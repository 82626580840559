import { Form, DatePicker, Button, Input, Modal, Calendar, Badge } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";

const WidgetTimerCalendar = (props) => {
    const { visible, onCancel, task } = props;

    const dateCellRender = useCallback((date) => {
        let data = (props.data || []).filter((item) => moment(item).format("DD-MM-YYYY") == date.format("DD-MM-YYYY"));

        if(data.length){
            return <div>
                {
                    data.map((item, i) => {
                        return <p className={cx(i != 0 && "m-0 mt-1", "m-0 pl-2")}>
                            <Badge status={item < Date.now() ? "success" : "processing"} text={<span className={item < Date.now() && "text-success"}>
                                {moment(item).format("HH:mm")}
                            </span>} />
                        </p>
                    })
                }
            </div>
        }

    }, [props.data])

    return <Modal
        title={task ? task.name : "Chi tiết lịch chạy chiến dịch"}
        width={1200}
        visible={visible}
        onCancel={onCancel}
        onOk={onCancel}
    >
        <Calendar 
            dateCellRender={dateCellRender}
        />
    </Modal>
}

export default WidgetTimerCalendar;