import React, { useEffect, useState } from "react";
import { CopyFilled } from "@ant-design/icons";
import { render } from "@testing-library/react";

const PopupMenu = ({ visible, x, y, bt, menu, hide }) => {
  const [visibleComponent, setvisibleComponent] = useState(false);

  useEffect(() => {
    if(visible){
      let event = () => {
        if(hide) hide();
        console.log('event click menu popp')
        window.removeEventListener("click", event);
      }
      window.addEventListener("click", event, false);
    }
  }, [visible])

  if (!visible) return null;
  return <div className="popup" style={{ left: `${x}px`, top: y ? `${y}px` : "unset", bottom: bt ? `${bt}px` : "unset" }}>
    {menu}
  </div>
}

export default PopupMenu