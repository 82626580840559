import React, { Component } from 'react';

import { Form, Input, Button, Checkbox, message, Menu, Modal, Select, Dropdown, Tooltip, Popover, Tree, Switch, Table, Spin } from 'antd';
import { fetchData, formatNumber } from '../lib/helpers';
import { Footer, Header, Sidebar } from '../component';
import moment from 'moment';
import { FolderOpenOutlined, ExclamationCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import qs from 'qs';

const { Option } = Select;

export default class SmsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            loadingForm: false
        };
        this.limit = 20;
        this.page = 1;
        this.services = {
            'otpsim': 'http://otpsim.com',
            'chothuesimcode': 'https://chothuesimcode.com'
        }
    }
    componentWillMount = () => {
        this.getListData();
    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            let params = {
                page: this.page,
                limit: this.limit
            }
            let response = await fetchData({
                full_path: `${process.env.REACT_APP_API_SMS}/api/v1/sms/list?${qs.stringify(params)}`,
                method: 'get'
            });

            this.setState({ loading: false, listData: response.data, total: response.total })

        } catch (err) {
            this.setState({ loading: false });
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    componentWillUnmount = () => {

    }
    deleteMany = async (ids) => {
        if (ids.length == 0) {
            this.props.notification({
                type: 'warning',
                message: 'Lỗi xóa',
                description: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        let { notification } = this.props;
        let _this = this;
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let result = await fetchData({
                            full_path: `${process.env.REACT_APP_API_SMS}/api/v1/sms/delete_many`,
                            method: 'post',
                            body: JSON.stringify({
                                ids: ids
                            }),
                            headers: { 'Content-Type': 'application/json' }
                        });
                        notification({
                            type: 'success',
                            message: 'Xóa thành công',
                            description: `Bạn đã xóa thành công ${result.data.deletedCount} đối tượng!`
                        })
                        _this.getListData();
                        resolve()
                    } catch (err) {
                        notification({
                            type: 'error',
                            message: 'Lỗi xóa',
                            description: err.message || 'Đã cõ lỗi xảy ra'
                        })
                        reject()
                    }
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }
    saveSms = async (values) => {
        try {
            this.setState({ loadingForm: true });
            let url = `${process.env.REACT_APP_API_SMS}/api/v1/sms/create`;
            let method = 'post'
            if (values['_id']) {
                url = `${process.env.REACT_APP_API_SMS}/api/v1/sms/${values['_id']}`;
                method = 'put';
            }
            values['domain'] = this.services[values['name']];

            let response = await fetchData({
                full_path: url,
                method: method,
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });

            this.getListData();

            this.setState({ loadingForm: false, visibleForm: false });

            if (this.form) this.form.resetFields();

            this.props.notification({
                type: 'success',
                message: 'Cập nhập dịch vụ',
                description: 'Thao tác thành công'
            });

        } catch (err) {
            this.setState({ loadingForm: false });
            this.props.notification({
                type: 'error',
                message: 'Cập nhập dịch vụ',
                description: err.message || 'Đã cõ lỗi xảy ra'
            });
        }
    }
    render() {

        return (
            <React.Fragment>
                <div className="row ">
                    <Modal
                        title="Cập nhập dịch vụ otp sms"
                        visible={this.state.visibleForm}
                        onCancel={() => this.setState({ visibleForm: false }, () => this.form.resetFields())}
                        footer={null}
                    >
                        <Form
                            ref={(evt) => this.form = evt}
                            onFinish={this.saveSms}
                            initialValues={{
                                name: "otpsim"
                            }}
                            layout="vertical"
                        >
                            <Form.Item name="_id" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item name="name" label="Dịch vụ">
                                <Select>
                                    {
                                        Object.keys(this.services).map((key, i) => {
                                            return <Select.Option key={i} value={key}>{key}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="token" label="Api key">
                                <Input />
                            </Form.Item>
                            <Form.Item name="networks" label="Loại mạng">
                                <Select mode="multiple">
                                    <Select.Option value={"Mobi"}>Mobiphone</Select.Option>
                                    <Select.Option value={"Viettel"}>Viettel</Select.Option>
                                    <Select.Option value={"Vina"}>Vinaphone</Select.Option>
                                    <Select.Option value={"VNMB"}>VNMB</Select.Option>
                                    <Select.Option value={"Gmobile"}>Gmobile</Select.Option>
                                    <Select.Option value={"ITelecom"}>ITelecom</Select.Option>
                                </Select>
                            </Form.Item>
                            <div className="d-flex justify-content-end" >
                                <Button type="primary" htmlType="submit" loading={this.state.loadingForm}>Cập nhập</Button>
                            </div>
                        </Form>
                    </Modal>
                    <div className="col-md-12 ">
                        <div className="block">
                            <div className="block-content block-content-m">
                                <div className="row mb-20">
                                    <div className="col-md-9">
                                        <button className="btn btn-info btn-sm">Tất cả ({this.state.total})</button>
                                        <button className="btn btn-primary btn-sm ml-2" onClick={() => this.getListData()}>
                                            <i className="fa fa-refresh"></i>
                                        </button>
                                        <button className="btn btn-primary btn-sm ml-2" onClick={() => this.setState({ visibleForm: true })}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-md-12 ">
                                        <Spin spinning={this.state.loading}>
                                            <Table
                                                columns={[
                                                    {
                                                        title: "Service",
                                                        key: "name",
                                                        dataIndex: "name"
                                                    },
                                                    {
                                                        title: "Token",
                                                        key: "token",
                                                        dataIndex: "token"
                                                    },
                                                    {
                                                        title: "Balance",
                                                        key: "balance",
                                                        dataIndex: "balance",
                                                        render: (balance) => formatNumber(balance)
                                                    },
                                                    {
                                                        title: "Networks",
                                                        key: "networks",
                                                        dataIndex: "networks",
                                                        render: (networks) => networks ? networks.join(', ') : ''
                                                    },
                                                    {
                                                        title: "Active",
                                                        key: "active",
                                                        dataIndex: "_id",
                                                        render: (id) => {
                                                            let data = this.state.listData.find((item) => item._id == id);
                                                            return <Switch checked={data.active ? true : false} onChange={() => {
                                                                this.saveSms({
                                                                    _id: id,
                                                                    active: data.active ? 0 : 1
                                                                })
                                                            }} />
                                                        }
                                                    },
                                                    {
                                                        title: "Error",
                                                        key: "logs",
                                                        dataIndex: "logs",
                                                        render: (logs) => {
                                                            let arr = [];
                                                            logs.slice(logs.length - 20).sort((a, b) => b.time - a.time).map((item) => {
                                                                if (item.message) arr.push(`${new Date(item.time)} ----> ${item.message}`);
                                                            });
                                                            return <textarea style={{ minHeight: "100px" }} className="form-control" value={arr.join('\n')} />
                                                        }
                                                    },
                                                    {
                                                        title: "Last time",
                                                        key: "last_time_use",
                                                        dataIndex: "last_time_use",
                                                        render: (last_time) => last_time ? moment(last_time).format('HH:mm DD/MM/YYYY') : ""
                                                    },
                                                    {
                                                        title: "Action",
                                                        key: "action",
                                                        dataIndex: "_id",
                                                        className: "text-right",
                                                        render: (id) => {
                                                            return <div>
                                                                <Tooltip title="Đơn hàng">
                                                                    <a href="#" className="btn btn-primary p-5" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.props.history.push(`/sms/${id}`)
                                                                    }}>
                                                                        <i class="far fa-eye"></i>
                                                                    </a>
                                                                </Tooltip>
                                                                <Tooltip title="Chỉnh sửa">
                                                                    <a href="#" className="btn btn-primary ml-5 p-5" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.setState({
                                                                            visibleForm: true
                                                                        }, () => {
                                                                            setTimeout(() => {
                                                                                if (this.form) this.form.setFieldsValue(this.state.listData.find((item) => item._id == id))
                                                                            })
                                                                        })
                                                                    }}>
                                                                        <i class="far fa-edit"></i>
                                                                    </a>
                                                                </Tooltip>
                                                                <Tooltip title="Xóa">
                                                                    <a href="#" className="btn btn-danger ml-5 p-5" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.deleteMany([id])
                                                                    }}>
                                                                        <i class="far fa-trash"></i>
                                                                    </a>
                                                                </Tooltip>
                                                            </div>
                                                        }
                                                    }
                                                ]}
                                                dataSource={this.state.listData}
                                                pagination={{
                                                    total: this.state.total,
                                                    page: this.page,
                                                    pageSize: this.limit,
                                                    onChange: (current) => {
                                                        this.page = current;
                                                        this.getListData();
                                                    },
                                                    onShowSizeChange: (current, size) => {
                                                        this.page = current;
                                                        this.limit = size;
                                                        this.getListData();
                                                    }
                                                }}

                                            />
                                        </Spin>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
