import { Modal, Form, Button, Input, Select, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { fetchData } from "../../../lib/helpers";
import { Config } from "../../../utils/config";

const WidgetForm = (props) => {
    const [loading, setloading] = useState(false);

    const form = useRef();

    const onFinish = async (values) => {
        try {
            setloading(true);

            await fetchData({
                url: API_URL.jobTds.index,
                method: "post",
                body: JSON.stringify(values),
            });

            props.onFinish();
            notification.success({
                message: "Thông báo",
                description: "Cập nhập thành công",
            });
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message || err,
            });
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        if (!props.visible && form.current) {
            form.current.resetFields();
        }
        if (props.visible) {
            form.current.setFieldsValue(props.item);
        }
    }, [props.visible]);

    return (
        <Modal
            title="Thêm token job"
            visible={props.visible}
            onCancel={props.onCancel}
            footer={null}
        >
            <Form layout="vertical" onFinish={onFinish} ref={form}
                initialValues={{
                    type: "tds"
                }}
            >
                <Form.Item label="Token" name={"tokens"} 
                    extra="Mỗi dòng 1 token"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống",
                        },
                    ]}
                >
                    <Input.TextArea rows={5} />
                </Form.Item>
                <Form.Item
                    name="type"
                    label="Loại"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống",
                        },
                    ]}
                >
                    <Select>
                        {Config.jobTypes.map((item) => {
                            return (
                                <Select.Option value={parseInt(item)}>
                                    {item}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item className="text-right mb-0">
                    <Button htmlType="submit" type="primary" loading={loading}>
                        Cập nhập
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default WidgetForm;
