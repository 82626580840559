import React, { Component } from 'react';

import { Form, Input, Button, Checkbox, message, Menu, Modal, Select, Dropdown, Tooltip, Popover, Tree, Switch, Table, Spin } from 'antd';
import { fetchData, formatNumber } from '../lib/helpers';
import { Footer, Header, Sidebar } from '../component';
import moment from 'moment';
import { FolderOpenOutlined, ExclamationCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import qs from 'qs';

const { Option } = Select;

export default class SmsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0
        };
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
        setInterval(() => {
            if (!this.state.loading) this.getListData();
        }, 10000);
    }

    getListData = async (options = {}) => {
        try {
            this.setState({ loading: true });
            let params = {
                page: this.page,
                limit: this.limit,
                auth_sms_id: this.props.match.params.sms_id
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let response = await fetchData({
                full_path: `${process.env.REACT_APP_API_SMS}/api/v1/order/list?${qs.stringify(params)}`,
                method: 'get'
            });

            this.setState({ loading: false, listData: response.data, total: response.total })

        } catch (err) {
            this.setState({ loading: false });
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    componentWillUnmount = () => {

    }

    render() {

        return (
            <React.Fragment>
                <div className="row ">
                    <div className="col-md-12 ">
                        <div className="block">
                            <div className="block-content block-content-m">
                                <div className="row mb-20">
                                    <div className="col-md-9">
                                        <button className="btn btn-info btn-sm">Tất cả ({this.state.total})</button>
                                        <button className="btn btn-primary btn-sm ml-2" onClick={() => this.getListData()}>
                                            <i className="fa fa-refresh"></i>
                                        </button>
                                        <button className="btn btn-primary btn-sm ml-2" onClick={() => {
                                            this.props.history.push('/sms');
                                        }}>
                                            <i className="far fa-arrow-left"></i>
                                        </button>
                                    </div>
                                </div>
                                <Form className="row"
                                    initialValues={{
                                        status: "",
                                        network: ""
                                    }}
                                    ref={(evt) => this.formFilter = evt}
                                >
                                    <Form.Item name="status" className="col-md-2">
                                        <Select onChange={this.getListData}>
                                            <Select.Option value="">Trạng thái</Select.Option>
                                            <Select.Option value={0}>Đang chờ</Select.Option>
                                            <Select.Option value={1}>Hoàn thành</Select.Option>
                                            <Select.Option value={2}>Thất bại</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="network" className="col-md-2">
                                        <Select onChange={this.getListData}>
                                            <Select.Option value="">Loại mạng</Select.Option>
                                            <Select.Option value={"Mobi"}>Mobiphone</Select.Option>
                                            <Select.Option value={"Viettel"}>Viettel</Select.Option>
                                            <Select.Option value={"Vina"}>Vinaphone</Select.Option>
                                            <Select.Option value={"VNMB"}>VNMB</Select.Option>
                                            <Select.Option value={"Gmobile"}>Gmobile</Select.Option>
                                            <Select.Option value={"ITelecom"}>ITelecom</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item className="col-md-1">
                                        <Button type="primary" onClick={() => {
                                            this.formFilter.resetFields();
                                            this.getListData();
                                        }}>Xóa lọc</Button>
                                    </Form.Item>
                                </Form>
                                <div className="row ">
                                    <div className="col-md-12 ">
                                        <Spin spinning={this.state.loading}>
                                            <Table
                                                columns={[
                                                    {
                                                        title: "#",
                                                        key: "#",
                                                        dataIndex: "index",
                                                        render: (index) => {
                                                            if (this.page == 1) {
                                                                return index + 1;
                                                            } else {
                                                                return (this.page - 1) * this.limit + index + 1;
                                                            }
                                                        }
                                                    },
                                                    {
                                                        title: "Service",
                                                        key: "type_service",
                                                        dataIndex: "type_service"
                                                    },
                                                    {
                                                        title: "Phone",
                                                        key: "phone",
                                                        dataIndex: "phone"
                                                    },
                                                    {
                                                        title: "Status",
                                                        key: "status",
                                                        dataIndex: "status",
                                                        render: (status) => {
                                                            return <span className={`badge ${status == 0 ? "badge-info" : status == 1 ? "badge-success" : "badge-danger"}`}>{status == 0 ? 'Đang chờ' : status == 1 ? 'Hoàn thành' : 'Thất bại'}</span>
                                                        }
                                                    },
                                                    {
                                                        title: "Network",
                                                        key: "network",
                                                        dataIndex: "network"
                                                    },
                                                    {
                                                        title: "Code",
                                                        key: "messages",
                                                        dataIndex: "messages",
                                                        render: (messages) => {
                                                            try {
                                                                if (messages && messages.length > 0) {
                                                                    return messages[0]['otp']
                                                                }
                                                            } catch (err) { }
                                                        }
                                                    },
                                                    {
                                                        title: "Messages",
                                                        key: "messages",
                                                        dataIndex: "messages",
                                                        render: (messages) => {
                                                            try {
                                                                if (messages && messages.length > 0) {
                                                                    return messages[0]['sms_content']
                                                                }
                                                            } catch (err) { }
                                                        }
                                                    },
                                                    {
                                                        title: "Created time",
                                                        key: "created_time",
                                                        dataIndex: "created_time",
                                                        className: "text-right",
                                                        render: (created_time) => moment(created_time).format('HH:mm DD/MM/YYYY')
                                                    }
                                                ]}
                                                dataSource={this.state.listData.map((item, i) => {
                                                    item.index = i;
                                                    return item;
                                                })}
                                                pagination={{
                                                    total: this.state.total,
                                                    page: this.page,
                                                    pageSize: this.limit,
                                                    onChange: (current) => {
                                                        this.page = current;
                                                        this.getListData();
                                                    },
                                                    onShowSizeChange: (current, size) => {
                                                        this.page = current;
                                                        this.limit = size;
                                                        this.getListData();
                                                    }
                                                }}

                                            />
                                        </Spin>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
