import { Button, Form, Input, message, Modal, notification, Radio, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { fetchData } from "../../../lib/helpers";
import { API_URL } from "../../../lib/api";
import { CopyPostTypes, StatusCopyPost } from "../../../utils/config";

const WidgetFormCopyPost = (props) => {
    const { visible, hide, Access } = props;
    const [loading, setloading] = useState(false);

    const formRef = useRef();

    const onFinish = async (values) => {
        try {
            setloading(true);

            let url = values._id ? `${API_URL.CopyPost.index}/${values._id}` : API_URL.CopyPost.index;

            if (values['uids']) values['uids'] = values['uids'].split("\n").map((item) => item.replace(/\s/gi, "")).filter((item) => item)

            values['ids'] = values['ids'] ? values['ids'].split("\n") : [];

            let response = await fetchData({
                url,
                method: values._id ? "put" : "post",
                body: JSON.stringify(values)
            });

            if (props.onFinish) props.onFinish();
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        if (visible) {
            formRef.current.resetFields();
            if (props.item) {
                formRef.current.setFieldsValue({
                    ...props.item,
                    uids: props.item.uids.join("\n")
                });
            }
            if (props.uids) {
                formRef.current.setFieldsValue({
                    uids: props.uids.join("\n")
                });
            }
        }

    }, [visible])

    return (
        <Modal
            title="Copy bài viết theo uid"
            visible={visible}
            onCancel={hide}
            footer={null}
        >
            <Form
                onFinish={onFinish}
                layout="vertical"
                ref={formRef}
                initialValues={{
                    status: 1,
                    type: CopyPostTypes.profile_facebook.value
                }}
            >
                {
                    props.uids && <div className="mb-3 text-right">
                        <strong>UID đăng bài: {props.uids.length}</strong>
                    </div>
                }
                <Form.Item name="_id" noStyle>
                    <Input type="hidden" />
                </Form.Item>

                {
                    props.item ?
                        <Form.Item name="id" label="Uid mục tiêu"
                            rules={[
                                {
                                    required: true,
                                    message: "Không được để trống"
                                }
                            ]}
                        >
                            <Input placeholder="Nhập uid mục tiêu" />
                        </Form.Item>
                        : <Form.Item name="ids" label="Uid mục tiêu"
                            rules={[
                                {
                                    required: true,
                                    message: "Không được để trống"
                                }
                            ]}
                            extra={<div className="fs-12" style={{ fontSize: "13px" }}>
                                <p className="mb-0">- Mỗi dòng 1 uid</p>
                                <p className="mb-0">- Hệ thống sẽ tự chia đều uid mục tiêu cho uid đăng bài</p>
                            </div>}
                        >
                            <Input.TextArea placeholder="Nhập uid mục tiêu" rows={5} />
                        </Form.Item>
                }

                <div className="row">
                    <Form.Item className="col-6" name="type" label="Loại"
                        rules={[
                            {
                                required: true,
                                message: "Không được để trống"
                            }
                        ]}
                    >
                        <Select>
                            {
                                Object.keys(CopyPostTypes).map((key) => {
                                    return <Select.Option value={key}>{CopyPostTypes[key]['label']}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item className="col-6" name="status" label="Trạng thái">
                        <Select>
                            {
                                Object.keys(StatusCopyPost).map((key) => {
                                    return <Select.Option value={parseInt(key)}>{StatusCopyPost[key]['label']}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </div>
                <Form.Item name="note" label="Ghi chú"
                >
                    <Input.TextArea
                        rows={3}
                    />
                </Form.Item>

                {
                    props.uids ?
                        <Form.Item name="uids" noStyle>
                            <Input
                                type="hidden"
                            />
                        </Form.Item>
                        :
                        <Form.Item name="uids" label="UID đăng bài"
                            extra={<div style={{ fontSize: "13px" }}>
                                <p className="mb-0">- Mỗi uid 1 dòng</p>
                                <p className="mb-0">- Uid đăng bài phải có trên cms và đã gán thiết bị chạy</p>
                            </div>}
                        >
                            <Input.TextArea
                                rows={5}
                            />
                        </Form.Item>
                }
                <Form.Item className="d-flex mb-0 justify-content-end">
                    <Button onClick={hide} className="mr-2">
                        Đóng
                    </Button>
                    <Button htmlType="submit" type="primary" loading={loading}>
                        Cập nhập
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

const mapDispathToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispathToProps)(WidgetFormCopyPost);
