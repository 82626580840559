const account = {}

export var dimens = {
    deviceWidth: window.innerWidth
}

export const AccountMetaData = {
    token: (getCookie("as_t")) ? getToken() : '',
    getToken: () => {
        try {
            let cookie = getCookie("as_t") ? getCookie("as_t") : '';
            return cookie ? atob(cookie) : '';
        } catch {
            return '';
        }
    },
    token_name: 'as_t',
    logOut: () => {
        setCookie('as_t', '', 0);
        return true
    },
    refName: "as_ref"
}

export const formatNumber = (nStr) => {
    nStr+='';let x=nStr.split(',');let x1=x[0];let x2=x.length>1?','+x[1]:'';let rgx=/(\d+)(\d{3})/;while(rgx.test(x1)){x1=x1.replace(rgx,'$1'+'.' + '$2');}return x1+x2;
}

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
        date.setMonth(date.getMonth() + 1);
        expires = "; expires=" + date.toUTCString();
    }
    // document.cookie = name + "=" + (value || "") + expires + ";domain=.muabanhay.com; path=/";
    document.cookie = name + "=" + (value || "") + expires + ";domain=; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export function checkIssetLogin(){
  if( !getCookie('as_t')  ) {
      return false;
  }
  try{
    dimens['as_t'] = atob(getCookie("as_t"));
  } catch(err){
      return false
  }
  return true;
}
export function getToken(cookie){
    try {
        return atob(cookie);
    } catch {
        return '';
    }
}
export default account