import { Button, Modal } from "antd";
import React from "react";
import { useLocation, withRouter } from "react-router-dom";

const ButtonCreateActionWidget = (props) => {

    return <>
        <Button type="primary">Tạo hành động</Button>
    </>
}

export default withRouter(ButtonCreateActionWidget);