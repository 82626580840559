import { Modal, Form, Button, Input, Select, notification, Radio } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { fetchData } from "../../../lib/helpers";

const FormScript = (props) => {
    const [typeSelect, settypeSelect] = useState();
    const [loading, setloading] = useState(false);

    const form = useRef();
    const { roleTypeAdmin } = props;

    const listType = {
        "UPDATE_CONFIG": {
            label: "UPDATE_CONFIG"
        },
        "RUN_BUTTON": {
            label: "RUN_BUTTON"
        },
        "STOP_BUTTON": {
            label: "STOP_BUTTON"
        },
        "RUN_FILE_ON_NEW_THREAD": {
            label: "RUN_FILE_ON_NEW_THREAD"
        },
        "RUN_FILE": {
            label: "RUN_FILE"
        },
        "RUN_SCRIPT_ON_NEW_THREAD": {
            label: "RUN_SCRIPT_ON_NEW_THREAD"
        },
        "RUN_SCRIPT": {
            label: "RUN_SCRIPT"
        },
        "FORCE_STOP_ALL": {
            label: "FORCE_STOP_ALL"
        }
    }
    const [ createType, setcreateType ] = useState(1);

    const onFinish = async (values) => {
        try{
            setloading(true);
            let script = `${values.action_name}`;
            if(values.content_script){
                script += `\n ${values.content_script}`
            }

            let data = [];
            let value_default = {
                action_type: "remote_control",
                device_ids: props.devices,
                num_target: props.devices.length,
                device_group: values.device_group,
                create_type: createType
            }

            if(values.action_name == "FORCE_STOP_ALL"){
                data.push(...[
                    {
                        ...value_default,
                        action_name: "RUN_SCRIPT".toLocaleLowerCase(),
                        options: {
                            script: `RUN_SCRIPT
                            sequence
                                    home
                                    GetRunningApp -var running
                                toast -text 'getVar -name running'
                                    StopApp -p "getVar -name running" -skip "com.android.adbkeyboard,org.meowcat.edxposed.manager,com.tzappstudio.autotouchpro,com.tzappstudio.autotouchpro:scriptProcess,com.tzappstudio.autotouchpro.updater"
                                toast -text "--------- kill all app done !!!!!!!!!!!!"
                                sleep -t 1000
                                    home
                            endSequence
                            `
                        }
                    }, 
                    {
                        ...value_default,
                        action_name: "STOP_BUTTON".toLocaleLowerCase(),
                        options: {
                            script: `STOP_BUTTON`
                        }
                    },
                    {
                        ...value_default,
                        action_name: "RUN_BUTTON".toLocaleLowerCase(),
                        options: {
                            script: `RUN_BUTTON`
                        }
                    }
                ])
            } else{
                data.push({
                    ...value_default,
                    action_name: values.action_name.toLocaleLowerCase(),
                    options: {
                        script: script
                    }
                })
            }

            for(let i = 0; i < data.length; i++){
                await fetchData({
                    url: roleTypeAdmin ? API_URL.admin.aciton.index : API_URL.aciton.create,
                    method: "post",
                    body: JSON.stringify(data[i])
                });
            }

            // props.onCancel();
            notification.success({
                message: "Thông báo",
                description: "Tạo action thành công"
            })
        } catch(err){
            notification.error({
                message: "Thông báo",
                description: err.message || err
            });
        } finally{
            setloading(false);
        }
    }

    useEffect(() => {
        if(!props.visible && form.current){
            form.current.resetFields();
        }
    }, [props.visible])

    return (
        <Modal
            title="Tạo lệnh remote cho thiết bị"
            visible={props.visible}
            onCancel={props.onCancel}
            footer={null}
            width={600}
        >
            {/* <p>Tổng số đã chọn: {props.devices.length}</p> */}
            <Form
                layout="vertical"
                onFinish={onFinish}
                ref={form}
            >
                <Form.Item >
                    <Radio.Group value={createType} onChange={(e) => setcreateType(e.target.value)}>
                        <Radio value={1}>Thiết bị đã chọn ({props.devices.length})</Radio>
                        <Radio value={2}>Thiết bị cùng box</Radio>
                        <Radio value={3}>Tất cả thiết bị ({props.totalDevice})</Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    createType == 2 && <Form.Item 
                        rules={[
                            {
                                required: true,
                                message: "Không được để trống"
                            }
                        ]}
                        label="Chọn box name" name="device_group" >
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Box name"
                                options={props.optionBoxName || []}
                                showSearch
                                allowClear
                                mode="multiple"
                            />
                    </Form.Item>
                }
                <Form.Item name="action_name" label="Chọn loại"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống"
                        }
                    ]}
                >
                    <Select onChange={(e) => settypeSelect(e)}>
                        {
                            Object.keys(listType).map((key) => {
                                return <Select.Option value={key}>{listType[key].label}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="Nội dung lệnh" name="content_script">
                    <Input.TextArea style={{ minHeight: "160px"}}
                        disabled={["RUN_BUTTON", "STOP_BUTTON", "FORCE_STOP_ALL"].indexOf(typeSelect) > -1}
                        placeholder="Nhập nội dung.."
                    />
                </Form.Item>
                <Form.Item className="text-right mb-0">
                    <Button htmlType="submit" type="primary" loading={loading}>Thêm mới</Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default FormScript;