import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row } from 'antd';
import { UploadOutlined, InboxOutlined, VerticalAlignBottomOutlined, CheckOutlined, DownSquareOutlined, DownSquareFilled, CopyFilled } from '@ant-design/icons';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';

const propTypes = {
    visible: PropTypes.bool,
    hide: PropTypes.func,
    listData: PropTypes.array,
    listIdChecked: PropTypes.array,
    listColumn: PropTypes.array
}

const WidgetExport = (props = propTypes) => {
    const [ selectedAll, setselectedAll ] = useState(false);
    const [ loading, setloading ] = useState(false);
    const [ loadingCopy, setloadingCopy ] = useState(false);
    
    const listColumn = props.listColumn || [];

    const [ format_text, setformat_text ] = useState(["device_id", ...listColumn].join(" | "));

    const onExport = async (type) => {
        let data = '';
        if (!format_text) {
            props.notification({
                type: 'warning',
                message: 'Lỗi export',
                description: 'Vui lòng chọn định dạng file!'
            })
            return;
        }
        if (type == 'copy') {
            setloadingCopy(true);
        } else {
            setloading(true);
        }
        let format = format_text.split(' | ');

        props.listData.forEach((item, i) => {
            if (!selectedAll && props.listIdChecked.indexOf(item._id) === -1) return;
            for (let ii = 0; ii < format.length; ii++) {
                if (format[ii].indexOf('.') == -1) {
                    data += item[format[ii]];
                } else if (item[format[ii].split('.')[0]]) {
                    data += item[format[ii].split('.')[0]][format[ii].split('.')[1]];
                }
                if (ii == format.length - 1) {
                    data += '\n';
                } else {
                    data += '|';
                }
            }
        })

        if (type == 'copy') {
            props.copyText(data);
            setloadingCopy(false);
        } else {
            let total = props.listData.length;
            var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
            setTimeout(() => {
                let date = new Date();
                FileSaver.saveAs(blob, `export-${total}-data-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.txt`);
                setloading(false);
            }, 300)
        }
    };
    
    return (
        <Modal
            title="Export"
            visible={props.visible}
            onCancel={props.hide}
            footer={null}
        >
            <div className="export-option">
                <div className="title">
                    <div style={{ display: "flex", alignItems: "center" }}><CheckOutlined className="mr-5" /> {props.listIdChecked.length} được chọn</div>
                </div>
                <div className="title">
                    <Button size={"small"} type="white" icon={selectedAll ? <DownSquareFilled /> : <DownSquareOutlined />} shape={'round'}
                        onClick={() => setselectedAll(!selectedAll)}
                        className={selectedAll ? 'active' : ''}
                    >
                        Chọn toàn bộ theo bộ lọc hiện tại ({props.listData.length})
                    </Button>
                </div>
            </div>
            <div className="export-container">
                <div className="format mb-15" >
                    <Checkbox.Group value={format_text.replace(/\s/gi, "").split("|")} className="format mb-15" onChange={(e) => { setformat_text(e.join(' | ')) }}>
                        <Row>
                            <Col span={8}>
                                <Checkbox value="device_id" style={{ lineHeight: '32px' }}>
                                    Device id
                                </Checkbox>
                            </Col>
                            {
                                listColumn.map((item, index) => {
                                    return <Col span={8}>
                                        <Checkbox value={item} style={{ lineHeight: '32px' }}>
                                            Column {index + 1}
                                        </Checkbox>
                                    </Col>
                                })
                            }
                        </Row>
                    </Checkbox.Group>
                    <Input disabled={true} value={format_text} />
                </div>
                <div className="export-header text-center d-flex align-items-center justify-content-center">
                    <Button type="primary" icon={<VerticalAlignBottomOutlined />} onClick={onExport} loading={loading}

                    >
                        Tải xuống
                    </Button>
                    <Button className="d-flex align-items-center ml-10" type="primary" icon={<CopyFilled />} onClick={() => onExport('copy')} loading={loadingCopy}

                    >
                        Copy
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default WidgetExport;
