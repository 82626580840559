import {
    ExclamationCircleFilled,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Badge, Button, Input, Modal, notification, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PopupMenu } from "../../component";
import { API_URL } from "../../lib/api";
import { getPosContextmenu } from "../../lib/app";
import { fetchData, formatNumber } from "../../lib/helpers";
import WidgetForm from "./Widgets/form"

const JobTdsPage = (props) => {
    const [data, setData] = useState({
        total: 0,
        data: [],
    });
    const [statitic, setstatitic] = useState({
        coint: 0
    });
    const [pagination, setpagination] = useState({
        limit: 1000,
        page: 1,
    });
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [loading, setloading] = useState(false);
    const [visibleForm, setvisibleForm] = useState(false);

    const getListData = async () => {
        try {
            setloading(true);
            let params = {
                ...pagination,
            };
            let response = await fetchData({
                url: API_URL.jobTds.index,
                params: params,
            });
            setData(response);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message || err,
            });
        } finally {
            setloading(false);
        }
    };

    const deleteMany = async (ids) => {
        Modal.confirm({
            title: "Bạn có muốn thực hiện thao tác này?",
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (ids.length == 0) throw "Chọn ít nhất 1 đối tượng!";
                        let response = await fetchData({
                            url: API_URL.jobTds.index,
                            method: "delete",
                            body: JSON.stringify({
                                ids: ids,
                            }),
                        });
                        getListData();

                        setselectedRowKeys([]);

                        notification.success({
                            message: "Thông báo",
                            description: "Thao tác thành công",
                        });
                    } catch (err) {
                        notification.error({
                            message: "Thông báo",
                            description: err.message || err,
                        });
                    } finally {
                    }
                    resolve();
                }).catch(() => console.log("Oops errors!"));
            },
            onCancel() { },
        });
    };

    useEffect(() => {
        getListData();
    }, []);

    useEffect(() => {
        setstatitic({
            ...statitic,
            coint: data.data.reduce((a, b) => a + b.coint, 0)
        })
    }, [ data.data ])

    return (
        <React.Fragment>
            <WidgetForm
                visible={visibleForm ? true : false}
                item={visibleForm}
                onFinish={() => {
                    setvisibleForm(null);
                    getListData();
                }}
                onCancel={() => setvisibleForm(null)}
            />

            <div className="block" style={{ minHeight: "800px", maxHeight: "100%" }}>
                <Badge.Ribbon text={"Job tds"} />
                <div className="block-content block-content-m">
                    <h3 className="block-title mb-20">Thống kê job</h3>
                    <div>
                        <div className="mb-20 d-flex">
                            <Button type="default" size="small">
                                Tất cả: {data.total}
                            </Button>
                            <Button type="default" size="small" className="ml-2">
                                Đã chọn: {selectedRowKeys.length}
                            </Button>
                            <Button
                                type="default"
                                size="small"
                                className="ml-2 d-flex align-items-center"
                                onClick={getListData}
                            >
                                <i className="si si-reload mr-2"></i>
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                className="ml-2 d-flex align-items-center"
                                onClick={() => setvisibleForm(true)}
                            >
                                Thêm mới
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                className="ml-2 d-flex align-items-center"
                                onClick={() => deleteMany(selectedRowKeys)}
                                danger
                            >
                                <i className="si si-trash"></i>
                            </Button>
                        </div>
                        <div className="mb-20 d-flex">
                            <p>Tổng xu: {formatNumber(statitic.coint)}</p>
                        </div>
                        <Table
                            dataSource={data.data}
                            columns={[
                                // {
                                //     title: "#",
                                //     render: (value, record, index) => {
                                //         return index + 1;
                                //     },
                                // },
                                {
                                    title: "Tài khoản",
                                    dataIndex: "username",
                                },
                                {
                                    title: "Loại",
                                    dataIndex: "type",
                                },
                                {
                                    title: "Xu",
                                    dataIndex: "coint",
                                    render: (value, record) => {
                                        return formatNumber(value)
                                    }
                                },
                                {
                                    title: "Xu die",
                                    dataIndex: "coint_die",
                                },
                                {
                                    title: "Job",
                                    dataIndex: "count_job",
                                    render: (value, record) => {
                                        return formatNumber(value)
                                    }
                                },
                                {
                                    title: "Thiết bị",
                                    dataIndex: "device_id",
                                },
                                {
                                    title: "Thời gian",
                                    dataIndex: "updated_time",
                                    align: "right",
                                    render: (value) => moment(value).format("HH:mm DD/MM/YYYY"),
                                },
                            ]}
                            rowSelection={{
                                selectedRowKeys: selectedRowKeys,
                                onChange: (e) => setselectedRowKeys(e),
                            }}
                            rowKey="_id"
                            pagination={{
                                total: data.total,
                                showSizeChanger: true,
                                pageSizeOptions: [20, 50, 100, 500],
                            }}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default JobTdsPage;
