import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber, Select } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { API_URL } from '../../lib/api';

export default class ScriptComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            type_join: 1,
            type_login: 1
        }
        this.form = null;
    }
    componentWillMount = () => {
    }
    componentWillUpdate = (nextProps, nextState) => {
        if(nextProps.visible && nextProps.visible != this.props.visible){
            this.getListProfile();
        }
    }
    onFinish = async (values) => {
        try {
            if(values['auto_sync_us']){
                values['auto_sync_us'] = 1;
                if(!this.props.userSystem.token_us){
                    this.props.notification({
                        type: 'warning',
                        message: this.title,
                        description: 'Vui lòng cập nhập token us'
                    })
                    return
                }
            }
            this.setState({ loading: true });
            values['action'] = {
                type: this.props.option.type,
                type_login: this.state.type_login
            }
            values['uids'] = this.props.listIdChecked;

            if (this.props.option.type == 'join_group') {
                values['action']['ids'] = values['group_id'].split('\n');
                values['action']['answer_text'] = values['answers'];
                values['action']['type_join'] = this.state.type_join;
                values['action']['max_join'] = values['max_join'] ? values['max_join'] : false;
                values['action']['time_join'] = values['time_join'] ? values['time_join'] : 0;
            }

            let resutl = await this.props.fetchData({
                url: 'api/v2/app/script/create',
                method: 'post',
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });
            this.setState({ loading: false, type_join: 1, type_login: 1 });
            this.props.hide();
            this.props.notification({
                type: 'success',
                message: this.title,
                description: 'Thao tác thành công'
            })

        } catch (err) {
            this.setState({ loading: false }, () => {
                this.props.notification({
                    type: 'error',
                    message: this.title,
                    description: err.message
                })
            });
        }
    };
    getListProfile = async (keyword = '') => {
        try {
            let res = await this.props.fetchData({
                url: API_URL.appProfile.index,
                params: {
                    profile_name: keyword
                }
            });
            this.setState({ listProfile: res.data });
        } catch (err) {
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    render() {
        if (!this.props.option) return null
        this.title = "Tạo kịch bản";
        let form = null;
        let type_modal = 1;
        if (this.props.option.type == 'join_group') {
            type_modal = 2;
            this.title = 'Join group';
            form = (<Form
                onFinish={this.onFinish}
                ref={(evt) => this.form = evt}
                initialValues={{
                    max_tab: 10,
                    type_join: 1,
                    max_join: 10,
                    time_join: 10,
                    type_login: 1
                }}
                layout="vertical"
            >
                <div className="row">
                    <div className="col-md-6">
                        <Form.Item
                            name="profile_id"
                            rules={[{ required: true, message: 'Tên app bắt buộc' }]}
                            label="Chọn app chạy"
                        >
                            {/* <Input /> */}
                            <Select
                                showSearch
                                placeholder="Chọn app"
                                optionFilterProp="children"
                                onChange={(e) => console.log(e)}
                                onSearch={(e) => {
                                    if (this.timeout_getprofile) clearTimeout(this.timeout_getprofile);
                                    let _this = this;
                                    this.timeout_getprofile = setTimeout(() => {
                                        _this.getListProfile(e);
                                    }, 300)
                                }}
                                filterOption={false}
                            >
                                {
                                    this.state.listProfile ? this.state.listProfile.map((item, i) => {
                                        return <Select.Option key={i} value={item.profile_id}>{item.name}</Select.Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="group_id"
                            rules={[{ required: true, message: 'Bạn phải nhập id group' }]}
                            label="Group id"
                        >
                            <Input.TextArea style={{ height: "120px" }} />
                        </Form.Item>
                        <Form.Item
                            name="answers"
                            label="Kịch bản trả lời"
                        >
                            <Input.TextArea style={{ height: "100px" }} placeholder="Vd: Đồng ý | Không đồng ý.." />
                        </Form.Item>
                    </div>
                    <div className="col-md-6" >
                        <Form.Item
                            name="max_tab"
                            label="Số tab thực hiện"
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                            name="max_join"
                            label="Giới hạn join groups / 1 uid"
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                            name="time_join"
                            label="Khoảng cách join của 1 uid / 1 group (giây)"
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                            name="type_join"
                        >
                            <Checkbox checked={this.state.type_join == 2} value={2} onChange={(e) => {
                                this.setState({ type_join: e.target.checked ? 2 : 1 })
                            }}>Join không trùng lặp</Checkbox>
                        </Form.Item>
                        <Form.Item
                            name="type_login"
                        >
                            <Checkbox checked={this.state.type_login == 2} value={2} onChange={(e) => {
                                this.setState({ type_login: e.target.checked ? 2 : 1 })
                            }}>Login cookie</Checkbox>
                        </Form.Item>
                        <div className="text-right">
                            <Button type="primary" loading={this.state.loading} htmlType="submit" >
                                Tiếp tục
                                </Button>
                        </div>
                    </div>
                </div>
            </Form>)
        }
        if (this.props.option.type == 'open_checkpoint') {
            this.title = 'Mở khóa nick';
            form = (<Form
                onFinish={this.onFinish}
                ref={(evt) => this.form = evt}
                initialValues={{
                    max_tab: 10,
                    type_login: 1
                }}
                layout="vertical"
            >
                <Row>
                    <Col span={24} className="pr-3">
                        <Form.Item
                            name="profile_id"
                            rules={[{ required: true, message: 'Tên app bắt buộc' }]}
                            label="Chọn app chạy"
                        >
                            {/* <Input /> */}
                            <Select
                                showSearch
                                placeholder="Chọn app"
                                optionFilterProp="children"
                                onChange={(e) => console.log(e)}
                                onSearch={(e) => {
                                    if (this.timeout_getprofile) clearTimeout(this.timeout_getprofile);
                                    let _this = this;
                                    this.timeout_getprofile = setTimeout(() => {
                                        _this.getListProfile(e);
                                    }, 300)
                                }}
                                filterOption={false}
                            >
                                {
                                    this.state.listProfile ? this.state.listProfile.map((item, i) => {
                                        return <Select.Option key={i} value={item.profile_id}>{item.name}</Select.Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="max_tab"
                            label="Số tab thực hiện"
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                            name="type_login"
                        >
                            <Checkbox checked={this.state.type_login == 2} value={2} onChange={(e) => {
                                this.setState({ type_login: e.target.checked ? 2 : 1 })
                            }}>Login cookie</Checkbox>
                        </Form.Item>
                        <Form.Item
                            name="auto_sync_us"
                            valuePropName="checked"
                        >
                            <Checkbox>Tự động đồng bộ sang Us</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <div className="text-center">
                    <Button type="primary" loading={this.state.loading} htmlType="submit" >
                        Tiếp tục
                    </Button>
                </div>
            </Form>)
        }
        if (this.props.option.type == 'verify_email') {
            this.title = 'Verify email';
            form = (<Form
                onFinish={this.onFinish}
                ref={(evt) => this.form = evt}
                initialValues={{
                    max_tab: 10
                }}
                layout="vertical"
            >
                <Row>
                    <Col span={24} className="pr-3">
                        <Form.Item
                            name="profile_id"
                            rules={[{ required: true, message: 'Tên app bắt buộc' }]}
                            label="Chọn app chạy"
                        >
                            {/* <Input /> */}
                            <Select
                                showSearch
                                placeholder="Chọn app"
                                optionFilterProp="children"
                                onChange={(e) => console.log(e)}
                                onSearch={(e) => {
                                    if (this.timeout_getprofile) clearTimeout(this.timeout_getprofile);
                                    let _this = this;
                                    this.timeout_getprofile = setTimeout(() => {
                                        _this.getListProfile(e);
                                    }, 300)
                                }}
                                filterOption={false}
                            >
                                {
                                    this.state.listProfile ? this.state.listProfile.map((item, i) => {
                                        return <Select.Option key={i} value={item.profile_id}>{item.name}</Select.Option>
                                    }) : null
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="max_tab"
                            label="Số tab thực hiện"
                        >
                            <InputNumber style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="text-center">
                    <Button type="primary" loading={this.state.loading} htmlType="submit" >
                        Tiếp tục
                    </Button>
                </div>
            </Form>)
        }
        return (
            <Modal
                title={this.title}
                visible={this.props.visible}
                onCancel={() => {
                    this.form.resetFields();
                    this.props.hide();
                }}
                footer={null}
                className={type_modal == 2 ? 'modal-join-group' : ''}
            >
                {form}
            </Modal>
        )
    }
}
