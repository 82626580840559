import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Form, Input, Button, Checkbox, message, Menu, Modal, Select, Dropdown, Tooltip, Popover, Tree, Pagination } from 'antd';
import { fetchData } from '../lib/helpers';
import { Footer, Header, Sidebar, ModalUploadUserFb, ModalExportUserFb, FormSaveFolder, PopupMenu, SpinLoading, FormOptJoinGroup, FormProxy } from '../component';
import moment from 'moment';
import { FolderOpenOutlined, ExclamationCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;
const { Option } = Select;
const { DirectoryTree } = Tree;

export default class Proxy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            listIdChecked: [],
            valuesFilter: {},
            loading: false,
            DataM: {},
            proxyTinsoft: null
        };
        this.limit = 20;
        this.page = 1;
        this.nextpage = true;
        this.timeOutFilter = null;
    }
    componentWillMount = () => {
        this.getListData();
    }

    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
                this.nextpage = false;
                this.state.listIdChecked = [];
            }
            this.setState({ loading: true });
            let query = `limit=${this.limit}&page=${this.page}`;
            for (let [key, value] of Object.entries(this.state.valuesFilter)) {
                query += `&${key}=${value}`;
            }
            let res = await fetchData({
                url: `api/v1/proxy/list?${query}`,
                method: 'get'
            });
            if (this.limit != res.data.length) {
                this.nextpage = false;
            } else {
                this.nextpage = true;
            }
            this.setState({ listData: res.data, totalData: res.total, DataM: res, loading: false, proxyTinsoft: res.proxy_tinsoft });
            if (document.querySelector('[name="checkall"]')) document.querySelector('[name="checkall"]').checked = false;
        } catch (err) {
            this.setState({ loading: false });
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    handleChecked = (e) => {
        if (e.target.name == 'checkall') {
            if (e.target.checked) {
                let listIdChecked = [];
                document.querySelectorAll('[name="check_item"]').forEach((item) => {
                    item.checked = true;
                    listIdChecked.push(item.value);
                });
                this.setState({ listIdChecked: listIdChecked });
            } else {
                document.querySelectorAll('[name="check_item"]').forEach((item) => item.checked = false)
                this.setState({ listIdChecked: [] });
            }
        } else {
            document.querySelector('[name="checkall"]').checked = false;
            let listIdChecked = this.state.listIdChecked;
            if (e.target.checked) {
                listIdChecked.push(e.target.value);
            } else {
                listIdChecked.splice(listIdChecked.indexOf(e.target.value), 1);
            }
            this.setState({ listIdChecked });
        }
    }
    handleFilterSearch = (e) => {
        if (this.timeOutFilter) clearTimeout(this.timeOutFilter);
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter });
        this.timeOutFilter = setTimeout(() => {
            this.getListData('reset')
        }, 300)
    }
    handleFilter = (e) => {
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter }, () => { this.getListData('reset') });
    }
    componentWillUnmount = () => {

    }
    successSave = (data) => {
        this.setState({
            visibleForm: false
        }, () => {
            this.props.notification({
                message: 'Cập nhập proxy',
                description: `Cập nhập thành công`,
                type: 'success'
            })
            this.getListData('reset');
        })
    }
    removeMany = async (values) => {
        var { id, type, list_ids } = values;
        if (type == 1) {
            list_ids = [id];
        }
        if (list_ids.length == 0) {
            this.props.notification({
                type: 'warning',
                message: 'Lỗi xóa',
                description: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        let { notification } = this.props;
        let _this = this;
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${list_ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let result = await fetchData({
                            url: 'api/v1/proxy/delete_many',
                            method: 'post',
                            body: JSON.stringify({ listids: list_ids.join(',') }),
                            headers: { 'Content-Type': 'application/json' }
                        });
                        notification({
                            type: 'success',
                            message: 'Xóa thành công',
                            description: `Bạn đã xóa thành công ${result.data.deletedCount} đối tượng!`
                        })
                        if (type == 2) _this.setState({ listIdChecked: [] });
                        _this.getListData();
                        resolve()
                    } catch (err) {
                        notification({
                            type: 'error',
                            message: 'Lỗi xóa',
                            description: err.message || 'Đã cõ lỗi xảy ra'
                        })
                        reject()
                    }
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }
    render() {
        return (
            <React.Fragment>
                <FormProxy {...this.props} success={this.successSave} visible={this.state.visibleForm} hide={() => this.setState({ visibleForm: false })} proxyTinsoft={this.state.proxyTinsoft} />
                <div className="row ">
                    <div className="col-md-12 ">
                        <div className="block">
                            <div className="block-content block-content-m">
                                <div className="row mb-20">
                                    <div className="col-md-9">
                                        <button className="btn btn-info btn-sm">Tất cả ({this.state.totalData ? this.state.totalData : 0})</button>
                                        <button className="btn btn-primary btn-sm ml-2" onClick={() => this.getListData()}>
                                            <i className="fa fa-refresh"></i>
                                        </button>
                                        <button className="btn btn-primary btn-sm ml-2" onClick={() => this.setState({ visibleForm: true })}>
                                            <i className="fa fa-key"></i>
                                        </button>
                                        <button className="btn btn-danger btn-sm ml-2 btn-remove-nc" onClick={() => this.removeMany({ type: 2, list_ids: this.state.listIdChecked })}>
                                            <i className="fa fa-trash-o"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="row mb-20 tool-filter">
                                    <div className="col-md-2">
                                        <Select value={this.state.valuesFilter['status'] ? this.state.valuesFilter['status'] : ""}
                                            name="status" style={{ width: "100%" }}
                                            onChange={(e) => {
                                                let valuesFilter = this.state.valuesFilter;
                                                valuesFilter['status'] = e;
                                                this.setState({ valuesFilter }, () => this.getListData('reset'));
                                            }}
                                        >
                                            <Option value="">Trạng thái</Option>
                                            <Option value="2">Thành công</Option>
                                            <Option value="3">Thất bại</Option>
                                        </Select>
                                    </div>
                                    <div className="col-md-2">
                                        <Button icon={<DeleteOutlined />} type="primary" size={"middle"} onClick={() => this.setState({ valuesFilter: {} }, () => {
                                            this.getListData('reset');
                                        })}>
                                            Xóa lọc
                                        </Button>
                                    </div>
                                    <div className="col-md-8 text-right">
                                        <Pagination
                                            total={this.state.DataM && this.state.DataM.total ? this.state.DataM.total : 1}
                                            showSizeChanger
                                            pageSize={this.limit}
                                            pageSizeOptions={[20, 50, 100, 200, 500, 1000]}
                                            current={this.page}
                                            onShowSizeChange={(current, size) => {
                                                this.limit = size;
                                            }}
                                            onChange={(current) => {
                                                this.page = current;
                                                this.getListData();
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-md-12 ">
                                        <div className="table-responsive" onScroll={this.handleScroll}>
                                            <SpinLoading visible={this.state.loading} />
                                            <table className="table table-striped table-vcenter">
                                                <thead>
                                                    <tr>
                                                        <th className="checkbox">
                                                            <label class="css-control css-control-primary css-checkbox p-0">
                                                                <input name="checkall" type="checkbox" class="css-control-input checkbox" onChange={this.handleChecked}
                                                                    checked={this.state.listIdChecked.length == this.state.listData.length && this.state.listIdChecked.length > 0 ? 1 : 0}
                                                                />
                                                                <span class="css-control-indicator"></span>
                                                            </label>
                                                        </th>
                                                        <th>Stt</th>
                                                        <th>Host</th>
                                                        <th>Port</th>
                                                        <th>Type</th>
                                                        <th>Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                                            return (<tr key={i + '_' + item._id}
                                                            >
                                                                <td className="checkbox">
                                                                    <label class="css-control css-control-primary css-checkbox p-0">
                                                                        <input name="check_item" type="checkbox" class="css-control-input checkbox" value={item._id}
                                                                            onChange={this.handleChecked}
                                                                        />
                                                                        <span class="css-control-indicator"></span>
                                                                    </label>
                                                                </td>
                                                                <td>
                                                                    {this.page > 1 ? ((this.page - 1) * this.limit) + i + 1 : i + 1}
                                                                </td>
                                                                <td>{item.host}</td>
                                                                <td>{item.port}</td>
                                                                <td>{item.type}</td>
                                                                <td>{moment(item.time_created).format('LT DD/MM/YYYY')}</td>
                                                            </tr>)
                                                        }) : <tr>
                                                            <td colSpan="11" className="text-center">Không có dữ liệu</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
