import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber } from 'antd';
import { UploadOutlined , InboxOutlined} from '@ant-design/icons';

export default class FormMoveData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.form = null;
    }
    onFinish = async (values) => {
        try {
            this.setState({ loading: true });
            values['folder_id'] = this.props.folder.folder_id;
            await this.props.fetchData({
                url: 'api/v1/user/move/data',
                method: 'post',
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });

            this.setState({ loading: false }, () => {
                this.props.resetData();
                this.props.hide();
                this.props.notification({ 
                    description: 'Chuyển dữ liệu thành công!',
                    type: 'success',
                    key: 'success',
                    message: 'Chuyển dữ liệu'
                })
            });

        } catch (err) {
            this.setState({ loading: false }, () => {
                this.props.notification({ 
                    description: err.message || 'Đã có lỗi xảy ra',
                    type: 'error',
                    key: 'error',
                    message: 'Chuyển dữ liệu'
                })
            });
        }
    };
    cancel = () => {
        this.form.resetFields();
        this.props.resetData();
        this.props.hide();
    }
    render() {
        return (
            <Modal
            title={`Chuyển dữ liệu của thư mục: "${this.props.folder.folder_name}"`}
            visible={this.props.visible}
            onCancel={this.cancel}
            footer={null}
            >
                <Form
                        onFinish={this.onFinish}
                        ref={(evt) => this.form = evt}
                        layout="vertical"
                    >
                        <Form.Item 
                            name="user_name"
                            label="User name nguời nhận"
                            rules={[{ required: true, message: 'Bạn phải nhập username đăng nhập của người nhận!' }]}
                        >
                            <Input placeholder="Nhập username" />
                        </Form.Item>

                        <div className="text-center">
                            <Button type="primary" loading={this.state.loading} htmlType="submit" >
                                Xác nhận
                            </Button>
                        </div>
                    </Form>
            </Modal>
        )
    }
}
