import { Modal } from "antd";
import React from "react";
import { useLocation, withRouter } from "react-router-dom";
import FormCreate from "../../Action/Task/Widget/FormCreate";

const HomeCreateActionWidget = (props) => {

    return <FormCreate
        {...props}
        isHomeCreate={true}
        onFinish={(response) => {
            props.hide();
            Modal.success({
                title: "Thông báo",
                content: "Tạo chiến dịch thành công!",
                onOk: () => {
                    props.history.push(`/task_action/${response.task._id}?open_setting=1`)
                },
                okText: "Xem chi tiết chiến dịch",
                okCancel: true,
                cancelText: "Đóng"
            })
        }}
    />
}

export default withRouter(HomeCreateActionWidget);