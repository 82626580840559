import React from "react";

import ScriptContent from "../../ScriptContent";

const AdminScriptContentView = (props) => {
    return <ScriptContent 
        {...props}
        routerTypeAdmin={true}
    />
}

export default AdminScriptContentView;