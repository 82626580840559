import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Tag,
  TreeSelect,
  InputNumber,
  Skeleton,
  message,
  Checkbox,
  Row,
  Col,
  Upload,
  Pagination,
  Tooltip,
  Switch,
  Badge,
  Radio,
  // Tree,
} from "antd";
import {
  // EyeOutlined,
  DeleteOutlined,
  SyncOutlined,
  UploadOutlined,
  // CheckCircleOutlined,
  FilterOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
import "./CreateAction.scss";
//new import
import { Header, Sidebar } from "../../../component";
import DetailActionModal from "../components/DetailActionModal";

// import CheckLiveDieInstagram from "../components/CheckLiveDieInstagram";
// import dummyData from "../dummyData/CreateActionTableData";
// import ChangeDeviceId from "../components/ChangeDeviceId";

import { fetchData } from "../../../lib/helpers";
import { AccountMetaData } from "../../../lib/account";
import moment from "moment";

// widget action
import WidgetViewLiveStream from "./Widget/viewLiveStream";

const { Option } = Select;

const CreateAction = (props) => {
  //state
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataRaw, setDataRaw] = useState([]);
  const [dataRawSelected, SetDataRawSelected] = useState(null);
  const [data, setData] = useState([]);
  const [action, setAction] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [selectedRowNum, setSelectedRowNum] = useState(0);
  const [listIdToDelete, setListIdToDelete] = useState(null);
  const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [treeDataNickFolder, setTreeDataNickFolder] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [filter, setFilter] = useState({});
  // const [isCheckNickModalVisible, setIsCheckNickModalVisible] = useState(false);
  //pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setToltal] = useState(0);
  //state of the switch in post_wall_facebook action
  const [checked, setChecked] = useState(false);
  // const [checked, setChecked] = useState(true); //just an alternative when feature is in development
  //state for the shareToGroup option in share_livestream_facebook
  const [shareToGroupOption, setShareToGroupOption] = useState(false);
  const [treeDataContentFolder, setTreeDataContentFolder] = useState([]);

  //state for uplooad component
  const [listFile, setListFile] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  //state for error handling while creating action
  const [errorMes, setErrorMes] = useState("");

  //ACTION LIST OF COMPONENT
  const allActionsObj = [
    //tiki
    { key: "pay_electricity_bill_tiki", value: "Thanh toán tiền điện Tiki" },
    { key: "tiki_coin", value: "Mua hàng bằng xu Tiki" },

    //facebook
    // { key: "like_post_facebook", value: "Like seeding facebook" },
    // { key: "comment_post_facebook", value: "Comment seeding facebook" },

    { key: "farm_nick_facebook", value: "Nuôi nick facebook" },
    { key: "login_nick_facebook", value: "Login & backup nick facebook" },
    { key: "join_group_facebook", value: "Tham gia nhóm facebook" },
    { key: "farm_group_facebook", value: "Lướt xem nhóm facebook" },
    {
      key: "interact_group_post_facebook",
      value: "Tương tác post nhóm facebook",
    },
    {
      key: "interact_regular_post_facebook",
      value: "Tương tác post facebook trên newsfeed",
    },
    {
      key: "interact_regular_video_facebook",
      value: "Tương tác video facebook trên newsfeed",
    },
    {
      key: "add_friend_facebook",
      value: "Kết bạn theo danh sách UID facebook",
    },
    { key: "post_wall_facebook", value: "Đăng bài lên tường facebook" },
    { key: "post_group_facebook", value: "Đăng bài vào nhóm facebook" },
    {
      key: "seeding_uid_facebook",
      value: "Seeding theo danh sách UID facebook",
    },
    {
      key: "view_livestream_facebook",
      value: "Livestream facebook",
    },
    // { key: "share_livestream_facebook", value: "Share livestream facebook" },

    //instagram
    { key: "farm_nick_instagram", value: "Nuôi nick instagram" },
    {
      key: "post_wall_instagram",
      value: "Đăng bài lên trang cá nhân instagram",
    },

    //kakaotalk
    {
      key: "join_spam_group_kakaotalk",
      value: "Tham gia/spam tin nhắn group chat Kakao Talk",
    },
  ];

  //url API
  const filter_url = `&uid=${fallbackString(
    filter.uid
  )}&device_id=${fallbackString(filter.device_id)}&action_name=${fallbackString(
    filter.action_name
  )}&status=${fallbackString(filter.status)}`;
  const get_list_action = `api/v1/action/list?page=${page}&limit=${pageSize}&action_type=${props.platform}${filter_url}`;
  // const get_list_action = "api/v1/action/list";
  const creat_action = "api/v1/action/create";
  const delete_action = "api/v1/action/delete";
  const get_list_folder = "api/v1/folder/list";
  const uploads = "api/v1/uploads";
  const get_list_folder_content = "api/v1/post/folder/list";

  //refs
  const formRef = useRef();
  const filterRef = useRef();
  //end of refs

  //functions

  function b64EncodeUnicode(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode("0x" + p1);
      })
    );
  }

  function fallbackString(str) {
    if (!str) {
      return "";
    }
    return str;
  }

  const addAnAction = () => {
    setIsModalVisible(true);
  };

  const fetchAndRenderData = async () => {
    try {
      setIsTableLoading(true);
      const dataInit = await fetchData({ url: get_list_action });

      setDataRaw(dataInit["data"]);
      setToltal(dataInit.total);

      // console.log(dataInit); //for debug
      const dataTable = dataInit["data"];

      let dataTableInit = [];
      dataTable.forEach((dt, index) => {
        //handle commandInit
        // console.log("action name raw: ", dt.action_name); //for debug
        let commandInit = "";
        try {
          commandInit = allActionsObj.filter(
            (obj) => obj.key === dt.action_name
          )[0].value;
        } catch (error) {
          console.log("error when create commandInit:", error);
          commandInit = "unknown!";
        }

        // console.log("commandInit: ", commandInit); //for debug
        //end of handle commandInit

        // //handle logs
        // const logs = dt.logs.join("\n");
        // //end of handle logs

        dataTableInit.push({
          key: dt._id,
          device_uid: `${dt.device_id}/${dt.uid}`,
          running: "",
          log: dt.logs,
          command: commandInit,
          commandStatus: dt.status,
          begin: dt.options.beginTime ? dt.options.beginTime : "unknown!",
          end: "--",
          _id: dt._id,
        });
      });

      setData(dataTableInit);

      setIsTableLoading(false);
    } catch (error) {
      message.error("Error while fetching data!");
      console.log("error while fetching data:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const convertTime = (t) => {
    if (t < 10) {
      return `0${t}`;
    } else {
      return t;
    }
  };

  const converttContent = (content) => {
    const regex = /\n/g;
    return content.replace(regex, ",");
  };

  //this function return an array contains multiple arrays with random elements
  function getMultipleRandomByTime(arr, elNum, time) {
    let final = [];

    for (let index = 0; index < time * 1; index++) {
      const shuffled = [...arr].sort(() => 0.5 - Math.random());
      final.push(shuffled.slice(0, elNum * 1));
    }

    return final;
  }

  const onFinish = async (values) => {
    try {
      setBtnLoading(true);
      let optionsObj = { ...values };
      // console.log(optionsObj); //for debug
      //handle cardExpiredDate tiki
      if (optionsObj["CardDate"]) {
        const dates = optionsObj["CardDate"].split("-");
        const dateValid = `${dates[1]}/${dates[2]}`;
        optionsObj = { ...optionsObj, dateValid };
      }
      //end of handle cardExpiredDate tiki

      //handle commentContent
      if (optionsObj.commentContent) {
        optionsObj.commentContent = converttContent(optionsObj.commentContent);
      }

      if (
        !optionsObj.commentContent &&
        (optionsObj.actionName.includes("facebook") ||
          optionsObj.actionName.includes("instagram"))
      ) {
        optionsObj.commentContent = "haha";
      }
      //end of handle commentContent

      //handle shareContent
      if (optionsObj.shareContent) {
        optionsObj.shareContent = converttContent(optionsObj.shareContent);
      }

      if (optionsObj.contentComment) {
        optionsObj.contentComment = converttContent(optionsObj.contentComment);
      }

      if (
        !optionsObj.shareContent &&
        (optionsObj.actionName.includes("facebook") ||
          optionsObj.actionName.includes("instagram"))
      ) {
        optionsObj.shareContent = "haha";
      }
      //end of handle shareContent

      //handle farm options
      if (optionsObj.farmOption) {
        let farmOptionDetail = {};
        optionsObj.farmOption.forEach((option) => {
          farmOptionDetail[option] = true;
        });
        // console.log(farmOption); //for debug
        optionsObj.farmOptionDetail = farmOptionDetail;
      }
      //end of handle farm options

      //handle loop options
      if (optionsObj.loopOption) {
        let loopOptionDetail = {};
        optionsObj.loopOption.forEach((option) => {
          loopOptionDetail[option] = true;
        });
        // console.log(loopOption); //for debug
        optionsObj.loopOptionDetail = loopOptionDetail;
      }
      //end of handle loop options

      //handle farm options for seeding by uid list
      if (
        !optionsObj.farmOption &&
        optionsObj.actionName === "seeding_uid_facebook"
      ) {
        let farmOptionDetail = {};
        farmOptionDetail.likeWhileView = true;
        optionsObj.farmOptionDetail = farmOptionDetail;
      }
      //end of handle farm options for seeding by uid list

      // //this used for upload txt files
      // //handle post content (.txt)
      // if (optionsObj.postContent) {
      //   let postContentArray = [];
      //   optionsObj.postContent.fileList.forEach((fl) => {
      //     postContentArray.push(fl.response.attachment.url);
      //   });
      //   // console.log(postContentArray); //for debug
      //   optionsObj.contentURL = postContentArray.join(",");
      // }
      // //end of handle post content (.txt)

      //handle answer content join group
      if (optionsObj.answerContent) {
        optionsObj.answerContent = converttContent(optionsObj.answerContent);
      }

      if (
        !optionsObj.answerContent &&
        optionsObj.actionName === "join_group_facebook"
      ) {
        optionsObj.answerContent = "okey";
      }
      //end of handle answer content join group

      //handle link group join group (text area) (includes for kakao in lua)
      if (optionsObj.linkGroup) {
        let linkGroupInit = optionsObj.linkGroup.trim().split("\n");
        let linkGroup = [];

        linkGroupInit.forEach((lg) => {
          let url = new URL(lg);
          linkGroup.push(url.origin + url.pathname);
        });
        optionsObj.linkGroup = linkGroup;

        // //kakao
        // if (optionsObj.actionName === "join_spam_group_kakaotalk") {
        //   optionsObj.linkGroupForLua = linkGroup.join("|");
        // }
      }
      //end of handle link group join group (text area) (includes for kakao in lua)

      //handle id (link) post in group
      if (
        optionsObj.linkPost &&
        optionsObj.actionName === "interact_group_post_facebook"
      ) {
        const arr = optionsObj.linkPost.split("\n");

        let newArr = [];

        arr.forEach((link, index) => {
          if (link.split("/").slice(-1)[0]) {
            newArr.push(link.split("/").slice(-1)[0]);
          } else {
            newArr.push(link.split("/").slice(-2)[0]);
          }
        });

        optionsObj.linkPostArr = newArr;

        // if (optionsObj.linkPost.split("/").slice(-1)[0]) {
        //   optionsObj.idPost = optionsObj.linkPost.split("/").slice(-1)[0];
        // } else {
        //   optionsObj.idPost = optionsObj.linkPost.split("/").slice(-2)[0];
        // }
      }
      //end of handle id (link) post in group

      //handle link post array
      if (
        optionsObj.linkPost &&
        optionsObj.actionName === "interact_regular_post_facebook"
      ) {
        optionsObj.linkPostArr = optionsObj.linkPost.split("\n");
      }
      //end of handle link post array

      //handle image URL
      if (optionsObj.postImage) {
        let postImageArray = [];
        optionsObj.postImage.fileList.forEach((fl) => {
          postImageArray.push(fl.response.attachment.url);
        });
        // console.log(postImageArray); //for debug
        // optionsObj.imgURL = postImageArray.join(",");
        optionsObj.imgURL = postImageArray;
      }
      //end of handle image URL

      //handle content folder (when picked from content folder instead of upload manualy)
      // if (optionsObj.contentFolder){

      // }
      //end of handle content folder (when picked from content folder instead of upload manualy)

      //handle uidList
      if (optionsObj.uidList) {
        optionsObj.uidList = optionsObj.uidList.split("\n");
      }
      //end of handle uidList

      //handle list messages for kakao (in lua)
      if (
        optionsObj.actionName === "join_spam_group_kakaotalk" &&
        optionsObj.messages
      ) {
        optionsObj.messages = optionsObj.messages.map((dt, i) => {
          return dt.replaceAll('"', "").trim();
        });

        optionsObj.messagesBase64 = optionsObj.messages.map((dt, i) => {
          return dt
            .replaceAll('"', "")
            .trim()
            .split("\n")
            .map((str) => b64EncodeUnicode(str))
            .filter((str) => str !== ""); //spit by "\n"
        });
      }
      //end of handle list messages for kakao (in lua)

      //handle list messages for kakao (android)
      if (
        optionsObj.actionName === "join_spam_group_kakaotalk" &&
        optionsObj.messages &&
        optionsObj.messagesBase64 &&
        optionsObj.linkGroup &&
        0 < parseInt(optionsObj.mesNum) &&
        parseInt(optionsObj.mesNum) < optionsObj.messages.length
      ) {
        // console.log(parseInt(optionsObj.mesNum), "abc"); //for debug
        // console.log(optionsObj.messages.length, "abc"); //for debug

        optionsObj.messagesChunk = getMultipleRandomByTime(
          optionsObj.messagesBase64,
          optionsObj.mesNum,
          optionsObj.linkGroup.length
        );
      }
      //end of handle list messages for kakao (android)

      //handle delay time for android (min to sec)
      optionsObj.delaySec = 0;
      if (
        optionsObj.actionName === "join_spam_group_kakaotalk" &&
        optionsObj.loopTime > 1 &&
        optionsObj.delay > 0
      ) {
        optionsObj.delaySec = optionsObj.delay * 60 * 1000;
      }
      //end of handle delay time for android (min to sec)

      // //hardcode
      // optionsObj.maxNumAddFriend = 1;
      // //end of hardcode

      //handle begin time
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        convertTime(today.getMonth() * 1 + 1) +
        "-" +
        convertTime(today.getDate());

      const time =
        convertTime(today.getHours()) +
        ":" +
        convertTime(today.getMinutes()) +
        ":" +
        convertTime(today.getSeconds());

      optionsObj.beginTime = date + " " + time;
      //end of handle begin time

      if (optionsObj.timeBeforeInteractive) optionsObj.timeMillisecondBeforeInteractive = optionsObj.timeBeforeInteractive * 1000;
      if (optionsObj.timeAfterInteractive) optionsObj.timeMillisecondAfterInteractive = optionsObj.timeAfterInteractive * 1000;

      // console.log("Success submit:", values);
      const body = {
        folder_id: optionsObj.nickFolder ? optionsObj.nickFolder : "",
        action_name: optionsObj.actionName,
        options: optionsObj,
        action_type: props.platform,
        actions: [],
        num_target: optionsObj.num,
      };

      await fetchData({
        method: "POST",
        url: creat_action,
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      });

      setIsModalVisible(false);
      fetchAndRenderData();
      formRef.current.resetFields();
      setAction(null);
      setChecked(false); //will be added when ready
      setListFile([]);
      setErrorMes("");
      setBtnLoading(false);

      message.success("Thêm thành công!");
    } catch (error) {
      console.log("error while Submiting:", error);
      // console.log(typeof error); //for debug

      // error.TypeError && console.log(error.TypeError); //for debug

      message.error(error.message || "Gặp lỗi trong quá trình tạo action!");

      if (error.message === "Not enough uid #1") {
        setErrorMes(
          "Không đủ uid để chia đều cho các nick, vui lòng tăng số uid hoặc giảm số nick kết bạn!"
        );
      } else if (
        error === "TypeError: Failed to construct 'URL': Invalid URL"
      ) {
        setErrorMes("Link group không hợp lệ, vui lòng nhập lại!");
      } else {
        setErrorMes(error.message || "Đã có lỗi xảy ra, vui lòng kiểm tra lại!");
      }

      setBtnLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed Submit:", errorInfo);
    message.error("Vui lòng hoàn thành biểu mẫu trước khi tạo!");
  };

  const onActionChange = (value) => {
    console.log(`action: ${value}`); //for debug
    setAction(value);
  };

  const viewDetailAction = (text, record, index) => {
    // console.log("text:", text); //for debug
    // console.log("record:", record); //for debug
    let detailAction = dataRaw.find(({ _id }) => _id === record._id);
    console.log("detailAction: ", detailAction); //for debug
    SetDataRawSelected(detailAction);

    setIsDetailVisible(!isDetailVisible);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowNum(selectedRowKeys.length);
      setListIdToDelete({ ids: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
    type: "checkbox",
  };

  const deleteActions = async () => {
    // console.log("listIdToDelete", listIdToDelete); // for debug
    if (listIdToDelete && selectedRowNum) {
      try {
        setIsDeleteBtnLoading(true);
        await fetchData({
          method: "DELETE",
          url: delete_action,
          body: JSON.stringify(listIdToDelete),
          headers: { "Content-Type": "application/json" },
        });

        setListIdToDelete(null);
        setIsDeleteBtnLoading(false);
        setSelectedRowNum(0);
        fetchAndRenderData();
        message.success(`Đã xóa ${selectedRowNum} hành động!`);
      } catch (error) {
        // console.log("error while deleting:", error);
        message.error("Có lỗi xảy ra, vui lòng tải lại trang!");
      }
    } else {
      message.info("Vui lòng chọn ít nhất 1 hành động.");
    }
  };

  const handleReloadData = async () => {
    setIsSpinning(true);
    await fetchAndRenderData();
    setIsSpinning(false);
  };

  const parseMenu = (list) => {
    let map = {},
      node,
      i;
    let roots = [];

    for (i = 0; i < list.length; i++) {
      map[list[i].folder_id] = i;
      list[i].children = [];
    }

    for (i = 0; i < list.length; i++) {
      node = list[i];
      node["title"] = node.folder_name;
      node["key"] = node.folder_id;
      node["value"] = node.folder_id;
      if (node.parent_id !== 0) {
        try {
          list[map[node.parent_id]].children.push(node);
        } catch (err) {
          console.log(node);
        }
      } else {
        // rootSubmenuKeys.push("folder_" + node.folder_id);
        roots.push(node);
      }
    }
    return roots;
  };

  const fetchListNickFolder = async () => {
    try {
      const res = await fetchData({ url: get_list_folder });
      const parsedFolderData = parseMenu(res["data"]);
      // console.log("treeDataFolder:", parsedFolderData); //for debug
      setTreeDataNickFolder(parsedFolderData);
    } catch (error) {
      console.log("error while getting nick folder: ", error);
      message.error("Gặp lỗi trong quá trình tải nick folder!");
    }
  };

  // const propsUploadTxt = {
  //   name: "file",
  //   accept: ".txt",
  //   action: `${process.env.REACT_APP_API}/${uploads}`,
  //   headers: {
  //     authorization: `JWT ${AccountMetaData.getToken()}`,
  //   },
  //   onChange(info) {
  //     if (info.file.status !== "uploading") {
  //       // console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //       // console.log("success", info); //for debug
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const propsUploadImage = {
    name: "file",
    accept: "image/*",
    action: `${process.env.REACT_APP_API}/${uploads}`,
    headers: {
      authorization: `JWT ${AccountMetaData.getToken()}`,
    },
    listType: "picture-card",
    onChange: (info) => {
      if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        return;
      }
      setListFile(info.fileList);
    },

    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    },
    fileList: listFile,
  };

  const propsPagination = {
    total: total,
    pageSize: pageSize,
    current: page,
    pageSizeOptions: [20, 50, 100, 200, 500, 1000],
    showSizeChanger: total > 20 ? true : false,
    // showSizeChanger
    onChange: (pg, pgSize) => {
      // console.log("page:", pg); //for debug
      // console.log("page_size:", pgSize); //for debug
      setPage(pg);
      setPageSize(pgSize);
    },
  };

  const handleFilterForm = (changedValues, allValues) => {
    // console.log("changes:", changedValues); //for debug
    // console.log("all:", allValues); //for debug

    if (changedValues.uid || changedValues.device_id) {
      let timer;
      clearTimeout(timer);
      timer = setTimeout(() => {
        setFilter(allValues);
      }, 2000);
    } else {
      setFilter(allValues);
    }
  };

  const resetFilter = () => {
    if (Object.keys(filter).length) {
      if (filterRef.current) {
        filterRef.current.resetFields();
      }
      setFilter({});
    } else {
      message.info("Bạn chưa có bộ lọc nào đang chạy.");
    }
  };

  const parseMenuContent = (list) => {
    let map = {},
      node,
      i;
    let roots = [];

    for (i = 0; i < list.length; i++) {
      map[list[i].folder_id] = i;
      list[i].children = [];
    }

    for (i = 0; i < list.length; i++) {
      node = list[i];
      node["title"] = node.name;
      node["key"] = node.id;
      node["value"] = node.id;
      if (node.parent_id !== 0) {
        try {
          // list[map[node.parent_id]].children.push(node);
          let parent = list.find((fol) => fol.id === node.parent_id);
          parent.children.push(node);
        } catch (err) {
          console.log(err, node);
        }
      } else {
        // rootSubmenuKeys.push("folder_" + node.folder_id);
        roots.push(node);
      }
    }
    return roots;
  };

  const getAndRenderListFolderContent = async () => {
    try {
      const listFolderDataRaw = await fetchData({
        url: get_list_folder_content,
      });
      // console.log(listFolderDataRaw.data); //for debug
      // setListFolderRaw(listFolderDataRaw.data);

      let obj = { ...listFolderDataRaw };

      //init all folder
      const initFolderAll = {
        name: "Tất cả",
        parent_id: 0,
        id: 0,
        _id: "all",
      };

      obj.data.unshift(initFolderAll);
      //end of init all folder

      const treeData = parseMenuContent(obj.data);
      // console.log("treeData", treeData); //for debug
      setTreeDataContentFolder(treeData);
      // message.success("Welcome back!");
    } catch (error) {
      console.log("error while getting list content folder: ", error);
      message.error("Gặp lỗi trong quá trình tải content folder!");
    }
  };

  const convertBadge = (platform) => {
    switch (platform) {
      case "facebook":
        return (
          <>
            <i class="fab fa-facebook-f"></i>
            {` ${platform}`}
          </>
        );
      case "instagram":
        return (
          <>
            <i class="fab fa-instagram"></i>
            {` ${platform}`}
          </>
        );
      case "kakaotalk":
        return "Kakao Talk";

      default:
        return platform;
    }
  };

  function b64EncodeUnicode(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode("0x" + p1);
      })
    );
  }

  function b64DecodeUnicode(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  // const openCheckNickInstaModal = () => {
  //   setIsCheckNickModalVisible(true);
  // };

  //effects
  useEffect(() => {
    fetchAndRenderData();

    //clear form values and inputs
    setAction(null);

    if (formRef.current) {
      formRef.current.resetFields();
    }
    // setData(dummyData);
    // console.log(action);
    console.log("filter", filter); //for debug
    console.log("Object.keys(filter).length:", Object.keys(filter).length); //for debug
  }, [props.platform, page, pageSize, filter]);

  useEffect(() => {
    if (filterRef.current) {
      filterRef.current.resetFields();
    }
    if (filter) {
      setFilter({});
    }
  }, [props.platform]);

  useEffect(() => {
    if (
      action &&
      (action.includes("facebook") ||
        action.includes("instagram") ||
        action.includes("kakaotalk"))
    ) {
      fetchListNickFolder();
      // console.log(action); //for debug
    }

    if (action?.includes("post_wall") || action?.includes("post_group")) {
      getAndRenderListFolderContent();
    }
  }, [action]);

  //columns of table
  const column = [
    {
      title: "Thiết bị / UID",
      dataIndex: "device_uid",
      key: "device_uid",
    },
    // {
    //   title: "Đang chạy",
    //   dataIndex: "running",
    //   key: "running",
    // },
    // {
    //   title: "Nội dung",
    //   dataIndex: "content",
    //   key: "content",
    // },
    // {
    //   title: "Mục tiêu",
    //   dataIndex: "goal",
    //   key: "goal",
    // },

    {
      title: "Tập lệnh",
      dataIndex: "command",
      key: "command",
    },
    {
      title: "Trạng thái lệnh",
      dataIndex: "commandStatus",
      key: "commandStatus",
      render: (dataIndex) => {
        switch (dataIndex) {
          case 1:
            return <Tag color="processing">đang xử lý</Tag>;
          case 2:
            return <Tag color="success">thành công</Tag>;
          case 3:
            return <Tag color="error">thất bại</Tag>;

          default:
            return <Tag color="processing">unkown!</Tag>;
        }
      },
    },
    {
      title: "Bắt đầu chạy",
      dataIndex: "begin",
      key: "begin",
    },
    {
      title: "Log",
      dataIndex: "log",
      key: "log",
      width: "200px",
      render: (text) => {
        const logArr = text.map((dt, i) => {
          return `${moment.unix(dt.time / 1000).format("DD-MM HH:mm:SS")} >> ${dt.log
            }`;
        });
        // console.log(text, record); //for debug

        return (
          <Input.TextArea value={logArr.join("\n")} rows={1}></Input.TextArea>
        );
      },
    },
    // {
    //   title: "Kết thúc",
    //   dataIndex: "end",
    //   key: "end",
    // },
    {
      title: "Hành động",
      key: "action",
      render: (text, record, index) => (
        <Tooltip title="Chi tiết hành động">
          <Button
            type="primary"
            size="medium"
            onClick={() => {
              viewDetailAction(text, record, index);
            }}
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            // }}
            // icon={<EyeOutlined />}
            shape="circle"
          >
            <i class="far fa-eye"></i>
          </Button>
        </Tooltip>
      ),
      align: "center",
    },
  ];

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };
  // console.log(dataRawSelected);
  //main return

  return (
    <React.Fragment>
      <Badge.Ribbon text={convertBadge(props.platform)}>
        <div className="containerAlternative">
          <div className="features">
            <Form
              className="featuresForm"
              name="filterForm"
              onValuesChange={handleFilterForm}
              ref={filterRef}
              autoComplete={false}
            >
              <Form.Item name="uid">
                <Input
                  placeholder="UID, tên đăng nhập"
                  className="featuresItem"
                  allowClear={true}
                />
              </Form.Item>

              <Form.Item name="device_id">
                <Input
                  placeholder="Thiết bị"
                  className="featuresItem"
                  allowClear={true}
                />
              </Form.Item>

              <Form.Item name="status">
                <Select
                  placeholder="Trạng thái hành động"
                  className="featuresItem"
                >
                  <Option value={1}>Đang xử lý</Option>
                  <Option value={2}>Thành công</Option>
                  <Option value={3}>Thất bại</Option>
                </Select>
              </Form.Item>

              <Form.Item name="action_name">
                <Select
                  placeholder="Loại hành động"
                  className="featuresItem"
                >
                  {allActionsObj
                    .filter((obj1) => obj1.key.includes(props.platform))
                    .map((obj2) => {
                      return (
                        <Option key={obj2.key} value={obj2.key}>
                          {obj2.value}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item name="resetFilter">
                <Tooltip title="Reset bộ lọc">
                  <Button
                    onClick={resetFilter}
                    size="medium"
                    type="primary"
                    style={{
                      marginRight: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    icon={<FilterOutlined />}
                  >
                    Xóa lọc
                  </Button>
                </Tooltip>
              </Form.Item>

              {/* {props.platform === "instagram" && (
                  <Form.Item name="checkLiveDieInstagram">
                    <Tooltip title="Check nick live/die">
                      <Button
                        onClick={openCheckNickInstaModal}
                        size="medium"
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Check nick
                      </Button>
                    </Tooltip>
                  </Form.Item>
                )} */}
            </Form>

            <div className="featuresButton">
              <Tooltip title="Thêm mới hành động">
                <Button
                  type="primary"
                  onClick={addAnAction}
                  className="featuresButtonItem"
                >
                  + Thêm mới
                </Button>
              </Tooltip>

              <Tooltip title="Xóa hành động đã chọn">
                <Button
                  type="danger"
                  onClick={deleteActions}
                  loading={isDeleteBtnLoading}
                  style={{ alignItems: "center", display: "flex" }}
                  className="featuresButtonItem"
                >
                  <DeleteOutlined />
                  Xóa
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className="subFeatures">
            <Button type="default" size="small" className="subFeaturesItem">
              Tất cả: {total}
            </Button>
            <Button type="default" size="small" className="subFeaturesItem">
              Đã chọn: {selectedRowNum}
            </Button>

            <Tag color="processing">
              đang xử lý: {dataRaw.filter((dt) => dt.status === 1).length}
            </Tag>

            <Tag color="success">
              thành công: {dataRaw.filter((dt) => dt.status === 2).length}
            </Tag>
            <Tag color="error">
              Thấy bại: {dataRaw.filter((dt) => dt.status === 3).length}
            </Tag>

            <Tooltip title="làm mới bảng">
              <Button
                type="primary"
                size="small"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleReloadData}
                className="subFeaturesItem"
                icon={<SyncOutlined spin={isSpinning} />}
                shape="circle"
              />
            </Tooltip>
          </div>

          <div className="paginationForTable">
            <Pagination {...propsPagination} />
          </div>

          <div className="mainTable">
            {isTableLoading ? (
              <Skeleton active />
            ) : (
              <Table
                columns={column}
                dataSource={data}
                rowKey={(record) => record._id}
                rowSelection={rowSelection}
                pagination={false}
              ></Table>
            )}
          </div>
          <Modal
            title="Tạo hành động"
            visible={isModalVisible}
            // onOk={handleOk}
            onCancel={handleCancel}
            width="60%"
            // footer={
            //   action === "farm_nick_facebook" ? (
            //     <p>
            //       Mỗi quá trình nuôi sẽ kéo dài từ 2-4 phút. Số like, số
            //       lượt kết bạn sẽ từ 3-5, số comment sẽ từ 1-2 lần
            //     </p>
            //   ) : (
            //     {
            //       if(errorMes) {
            //         return <p style={{ color: "red" }}>{errorMes}</p>;
            //       },
            //     }
            //   )
            // }
            footer={
              (action === "farm_nick_facebook" && (
                <p style={{ textAlign: "left" }}>
                  Mỗi quá trình nuôi sẽ kéo dài từ 2-4 phút. Số like, số
                  lượt kết bạn sẽ từ 3-5, số comment sẽ từ 1-2 lần
                </p>
              )) ||
              (action === "join_spam_group_kakaotalk" && (
                <p style={{ textAlign: "left" }}>
                  Không thêm tin nhắn nếu muốn các nick chỉ tham gia nhóm
                  chat (nếu chưa tham gia).Nếu có thêm tin nhắn, máy sẽ tham
                  gia (nếu chưa tham gia) và spam các tin nhắn đã thêm như
                  bình thường.
                </p>
              )) ||
              (errorMes && <p style={{ color: "red" }}>{errorMes}</p>)

              // errorMes && <p style={{ color: "red" }}>{errorMes}</p>
            }
          >
            <Form
              ref={formRef}
              name="create_action"
              labelAlign="left"
              labelWrap={true}
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 18,
              }}
              style={{ paddingRight: "5px" }}
              initialValues={{
                // remember: true,
                // actionName: "hành động",
                loopTime: 1,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Loại hành động"
                name="actionName"
                rules={[
                  {
                    required: true,
                    message: "Hãy chọn một hành động!",
                  },
                ]}
              >
                <Select
                  placeholder="chọn một loại hành động"
                  onChange={onActionChange}
                >
                  {allActionsObj
                    .filter((obj1) => obj1.key.includes(props.platform))
                    .map((obj2) => {
                      return (
                        <Option key={obj2.key} value={obj2.key}>
                          {obj2.value}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              {/* //Tiki */}
              {action === "pay_electricity_bill_tiki" && (
                <React.Fragment>
                  <Form.Item
                    label="User Tiki"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập tên tài khoản!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password Tiki"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập mật khẩu!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    label="Mã đơn"
                    name="oderCode"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập mã đơn hàng!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Số thẻ tín dụng"
                    name="CardNumber"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập số thẻ!",
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>

                  <Form.Item
                    label="Tên thẻ tín dụng"
                    name="CardName"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập tên thẻ!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Ngày hết hạn thẻ"
                    name="CardDate"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập ngày hết hạn thẻ!",
                      },
                    ]}
                  >
                    <Input type="date" />
                  </Form.Item>

                  <Form.Item
                    label="Mã CVV thẻ"
                    name="CardCvv"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập mã CVV thẻ!",
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </React.Fragment>
              )}
              {action === "tiki_coin" && (
                <React.Fragment>
                  <Form.Item
                    label="User Tiki"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập tên tài khoản!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password Tiki"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập mật khẩu!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    label="Link sản phẩm"
                    name="productUrl"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập link sản phẩm!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </React.Fragment>
              )}
              {/* //facebook seeding */}

              {action === "seeding_uid_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng nick nuôi"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick nuôi!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    name="farmOption"
                    extra="( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )"
                    label="Tùy chọn tương tác"
                  >
                    <Checkbox.Group>
                      <Checkbox
                        value="likeWhileView"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Like post
                      </Checkbox>

                      <Checkbox
                        value="commetWhileView"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Comment post
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>

                  <Form.Item
                    label="Số post tương tác"
                    name="postToInteractnum"
                    tooltip="Số lượng tối đa bài viết tương tác/1 UID, hệ thống sẽ tự động random để tránh trùng lặp"
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    label="Nội dung comment"
                    name="commentContent"
                    extra="(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)"
                  >
                    <Input.TextArea
                      defaultValue="haha"
                      rows={3}

                    // disabled={true}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Danh sách UID"
                    name="uidList"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập danh sách UID!",
                      },
                    ]}
                    extra="* Mỗi UID 1 dòng *"
                  >
                    <Input.TextArea
                      allowClear={true}
                      rows={3}
                      placeholder="..."
                    />
                  </Form.Item>
                </React.Fragment>
              )}

              {action === "view_livestream_facebook" && <WidgetViewLiveStream treeDataNickFolder={treeDataNickFolder} />}

              {action === "add_friend_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng nick nuôi"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick nuôi!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    name="farmOptionRadio"
                    label="Tùy chọn kết bạn"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn một tùy chọn!",
                      },
                    ]}
                    tooltip={
                      <ul>
                        <li>
                          Trùng UID: Các nick sẽ kết bạn với cùng một tệp
                          UID
                        </li>
                        <li>
                          Không trùng UID: Mỗi nick sẽ kết bạn với 1 tập UID
                          khác nhau
                        </li>
                      </ul>
                    }
                  >
                    <Radio.Group>
                      <Row>
                        <Col span={12}>
                          <Radio
                            value="sameUid"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Trùng UID
                          </Radio>
                        </Col>
                        <Col span={12}>
                          <Radio
                            value="separateUid"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Không trùng UID
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="Giới hạn kết bạn / 1 nick"
                    name="maxNumAddFriend"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập giới hạn kết bạn!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    name="farmOption"
                    label="Tùy chọn nuôi"
                    tooltip={
                      "Random lướt xem và like bài viết trên tường UID khi kết bạn (chỉ xem khoảng 30-40% lượng UID, mỗi quá trình kéo dài khoảng 1 phút)"
                    }
                  >
                    <Checkbox.Group>
                      <Checkbox
                        value="likeWhileView"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Like post trên tường khi kết bạn
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>

                  <Form.Item
                    label="Danh sách UID"
                    name="uidList"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập danh sách UID!",
                      },
                    ]}
                    extra="* Mỗi UID 1 dòng *"
                  >
                    <Input.TextArea
                      allowClear={true}
                      rows={3}
                      placeholder="..."
                    />
                  </Form.Item>
                </React.Fragment>
              )}

              {action === "interact_regular_video_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Link video post Facebook"
                    name="linkPost"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập link bài post!",
                      },
                    ]}
                    extra="(* Lưu ý: bài post phải được đặt chế độ công khai *)"
                  >
                    <Input placeholder="Format mẫu: https://www.facebook.com/page_name/videos/id_post/" />
                  </Form.Item>

                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng nick nuôi"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick nuôi!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    name="farmOption"
                    extra="( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )"
                    label="Tùy chọn tương tác"
                  >
                    <Checkbox.Group>
                      <Row>
                        <Col span={12}>
                          <Checkbox
                            value="likeWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Like post
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            value="commetWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Comment post
                          </Checkbox>
                        </Col>

                        <Col span={12}>
                          <Checkbox
                            value="viewNotification"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Xem bình luận
                          </Checkbox>
                        </Col>

                        <Col span={12}>
                          <Checkbox
                            value="shareWhileView"
                            style={{ lineHeight: "32px" }}
                          >
                            Share bài viết
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item
                    label="Nội dung share"
                    name="shareContent"
                    extra="(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)"
                  >
                    <Input.TextArea
                      defaultValue="haha"
                      rows={3}

                    // disabled={true}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Nội dung comment"
                    name="commentContent"
                    extra="(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)"
                  >
                    <Input.TextArea
                      defaultValue="haha"
                      rows={3}

                    // disabled={true}
                    />
                  </Form.Item>
                </React.Fragment>
              )}

              {action === "interact_regular_post_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Link post Facebook"
                    name="linkPost"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập link bài post!",
                      },
                    ]}
                    extra="(* Lưu ý: bài post phải được đặt chế độ công khai *)"
                  >
                    <Input.TextArea
                      placeholder="Mỗi link bài viết tương ứng 1 dòng..."
                      rows={3}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng nick nuôi"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick nuôi!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    name="farmOption"
                    extra="( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )"
                    label="Tùy chọn tương tác"
                  >
                    <Checkbox.Group>
                      <Row>
                        <Col span={12}>
                          <Checkbox
                            value="likeWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Like post
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            value="commetWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Comment post
                          </Checkbox>
                        </Col>

                        <Col span={12}>
                          <Checkbox
                            value="viewNotification"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Xem bình luận
                          </Checkbox>
                        </Col>

                        <Col span={12}>
                          <Checkbox
                            value="shareWhileView"
                            style={{ lineHeight: "32px" }}
                          >
                            Share bài viết
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item
                    label="Nội dung share"
                    name="shareContent"
                    extra="(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)"
                  >
                    <Input.TextArea
                      defaultValue="haha"
                      rows={3}

                    // disabled={true}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Nội dung comment"
                    name="commentContent"
                    extra="(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)"
                  >
                    <Input.TextArea
                      defaultValue="haha"
                      rows={3}

                    // disabled={true}
                    />
                  </Form.Item>
                </React.Fragment>
              )}

              {action === "interact_group_post_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Link post Facebook"
                    name="linkPost"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập link bài post!",
                      },
                    ]}
                    extra="(* Lưu ý: bài post phải được đặt chế độ công khai *)"
                    tooltip="Mỗi link bài viết tương ứng 1 dòng"
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder="Format mẫu: https://www.facebook.com/groups/id_group/posts/id_post"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng nick nuôi"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick nuôi!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    name="farmOption"
                    extra="( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )"
                    label="Tùy chọn tương tác"
                  >
                    <Checkbox.Group>
                      <Row>
                        <Col span={12}>
                          <Checkbox
                            value="likeWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Like post
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            value="commetWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Comment post
                          </Checkbox>
                        </Col>

                        <Col span={12}>
                          <Checkbox
                            value="viewNotification"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Xem bình luận
                          </Checkbox>
                        </Col>

                        <Col span={12}>
                          <Checkbox
                            value="shareWhileView"
                            style={{ lineHeight: "32px" }}
                          >
                            Share bài viết
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>

                  <Form.Item
                    label="Nội dung share"
                    name="shareContent"
                    extra="(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)"
                  >
                    <Input.TextArea
                      defaultValue="haha"
                      rows={3}

                    // disabled={true}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Nội dung comment"
                    name="commentContent"
                    extra="(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)"
                  >
                    <Input.TextArea
                      defaultValue="haha"
                      rows={3}

                    // disabled={true}
                    />
                  </Form.Item>
                </React.Fragment>
              )}

              {action === "farm_group_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng nick nuôi"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick nuôi!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    name="farmOption"
                    extra="( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )"
                    label="Tùy chọn nuôi"
                  >
                    <Checkbox.Group>
                      <Row>
                        <Col span={12}>
                          <Checkbox
                            value="likeWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Like khi lướt Feed Group
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            value="commetWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Comment khi lướt Feed Group
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            value="viewNotification"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Xem thông báo
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item
                    label="Nội dung share"
                    name="shareContent"
                    extra="(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)"
                  >
                    <Input.TextArea
                      defaultValue="haha"
                      rows={3}

                    // disabled={true}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Nội dung comment"
                    name="commentContent"
                    extra="(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)"
                  >
                    <Input.TextArea
                      defaultValue="haha"
                      rows={3}

                    // disabled={true}
                    />
                  </Form.Item>
                </React.Fragment>
              )}

              {action === "join_group_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng nick join"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick join!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    label="Nội dung câu trả lời"
                    name="answerContent"
                  >
                    <Input.TextArea
                      defaultValue="okey"
                      rows={3}
                      placeholder="Mỗi câu trả lời tương ứng một dòng (khi chạy sẽ random)"
                    // disabled={true}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Danh sách link nhóm"
                    name="linkGroup"
                    rules={[
                      {
                        required: true,
                        message: "Vui nhập danh sách link group!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder="Mỗi link group tương ứng một dòng"

                    // disabled={true}
                    />
                  </Form.Item>
                </React.Fragment>
              )}

              {action === "post_wall_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng nick đăng"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick đăng!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    label="Tùy chọn nội dung"
                    tooltip={
                      <ul>
                        <li>
                          Chọn từ thư viện: random 1 trong số các nội dung
                          trong folder đã chọn
                        </li>
                      </ul>
                    }
                    name="switchOption"
                    extra={
                      checked
                        ? "** Nhập nội dung thủ công **"
                        : "** sử dụng nội dung có sẵn từ thư viện **"
                    }
                  >
                    <Switch
                      onChange={(checked) => {
                        // console.log(`switch to ${checked}`); //for debug
                        setChecked(checked);
                      }}
                    // disabled={true} //will remove later
                    />
                  </Form.Item>

                  {checked ? (
                    <React.Fragment>
                      <Form.Item
                        label="Nội dung bài đăng"
                        name="postContent"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng tải lên nội dung post!",
                          },
                        ]}
                      >
                        {/* <Upload {...propsUploadTxt}>
                            <Button htmlType="button" icon={<UploadOutlined />}>
                              Click to Upload (Có thể có nhiều file để random)
                            </Button>
                          </Upload> */}
                        <Input.TextArea
                          rows={3}
                          allowClear={true}
                          placeholder="..."
                        />
                      </Form.Item>

                      <Form.Item
                        label="Ảnh trong bài đăng"
                        name="postImage"
                        extra="(* Tối đa 5 ảnh *)"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Vui lòng nhập nội dung bài post!",
                      //   },
                      // ]}
                      >
                        <Upload {...propsUploadImage}>
                          {listFile.length >= 5 ? null : (
                            <div>
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                          )}
                        </Upload>
                      </Form.Item>

                      <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={() => setPreviewVisible(false)}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Form.Item
                        label="Folder nội dung"
                        tooltip={
                          "Folder nội dung ứng với các folder ở màn quản lí content"
                        }
                        name="contentFolder"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn folder chứa content!",
                          },
                        ]}
                      >
                        <TreeSelect
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                          }}
                          treeData={treeDataContentFolder}
                          placeholder="Hãy chọn một folder"
                          treeDefaultExpandAll={false}
                        />
                        {/* <Input /> */}
                      </Form.Item>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {action === "post_group_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng nick đăng"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick đăng!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    label="Link nhóm đăng bài"
                    name="linkGroup"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập danh sách link nhóm!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder="Mỗi link group tương ứng một dòng..."
                    ></Input.TextArea>
                  </Form.Item>

                  <Form.Item
                    label="Tùy chọn nội dung"
                    tooltip={
                      <ul>
                        <li>
                          Chọn từ thư viện: random 1 trong số các nội dung
                          trong folder đã chọn
                        </li>
                      </ul>
                    }
                    name="switchOption"
                    extra={
                      checked
                        ? "** Nhập nội dung thủ công **"
                        : "** sử dụng nội dung có sẵn từ thư viện **"
                    }
                  >
                    <Switch
                      onChange={(checked) => {
                        // console.log(`switch to ${checked}`); //for debug
                        setChecked(checked);
                      }}
                    // disabled={true} //will remove later
                    />
                  </Form.Item>

                  {checked ? (
                    <React.Fragment>
                      <Form.Item
                        label="Nội dung bài đăng"
                        name="postContent"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng tải lên nội dung post!",
                          },
                        ]}
                      >
                        {/* <Upload {...propsUploadTxt}>
                            <Button htmlType="button" icon={<UploadOutlined />}>
                              Click to Upload (Có thể có nhiều file để random)
                            </Button>
                          </Upload> */}
                        <Input.TextArea
                          rows={3}
                          allowClear={true}
                          placeholder="..."
                        />
                      </Form.Item>

                      <Form.Item
                        label="Ảnh trong bài đăng"
                        name="postImage"
                        extra="(* Tối đa 5 ảnh *)"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Vui lòng nhập nội dung bài post!",
                      //   },
                      // ]}
                      >
                        <Upload {...propsUploadImage}>
                          {listFile.length >= 5 ? null : (
                            <div>
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                          )}
                        </Upload>
                      </Form.Item>

                      <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={() => setPreviewVisible(false)}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Form.Item
                        label="Folder nội dung"
                        tooltip={
                          "Folder nội dung ứng với các folder ở màn quản lí content"
                        }
                        name="contentFolder"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn folder chứa content!",
                          },
                        ]}
                      >
                        <TreeSelect
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                          }}
                          treeData={treeDataContentFolder}
                          placeholder="Hãy chọn một folder"
                          treeDefaultExpandAll={false}
                        />
                        {/* <Input /> */}
                      </Form.Item>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {action === "farm_nick_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng nick nuôi"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick nuôi!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    name="farmOption"
                    extra="( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )"
                    label="Tùy chọn nuôi"
                  >
                    <Checkbox.Group>
                      <Row>
                        <Col span={12}>
                          <Checkbox
                            value="likeWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Like khi lướt Feed (Watch)
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            value="commetWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Comment khi lướt Feed (Watch)
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            value="viewNotification"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Xem thông báo
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            value="viewAndLikeStory"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Xem và like story
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            value="addFriendWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Kết bạn gợi ý
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            value="viewFacebookWatch"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Lướt xem Facebook Watch
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>

                  <Form.Item
                    label="Nội dung comment"
                    name="commentContent"
                    extra="(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)" // extra="(* Mỗi quá trình nuôi sẽ kéo dài từ 2-4 phút, số like, số lượt kết bạn sẽ từ 3-5, số comment sẽ từ 1-2 lần *)"
                  >
                    <Input.TextArea
                      defaultValue="haha"
                      rows={3}

                    // disabled={true}
                    />
                  </Form.Item>
                </React.Fragment>
              )}

              {action === "login_nick_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  {/* <Form.Item
                        label="Folder đẩy nick checkpoint"
                        name="nickFolderCp"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn folder chứa nick!",
                          },
                        ]}
                      >
                        <Input placeholder="Nhập folder id..." />
                      </Form.Item> */}

                  <Form.Item
                    label="Số lượng nick login"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick login!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>
                </React.Fragment>
              )}

              {action === "like_post_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Link post Facebook"
                    name="linkPost"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập link bài post!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng like!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>
                </React.Fragment>
              )}
              {action === "comment_post_facebook" && (
                <React.Fragment>
                  <Form.Item
                    label="Link post Facebook"
                    name="linkPost"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập link bài post!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng comment!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    label="Nội dung comment"
                    name="commentContent"
                    extra="(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập nội dung comment!",
                      },
                    ]}
                  >
                    <Input.TextArea rows={5} />
                  </Form.Item>
                </React.Fragment>
              )}

              {/* instagram */}
              {action === "farm_nick_instagram" && (
                <React.Fragment>
                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng nick nuôi"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick nuôi!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    name="farmOption"
                    extra="( Khi chọn like, hệ thống sẽ tự động random 1 reaction (haha, wow,..) )"
                    label="Tùy chọn nuôi"
                  >
                    <Checkbox.Group>
                      <Row>
                        <Col span={12}>
                          <Checkbox
                            value="likeWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Like khi lướt Feed
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            value="commetWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Comment khi lướt Feed
                          </Checkbox>
                        </Col>

                        <Col span={12}>
                          <Checkbox
                            value="viewAndLikeStory"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Xem story
                          </Checkbox>
                        </Col>
                        <Col span={12}>
                          <Checkbox
                            value="addFriendWhileView"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Follow bạn gợi ý
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item
                    label="Nội dung share"
                    name="shareContent"
                    extra="(* Mỗi nội dung tương ứng một dòng, hệ thống sẽ tự động random *)"
                  >
                    <Input.TextArea
                      defaultValue="haha"
                      rows={3}

                    // disabled={true}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Nội dung comment"
                    name="commentContent"
                    extra="(* Mỗi comment tương ứng một dòng, hệ thống sẽ tự động random *)"
                  >
                    <Input.TextArea
                      defaultValue="haha"
                      rows={3}

                    // disabled={true}
                    />
                  </Form.Item>
                </React.Fragment>
              )}

              {action === "post_wall_instagram" && (
                <React.Fragment>
                  <Form.Item
                    label="Folder sử dụng nick"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Số lượng nick đăng"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số lượng nick đăng!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    label="Tùy chọn nội dung"
                    tooltip={
                      <ul>
                        <li>
                          Chọn từ thư viện: random 1 trong số các nội dung
                          trong folder đã chọn
                        </li>
                      </ul>
                    }
                    name="switchOption"
                    extra={
                      checked
                        ? "** Nhập nội dung thủ công **"
                        : "** sử dụng nội dung có sẵn từ thư viện **"
                    }
                  >
                    <Switch
                      onChange={(checked) => {
                        // console.log(`switch to ${checked}`); //for debug
                        setChecked(checked);
                      }}
                    // disabled={true} //will remove later
                    />
                  </Form.Item>

                  {checked ? (
                    <React.Fragment>
                      <Form.Item
                        label="Nội dung bài đăng"
                        name="postContent"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng tải lên nội dung post!",
                          },
                        ]}
                      >
                        {/* <Upload {...propsUploadTxt}>
                            <Button htmlType="button" icon={<UploadOutlined />}>
                              Click to Upload (Có thể có nhiều file để random)
                            </Button>
                          </Upload> */}
                        <Input.TextArea
                          rows={3}
                          allowClear={true}
                          placeholder="..."
                        />
                      </Form.Item>

                      <Form.Item
                        label="Ảnh trong bài đăng"
                        name="postImage"
                        extra="(* Tối đa 5 ảnh *)"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Vui lòng nhập nội dung bài post!",
                      //   },
                      // ]}
                      >
                        <Upload {...propsUploadImage}>
                          {listFile.length >= 5 ? null : (
                            <div>
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                          )}
                        </Upload>
                      </Form.Item>

                      <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={() => setPreviewVisible(false)}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Form.Item
                        label="Folder nội dung"
                        tooltip={
                          "Folder nội dung ứng với các folder ở màn quản lí content"
                        }
                        name="contentFolder"
                        rules={[
                          {
                            required: true,
                            message: "Vui lòng chọn folder chứa content!",
                          },
                        ]}
                      >
                        <TreeSelect
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                          }}
                          treeData={treeDataContentFolder}
                          placeholder="Hãy chọn một folder"
                          treeDefaultExpandAll={false}
                        />
                        {/* <Input /> */}
                      </Form.Item>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              {/* kakaotalk */}
              {action === "join_spam_group_kakaotalk" && (
                <React.Fragment>
                  <Form.Item
                    label="Số máy chạy"
                    name="num"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn số máy muốn chạy!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    label="Folder tạo action"
                    name="nickFolder"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn folder chứa nick!",
                      },
                    ]}
                  >
                    <TreeSelect
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      treeData={treeDataNickFolder}
                      placeholder="Hãy chọn một folder"
                      treeDefaultExpandAll={false}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Danh sách link nhóm"
                    name="linkGroup"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập danh sách link group!",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder="Mỗi link group tương ứng một dòng"

                    // disabled={true}
                    />
                  </Form.Item>

                  {/* <Form.Item
                        label="Số nick cần chạy / 1 máy"
                        name="nickNum"
                        tooltip="Bỏ trống nếu muốn chạy tất cả các nick có trong máy."
                      >
                        <InputNumber min={0} />
                      </Form.Item>*/}

                  <Form.Item
                    label="Số tin nhắn spam/lần"
                    name="mesNum"
                    tooltip="Số tin nhắn spam vào mỗi nhóm, mặc định là số tin nhắn nhập phía dưới."
                  >
                    <InputNumber min={0} />
                  </Form.Item>

                  <Form.Item
                    label="Số lần lặp lại"
                    name="loopTime"
                    tooltip="Số lần lặp lại hành động, mặc định là 1."
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập số lần lặp!",
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Form.Item
                    label="Thời gian nghỉ (phút)"
                    name="delay"
                    tooltip="Thời gian delay khi thao tác giữa các lần lặp. Bỏ trống nếu không muốn delay."
                  >
                    <InputNumber min={0} placeholder="Phút" />
                  </Form.Item>

                  {/* <Form.Item name="loopOption" label="Tùy chọn lặp">
                        <Checkbox.Group>
                          <Checkbox
                            value="infinity"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Lặp vô hạn đến khi tắt thủ công
                          </Checkbox>
                        </Checkbox.Group>
                      </Form.Item> */}

                  <Form.Item name="farmOption" label="Tùy chọn tương tác">
                    <Checkbox.Group>
                      <Checkbox
                        value="sendKakaoContact"
                        style={{
                          lineHeight: "32px",
                        }}
                      >
                        Gửi Contact KakaoTalk khi spam
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>

                  <Form.List name="messages" className="w-100">
                    {(fields, { add, remove }, { errors }) => (
                      <div className="w-100">
                        {fields.map((field, index) => (
                          <Form.Item
                            // {...(index === 0 && formItemLayoutWithOutLabel)}
                            // label={index === 0 ? "Tin nhắn" : ""}
                            label={"Tin nhắn spam " + (index * 1 + 1)}
                            required={false}
                            key={field.key}
                            className="w-100"
                          >
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message:
                                    "Vui lòng nhập văn bản tin nhắn cần spam.",
                                },
                              ]}
                            // className="w-100"
                            >
                              <Input.TextArea
                                rows={3}
                                placeholder="Nhập văn bản tin nhắn tại đây..."
                                style={{ width: "100%" }}
                              />
                            </Form.Item>

                            <Tooltip title="Xóa">
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Tooltip>
                          </Form.Item>
                        ))}

                        <Form.Item className="w-25">
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            className="w-100"
                            icon={<PlusOutlined />}
                          >
                            Thêm tin nhắn
                          </Button>

                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </div>
                    )}
                  </Form.List>
                </React.Fragment>
              )}

              <Form.Item
                wrapperCol={{
                  offset: 21,
                  // span: 16,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={btnLoading}
                >
                  Tạo mới
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          {/* detail modal */}
          {dataRawSelected && (
            <DetailActionModal
              isDetailVisible={isDetailVisible}
              actionName={dataRawSelected.options.actionName}
              //list actions obj
              allActionsObj={allActionsObj}
              //tiki
              productUrl={dataRawSelected.options.productUrl}
              CardCvv={dataRawSelected.options.CardCvv}
              CardDate={dataRawSelected.options.CardDate}
              CardName={dataRawSelected.options.CardName}
              CardNumber={dataRawSelected.options.CardNumber}
              oderCode={dataRawSelected.options.oderCode}
              password={dataRawSelected.options.password}
              username={dataRawSelected.options.username}
              //seeding facebook
              linkPost={dataRawSelected.options.linkPost}
              nickFolder={dataRawSelected.options.nickFolder}
              num={dataRawSelected.options.num}
              commentContent={dataRawSelected.options.commentContent}
              shareContent={dataRawSelected.options.shareContent}
              farmOption={dataRawSelected.options.farmOption}
              imgURL={dataRawSelected.options.imgURL}
              postContent={dataRawSelected.options.postContent}
              contentFolder={dataRawSelected.options.contentFolder}
              answerContent={dataRawSelected.options.answerContent}
              linkGroup={dataRawSelected.options.linkGroup}
              uidList={dataRawSelected.options.uidList}
              farmOptionRadio={dataRawSelected.options.farmOptionRadio}
              maxNumAddFriend={dataRawSelected.options.maxNumAddFriend}
              groupNum={dataRawSelected.options.groupNum}
              postToInteractnum={dataRawSelected.options.postToInteractnum}
              //kakaotalk
              messages={dataRawSelected.options.messages}
              logs={dataRawSelected.logs}
              delay={dataRawSelected.options.delay}
              mesNum={dataRawSelected.options.mesNum}
              loopOption={dataRawSelected.options.loopOption}
              nickNum={dataRawSelected.options.nickNum}
              loopTime={dataRawSelected.options.loopTime}
              //
              onCancel={() => {
                setIsDetailVisible(false);
              }}
              item={dataRawSelected}
            />
          )}

          {/* check nick live die instagram modal */}
          {/* {props.platform === "instagram" && (
              <ChangeDeviceId
                visible={isCheckNickModalVisible}
                onCancel={() => {
                  setIsCheckNickModalVisible(false);
                }}
                //dummy data for dev only (will be removed later)
                _id={dummyData}
              />
            )} */}
        </div>
      </Badge.Ribbon>
    </React.Fragment>
  );
};

export default CreateAction;
