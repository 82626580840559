import React, { useState, useEffect, useRef } from "react";
import "./ManageContent.scss";

import { Header, Sidebar } from "../../../component";
import {
  Tree,
  Button,
  Typography,
  Modal,
  Form,
  Input,
  message,
  Skeleton,
  Tooltip,
  Table,
  Upload,
  Pagination,
} from "antd";
import {
  FolderAddOutlined,
  FormOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  // UploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { fetchData } from "../../../lib/helpers";
import { AccountMetaData } from "../../../lib/account";

import DetailContentModal from "../components/DetailContentModal";

//dummy data
// import dummyData from "../dummyData/CreateActionTableData";
// import treeData from "../dummyData/CreateActionTableData";

const ManageContent = (props) => {
  //states

  //for folder
  const [isAddFolderModalVisible, setIsAddFolderModalVisible] = useState(false);
  const [isAddFolderBtnLoading, setIsAddFolderBtnLoading] = useState(false);
  const [parentId, setParentId] = useState(0);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [listFolder, setListFolder] = useState([]);
  const [isMenuLoading, setIsMenuLoading] = useState(true);
  const [listFolderRaw, setListFolderRaw] = useState(null);
  const [isRenameModalVisible, setIsRenameModalVisible] = useState(false);
  const [isRenameFolderBtnLoading, setIsRenameFolderBtnLoading] =
    useState(false);
  //end of for folder

  //for content
  const [isAddContentModalVisible, setIsAddContentModalVisible] =
    useState(false);
  const [isAddContentBtnLoading, setIsAddContentBtnLoading] = useState(false);
  const [listPostRaw, setListPostRaw] = useState([]);
  const [dataTableContent, setDataTableContent] = useState([]);
  const [isTableContentLoading, setIsTableContentLoading] = useState(false);

  const [selectedRowNum, setSelectedRowNum] = useState(0);
  const [listIdToDelete, setListIdToDelete] = useState([]);
  //pagination
  const [totalContent, setTotalContent] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  //filter title
  const [contentFilter, setContentFilter] = useState({});
  //upload image
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [imageNum, setImageNum] = useState(0);
  //detail & edit content
  const [isDetailContentModalVisible, setIsDetailContentModalVisible] =
    useState(false);
  const [selectedDetail, setSelectedDetail] = useState({});
  //end of for content

  //end of states

  //API URL
  //for folder
  const get_list_folder = "api/v1/post/folder/list";
  const create_folder = "api/v1/post/folder/create";
  const delete_folder = "api/v1/post/folder/delete";
  const update_folder = "api/v1/post/folder/";

  const uploads = "api/v1/uploads";

  //for content
  const create_post = "api/v1/post/create";
  const get_list_post_param = `folder_id=${selectedFolder ? fallbackString(selectedFolder.node.id) : ""
    }&title=${contentFilter.title ? contentFilter.title : ""}`;
  const get_list_post_pagination = `&page=${page}&limit=${pageSize}`;
  const get_list_post =
    "api/v1/post/list?" + get_list_post_param + get_list_post_pagination;
  const delete_list_post = "api/v1/post/delete";
  //end of API URL

  //ref
  const addFolderFormRef = useRef();
  const renameFolderRef = useRef();
  const addContentRef = useRef();
  const contentFilterRef = useRef();
  //end of ref

  //functions
  function fallbackString(str) {
    if (str === 0) {
      return "";
    }
    return str;
  }

  //for folder
  const parseMenu = (list) => {
    let map = {},
      node,
      i;
    let roots = [];

    for (i = 0; i < list.length; i++) {
      map[list[i].folder_id] = i;
      list[i].children = [];
    }

    for (i = 0; i < list.length; i++) {
      node = list[i];
      node["title"] = node.name;
      node["key"] = node.id;
      node["value"] = node.id;
      if (node.parent_id !== 0) {
        try {
          // list[map[node.parent_id]].children.push(node);
          let parent = list.find((fol) => fol.id === node.parent_id);
          parent.children.push(node);
        } catch (err) {
          console.log(err, node);
        }
      } else {
        // rootSubmenuKeys.push("folder_" + node.folder_id);
        roots.push(node);
      }
    }
    return roots;
  };

  const getAndRenderListFolder = async () => {
    try {
      setIsMenuLoading(true);
      const listFolderDataRaw = await fetchData({ url: get_list_folder });
      // console.log(listFolderDataRaw.data); //for debug
      setListFolderRaw(listFolderDataRaw.data);

      let obj = { ...listFolderDataRaw };

      //init all folder
      const initFolderAll = {
        name: "Tất cả",
        parent_id: 0,
        id: 0,
        _id: "all",
      };

      obj.data.unshift(initFolderAll);
      //end of init all folder

      const treeData = parseMenu(obj.data);
      // console.log("treeData", treeData); //for debug
      setListFolder(treeData);

      //reset selected node in menu
      setSelectedFolder(null);
      setParentId(0);
      //end of reset selected node in menu

      //reset form fields
      renameFolderRef.current && renameFolderRef.current.resetFields();
      addFolderFormRef.current && addFolderFormRef.current.resetFields();
      //end of reset form fields

      setIsMenuLoading(false);
      // message.success("Welcome back!");
    } catch (error) {
      console.log(error);
      message.error("Oops...Lỗi rồi!");
    }
  };

  const handleAddFolder = () => {
    if (selectedFolder && selectedFolder.selectedNodes.length > 1) {
      message.info("Vui lòng chỉ chọn 1 folder");
      return;
    }
    setIsAddFolderModalVisible(true);
  };

  const onFinishAddFolder = async (values) => {
    setIsAddFolderBtnLoading(true);
    // console.log(values);
    try {
      let bodyObj = { ...values };
      const body = {
        name: bodyObj.folderName,
        parent_id: parentId,
      };

      await fetchData({
        method: "POST",
        url: create_folder,
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      });

      setIsAddFolderBtnLoading(false);
      setIsAddFolderModalVisible(false);
      addFolderFormRef.current.resetFields();
      getAndRenderListFolder();
      message.success("Thêm thành công!");
    } catch (error) {
      setIsAddFolderBtnLoading(false);
      setIsAddFolderModalVisible(false);
      console.log(error);
      message.error("Gặp lỗi trong quá trình tạo folder!");
    }
  };

  const onFinishFailedAddFolder = (errorInfo) => {
    console.log("Failed Submit:", errorInfo);
    message.error("Vui lòng hoàn thành biểu mẫu trước khi tạo!");
  };

  const onFolderSelected = (selectedKeys, e) => {
    //reset pagination
    setPageSize(10);
    setPage(1);
    //end of reset pagination
    setParentId(selectedKeys[0]);
    setSelectedFolder(e);
    // console.log(e.selectedNodes[0]._id); //for debug
    // console.log("selectedKeys:", selectedKeys); //for debug
    // console.log("e:", e); // for debug
  };

  const handleDeleteFolder = () => {
    if (selectedFolder) {
      Modal.confirm({
        title: "Bạn có chắc muốn thực hiện thao tác này?",
        content: `Xóa các thư mục đã chọn. Chú ý, thao tác này sẽ xóa hết dữ liệu và các thư mục con.`,
        onOk: async () => {
          try {
            let idsArray = [];
            selectedFolder.selectedNodes.forEach((node) => {
              idsArray.push(node._id);
            });

            //handle delete all
            if (idsArray.includes("all")) {
              let idsArrayAll = [];
              listFolderRaw.forEach((folder) => {
                idsArrayAll.push(folder._id);
              });
              idsArray = idsArrayAll.filter((id) => id !== "all");
            }
            //end of handle delete all

            let body = { ids: idsArray };
            await fetchData({
              method: "DELETE",
              url: delete_folder,
              body: JSON.stringify(body),
              headers: { "Content-Type": "application/json" },
            });

            message.success(`Đã xóa folder!`);
            getAndRenderListFolder();
          } catch (error) {
            console.log(error);
            message.error("gặp lỗi trong quá trình xóa folder!");
          }
        },
      });
    } else {
      // debounce
      message.info("Vui lòng chọn một thư mục!");
    }
  };

  const handleRenameFolder = () => {
    if (
      selectedFolder &&
      selectedFolder.node._id !== "all" &&
      selectedFolder.selectedNodes.length === 1
    ) {
      setIsRenameModalVisible(true);
    } else {
      message.info("Vui lòng chọn một thư mục!");
    }
  };

  const onFinishRenameFolder = async (values) => {
    try {
      // console.log(values); //for debug

      setIsRenameFolderBtnLoading(true);
      let obj = { ...values };
      let body = { name: obj.folderName };

      await fetchData({
        method: "PUT",
        url: selectedFolder && `${update_folder}${selectedFolder.node._id}`,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      setIsRenameFolderBtnLoading(false);
      setIsRenameModalVisible(false);

      getAndRenderListFolder();
      message.success("Đổi tên thành công!");
    } catch (error) {
      console.log(error);
      message.error("gặp lỗi trong quá trình đổi tên folder!");
    }
  };

  const onFinishFailedRenameFolder = (errorInfo) => {
    console.log("Failed Submit:", errorInfo);
    message.error("Vui lòng hoàn thành biểu mẫu trước khi gửi!");
  };

  const copyFolderId = () => {
    try {
      let id = selectedFolder ? selectedFolder.node.id : 0;
      navigator.clipboard.writeText(id);
      message.success(`Đã copy Folder ID: ${id}`);
    } catch (error) {
      console.log(error);
      message.error("Gặp lỗi khi copy Folder ID!");
    }
  };
  //end of for folder

  //for content
  // const propsUploadTxt = {
  //   name: "file",
  //   accept: ".txt",
  //   action: `${process.env.REACT_APP_API}/${uploads}`,
  //   headers: {
  //     authorization: `JWT ${AccountMetaData.getToken()}`,
  //   },
  //   onChange(info) {
  //     if (info.file.status !== "uploading") {
  //       // console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //       // console.log("success", info); //for debug
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const propsUploadImage = {
    name: "file",
    accept: "image/*",
    action: `${process.env.REACT_APP_API}/${uploads}`,
    headers: {
      authorization: `JWT ${AccountMetaData.getToken()}`,
    },
    listType: "picture-card",
    onChange: (info) => {
      // if (info.file.status !== "uploading") {
      //   // console.log(info.file, info.fileList);
      // }
      // if (info.file.status === "done") {
      //   setImageNum(info.fileList.length);
      //   message.success(`${info.file.name} file uploaded successfully`);
      //   console.log("success", info); //for debug
      // } else if (info.file.status === "error") {
      //   message.error(`${info.file.name} file upload failed.`);
      // }

      if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      } else {
        setImageNum(info.fileList.length);
        // message.success(`${info.file.name} file uploaded successfully`);
        // console.log("success", info); //for debug
      }
    },
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
    },
  };

  //this fuction only use for img/txt (content) links when uploads, it returns a string of links, separated by ","
  function handleContentLinks(obj) {
    let arrResult = [];
    obj.fileList.forEach((fl) => {
      arrResult.push(fl.response.attachment.url);
    });
    // console.log(arrResult); //for debug
    // return arrResult.join(","); // for mobile to read
    return arrResult; //for webview
  }

  const getAndRenderContent = async () => {
    try {
      setIsTableContentLoading(true);

      const res = await fetchData({
        url: get_list_post,
      });

      setTotalContent(res.total);
      setListPostRaw(res.data);

      let dataTable = [];

      res.data.forEach((dt, index) => {
        // //handle folder name/id
        // const folder = listFolderRaw.filter((fol) => fol.id === dt.id)[0];
        // //end of handle folder name/id

        //handle attachment
        const attachment = dt.attachment.join("\n");
        //end of handle attachment

        dataTable.push({
          key: dt._id,
          // folder: `${folder.name}/${folder.id}`,
          content_name: dt.title,
          postContent: dt.content,
          postImage: attachment,
        });
      });

      setDataTableContent(dataTable);
      setIsTableContentLoading(false);
    } catch (error) {
      message.error("Gặp lỗi trong quá trình lấy danh sách post!");
      console.log(error);
      setIsTableContentLoading(false);
    }
  };

  const onFinishAddContent = async (values) => {
    try {
      setIsAddContentBtnLoading(true);

      const obj = { ...values };
      // console.log("obj: ", obj); //for debug

      let body = {};
      // let attachment = [];
      let folder_id;

      //folder_id
      selectedFolder ? (folder_id = selectedFolder.node.id) : (folder_id = 0);

      //attachment

      //update body values
      body.title = obj.contentName;
      body.folder_id = folder_id;
      body.content = obj.postContent;
      if (obj.postImage) {
        body.attachment = handleContentLinks(obj.postImage);
      }

      await fetchData({
        method: "POST",
        url: create_post,
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      });

      addContentRef.current.resetFields();
      setIsAddContentBtnLoading(false);
      setIsAddContentModalVisible(false);
      setImageNum(0);
      getAndRenderContent();
    } catch (error) {
      console.log("error while create new content: ", error);
      message.error("Gặp lỗi trong quá trình tạo content!");
      setImageNum(0);
      setIsAddContentBtnLoading(false);
    }
  };

  const onFinishFailedAddContent = (errorInfo) => {
    console.log("Failed Submit:", errorInfo);
    message.error("Vui lòng hoàn thành biểu mẫu trước khi tạo!");
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowNum(selectedRowKeys.length);
      setListIdToDelete({ ids: selectedRowKeys });
      console.log(selectedRowKeys, selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
    type: "checkbox",
  };

  const handleDeleteContents = () => {
    // console.log("listIdToDelete", listIdToDelete); // for debug
    if (listIdToDelete && selectedRowNum) {
      Modal.confirm({
        title: "Xóa content",
        content: `Bạn có chắc muốn xóa ${selectedRowNum} nội dung đã chọn?`,
        onOk: async () => {
          try {
            await fetchData({
              method: "DELETE",
              url: delete_list_post,
              body: JSON.stringify(listIdToDelete),
              headers: { "Content-Type": "application/json" },
            });

            setListIdToDelete(null);
            setSelectedRowNum(0);
            message.success(`Đã xóa ${selectedRowNum} nội dung!`);
            getAndRenderContent();
          } catch (error) {
            // console.log("error while deleting:", error);
            message.error("Có lỗi xảy ra, vui lòng tải lại trang!");
          }
        },
      });
    } else {
      message.info("Vui lòng chọn ít nhất 1 nội dung.");
    }
  };

  const propsPagination = {
    total: totalContent,
    pageSize: pageSize,
    current: page,
    pageSizeOptions: [5, 10, 20, 50, 100, 200, 500],
    showSizeChanger: totalContent > 5 ? true : false,
    // showSizeChanger
    onChange: (pg, pgSize) => {
      // console.log("page:", pg); //for debug
      // console.log("page_size:", pgSize); //for debug
      setPage(pg);
      setPageSize(pgSize);
    },
  };

  const handleFilterChange = (changedValues, allValues) => {
    console.log("changes:", changedValues); //for debug
    console.log("all:", allValues); //for debug

    if (changedValues.title) {
      let timer;
      clearTimeout(timer);
      timer = setTimeout(() => {
        setContentFilter(allValues);
      }, 2000);
    } else {
      setContentFilter(allValues);
    }
  };

  const viewDetailContent = (text, record, index) => {
    // console.log("detail: ", text, record, index); //for debug
    setSelectedDetail(record);
    setIsDetailContentModalVisible(true);
  };
  //end of for content

  //end of functions

  //effects
  useEffect(() => {
    getAndRenderListFolder();
  }, []);

  useEffect(() => {
    renameFolderRef.current && renameFolderRef.current.resetFields();

    getAndRenderContent();
  }, [selectedFolder, page, pageSize, contentFilter]);

  //end of effects

  //columns for content table
  const columnContentTable = [
    // {
    //   title: "Thư mục/ID",
    //   dataIndex: "folder",
    //   key: "folder",
    // },

    {
      title: "Tên",
      dataIndex: "content_name",
      key: "content_name",
      width: "100px",
    },

    {
      title: "Nội dung",
      dataIndex: "postContent",
      key: "postContent",
      render: (dataIndex) => (
        <Input.TextArea value={dataIndex} rows={3}></Input.TextArea>
      ),
    },

    {
      title: "File đính kèm",
      dataIndex: "postImage",
      key: "postImage",
      render: (dataIndex) => (
        <Input.TextArea
          value={
            dataIndex ? dataIndex : "** Post này không có file đính kèm 🤷‍♀️ **"
          }
          rows={3}
        ></Input.TextArea>
      ),
    },

    {
      title: "Hành động",
      key: "action",
      width: "100px",
      render: (text, record, index) => (
        <Tooltip title="Xem/sửa nội dung">
          <Button
            type="primary"
            size="medium"
            onClick={() => {
              viewDetailContent(text, record, index);
            }}
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            // }}
            // icon={<FormOutlined />}
            shape="circle"
          >
            <i class="far fa-edit"></i>
          </Button>
        </Tooltip>
      ),
      align: "center",
    },
  ];
  //end of columns for content table

  return (
    <React.Fragment>
      <div className="containerAlter">
        <div className="containerFolder">
          <div className="title">
            <Typography.Title level={4}>Danh sách Content</Typography.Title>
          </div>

          <div className="features">
            <div className="buttonContainer">
              <Tooltip title="Tạo folder mới" color="blue">
                <Button
                  type="ghost"
                  className="buttonContainerBtn"
                  size="large"
                  onClick={handleAddFolder}
                  // icon={<FolderAddOutlined />}
                  shape="circle"
                >
                  <img
                    src={require("../../../media/images/add-folder.png")}
                    width="25"
                  />
                </Button>
              </Tooltip>

              <Tooltip title="Xóa folder" color="red">
                <Button
                  className="buttonContainerBtn"
                  type="ghost"
                  size="large"
                  onClick={handleDeleteFolder}
                  // icon={<DeleteOutlined />}
                  shape="circle"
                >
                  <img
                    src={require("../../../media/images/del-folder.png")}
                    width="25"
                  />
                </Button>
              </Tooltip>

              <Tooltip title="Đổi tên folder">
                <Button
                  className="buttonContainerBtn"
                  type="ghost"
                  size="large"
                  onClick={handleRenameFolder}
                  icon={<EditOutlined />}
                  shape="circle"
                ></Button>
              </Tooltip>
            </div>
          </div>
          <div className="folderIdContainer">
            <Tooltip title="Nhấn để copy Folder ID">
              <Button
                // className="buttonContainerBtn"
                type="dashed"
                size="medium"
                shape="round"
                onClick={copyFolderId}
              >
                Folder ID: {selectedFolder ? selectedFolder.node.id : 0}
              </Button>
            </Tooltip>
          </div>
          <div className="folderListContainer">
            {!isMenuLoading ? (
              <Tree.DirectoryTree
                multiple
                treeData={listFolder}
                onSelect={onFolderSelected}
              />
            ) : (
              <Skeleton active />
            )}
          </div>
        </div>

        <div className="containerContent">
          <div className="contentFeatures">
            <Form
              name="contentFilter"
              ref={contentFilterRef}
              onValuesChange={handleFilterChange}
              autoComplete="off"
              className="contentFeaturesForm"
            >
              <div className="contentFeaturesBtnCon">
                <Button
                  type="default"
                  className="contentFeaturesBtn"
                  shape="round"
                >
                  Tất cả: {totalContent}
                </Button>

                <Button
                  type="dashed"
                  className="contentFeaturesBtn"
                  shape="round"
                >
                  Đã chọn: {selectedRowNum}
                </Button>

                <Tooltip title="Thêm mới content">
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    className="contentFeaturesBtn"
                    onClick={() => {
                      setIsAddContentModalVisible(true);
                    }}
                    shape="circle"
                  ></Button>
                </Tooltip>

                <Tooltip title="Xóa content">
                  <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    className="contentFeaturesBtn"
                    onClick={handleDeleteContents}
                    shape="circle"
                    danger
                  ></Button>
                </Tooltip>
              </div>
              <Tooltip title="Tìm content theo tên">
                <Form.Item name="title">
                  <Input placeholder="Tên content" allowClear={true} />
                </Form.Item>
              </Tooltip>
            </Form>
          </div>

          <div className="contentTable">
            <Pagination
              {...propsPagination}
              className="contentTablePagination"
            />
            <Table
              columns={columnContentTable}
              dataSource={dataTableContent}
              rowSelection={rowSelection}
              loading={isTableContentLoading}
              pagination={false}
            />
          </div>
        </div>

        {/* modal thêm thư mục */}
        <Modal
          title="Thêm thư mục"
          footer={null}
          visible={isAddFolderModalVisible}
          onCancel={() => {
            setIsAddFolderModalVisible(false);
          }}
        >
          <Form
            ref={addFolderFormRef}
            name="add_folder"
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 18,
            }}
            initialValues={
              {
                // remember: true,
                // actionName: "hành động",
              }
            }
            onFinish={onFinishAddFolder}
            onFinishFailed={onFinishFailedAddFolder}
            autoComplete="off"
          >
            <Form.Item
              label="Tên thư mục"
              name="folderName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên thư mục!",
                },
              ]}
            >
              <Input allowClear={true} autoFocus={true} />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 21,
                // span: 16,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={isAddFolderBtnLoading}
              >
                Tạo
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* modal đổi tên thư mục */}
        <Modal
          visible={isRenameModalVisible}
          title={
            selectedFolder
              ? `Đổi tên thư mục "${selectedFolder.node.title}"`
              : "Đổi tên thư mục"
          }
          footer={null}
          onCancel={() => {
            setIsRenameModalVisible(false);
          }}
        >
          <Form
            ref={renameFolderRef}
            name="rename_folder"
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 18,
            }}
            initialValues={
              {
                // remember: true,
                // actionName: "hành động",
              }
            }
            onFinish={onFinishRenameFolder}
            onFinishFailed={onFinishFailedRenameFolder}
            autoComplete="off"
          >
            <Form.Item
              label="Tên thư mục:"
              name="folderName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên thư mục mới!",
                },
              ]}
            >
              <Input
                allowClear={true}
                autoFocus={true}
                placeholder="Nhập tên thư mục mới"
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 21,
                // span: 16,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={isRenameFolderBtnLoading}
              >
                Đổi
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* modal thêm content */}
        <Modal
          footer={null}
          visible={isAddContentModalVisible}
          title="Tạo mới Content"
          onCancel={() => {
            setIsAddContentModalVisible(false);
          }}
          width="60%"
        >
          <Form
            name="add_content"
            ref={addContentRef}
            initialValues={
              {
                // remember: true,
                // actionName: "hành động",
              }
            }
            onFinish={onFinishAddContent}
            onFinishFailed={onFinishFailedAddContent}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Tên content:"
              name="contentName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên content mới!",
                },
              ]}
            >
              <Input
                allowClear={true}
                placeholder="Nhập tên Content mới"
                autoFocus={true}
              />
            </Form.Item>

            <Form.Item
              label="Nội dung bài đăng"
              // extra="file dạng '.txt'"
              name="postContent"
              rules={[
                {
                  required: true,
                  message: "Vui lòng tải lên nội dung post!",
                },
              ]}
            >
              {/* <Upload {...propsUploadTxt}>
                    <Button htmlType="button" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload> */}
              <Input.TextArea rows={4} placeholder="..."></Input.TextArea>
            </Form.Item>

            <Form.Item
              label="Ảnh trong bài đăng"
              name="postImage"
              extra="Có thể upload nhiều ảnh"
            >
              <Upload {...propsUploadImage}>
                {imageNum >= 5 ? null : (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>

            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={() => setPreviewVisible(false)}
            >
              <img
                alt="example"
                style={{ width: "100%" }}
                src={previewImage}
              />
            </Modal>

            <Form.Item
              wrapperCol={{
                offset: 21,
                // span: 16,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={isAddContentBtnLoading}
              >
                Tạo mới
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* modal detail/edit content */}
        <DetailContentModal
          visible={isDetailContentModalVisible}
          onCancel={() => {
            setIsDetailContentModalVisible(false);
          }}
          data={selectedDetail}
          reload={getAndRenderContent}
        />
      </div>
    </React.Fragment>
  );
};

export default ManageContent;
