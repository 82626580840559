import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Form, Input, Button, Checkbox, message, Menu, Modal, Select, Dropdown, Tooltip, Popover, Tree, Pagination } from 'antd';
import { fetchData } from '../../lib/helpers';
import { Footer, Header, Sidebar, ModalUploadUserFb, ModalExportUserFb, FormSaveFolder, PopupMenu, SpinLoading, FormOptJoinGroup, FormProxy } from '../../component';
import moment from 'moment';
import { FolderOpenOutlined, ExclamationCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;
const { Option } = Select;
const { DirectoryTree } = Tree;

export default class NotifySystem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            listIdChecked: [],
            valuesFilter: {},
            loading: false,
            DataM: null,
        };
        this.limit = 20;
        this.page = 1;
        this.nextpage = true;
    }
    componentWillMount = () => {
        this.getListData();
    }

    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
                this.nextpage = false;
                this.state.listIdChecked = [];
            }
            this.setState({ loading: true });
            let query = `limit=${this.limit}&page=${this.page}`;
            let res = await fetchData({
                url: `api/v1/admin/system/notification?${query}`,
                method: 'get'
            });
            if (this.limit != res.data.length) {
                this.nextpage = false;
            } else {
                this.nextpage = true;
            }
            this.setState({ DataM: res, loading: false });
            if (document.querySelector('[name="checkall"]')) document.querySelector('[name="checkall"]').checked = false;
        } catch (err) {
            this.setState({ loading: false });
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    handleChecked = (e) => {
        if (e.target.name == 'checkall') {
            if (e.target.checked) {
                document.querySelectorAll('[name="check_item"]').forEach((item) => {
                    item.checked = true;
                });
            } else {
                document.querySelectorAll('[name="check_item"]').forEach((item) => item.checked = false)
            }
        } else {
            document.querySelector('[name="checkall"]').checked = false;
        }
    }
    componentWillUnmount = () => {

    }
    getDataChecked = () => {
        let lists = []
        document.querySelectorAll('[name="check_item"]:checked').forEach((item) => {
            lists.push(item.value);
        })
        return lists;
    }
    removeMany = async () => {
        var list_ids = this.getDataChecked();
        console.log(list_ids);
        if (list_ids.length == 0) {
            this.props.notification({
                type: 'warning',
                message: 'Xóa thông báo',
                description: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        let { notification } = this.props;
        let _this = this;
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${list_ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let result = await fetchData({
                            url: 'api/v1/admin/system/notification/delete_many',
                            method: 'post',
                            body: JSON.stringify({ ids: list_ids }),
                            headers: { 'Content-Type': 'application/json' }
                        });
                        notification({
                            type: 'success',
                            message: 'Xóa thông báo',
                            description: `Bạn đã xóa thành công ${result.data.deletedCount} đối tượng!`
                        })
                        _this.getListData();
                        resolve()
                    } catch (err) {
                        notification({
                            type: 'error',
                            message: 'Xóa thông báo',
                            description: err.message || 'Đã cõ lỗi xảy ra'
                        })
                        reject()
                    }
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }
    saveNoti = async (values) => {
        try {
            this.setState({ loading: true });
            await this.props.fetchData({
                url: 'api/v1/admin/system/notification',
                method: 'post',
                body: JSON.stringify(values),
                headers: { 'Content-Type': 'application/json' }
            });

            this.setState({ loading: false }, () => {
                this.getListData();
                this.cancelForm();
                this.props.notification({
                    description: 'Tạo thông báo thành công!',
                    type: 'success',
                    key: 'success',
                    message: 'Tạo thông báo!'
                })
            });

        } catch (err) {
            this.setState({ loading: false }, () => {
                this.props.notification({
                    description: err.message,
                    type: 'error',
                    key: 'error',
                    message: 'Tạo thông báo'
                })
            });
        }
    };
    cancelForm = () => {
        this.form.resetFields();
        this.setState({
            visibleForm: false
        })
    }
    render() {

        return (
            <React.Fragment>
                <div className="row ">
                    <Modal
                        title={`Thêm thông báo`}
                        visible={this.state.visibleForm}
                        onCancel={this.cancelForm}
                        footer={null}
                    >
                        <Form
                            onFinish={this.saveNoti}
                            ref={(evt) => this.form = evt}
                            layout="vertical"
                        >
                            <Form.Item
                                name="title"
                                label="Tiêu đề"
                                rules={[{ required: true, message: 'Bạn phải nhập tiêu đề!' }]}
                            >
                                <Input placeholder="Nhập tiêu đề" />
                            </Form.Item>

                            <Form.Item
                                name="content"
                                label="Nội dung"
                                rules={[{ required: true, message: 'Bạn phải nhập nội dung!' }]}
                            >
                                <Input.TextArea placeholder="Nhập nội dung" style={{ minHeight: "120px" }} />
                            </Form.Item>

                            <div className="text-right">
                                <Button type="primary" loading={this.state.loading} htmlType="submit" >
                                    Xác nhận
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                    <div className="col-md-12 ">
                        <div className="block">
                            <div className="block-content block-content-m">
                                <div className="row mb-20">
                                    <div className="col-md-9">
                                        <button className="btn btn-info btn-sm">Tất cả ({this.state.DataM ? this.state.DataM.total : 0})</button>
                                        <button className="btn btn-danger btn-sm ml-2 btn-remove-nc" onClick={() => this.removeMany()}>
                                            <i className="fa fa-trash-o"></i>
                                        </button>
                                        <button className="btn btn-primary btn-sm ml-2" onClick={() => this.setState({ visibleForm: true })}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="row mb-20 tool-filter">
                                    <div className="col-md-12 text-right">
                                        <Pagination
                                            total={this.state.DataM && this.state.DataM.total ? this.state.DataM.total : 1}
                                            showSizeChanger
                                            pageSize={this.limit}
                                            pageSizeOptions={[20, 50, 100, 200, 500, 1000]}
                                            current={this.page}
                                            onShowSizeChange={(current, size) => {
                                                this.limit = size;
                                            }}
                                            onChange={(current) => {
                                                this.page = current;
                                                this.getListData();
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-md-12 ">
                                        <div className="table-responsive" onScroll={this.handleScroll}>
                                            <SpinLoading visible={this.state.loading} />
                                            <table className="table table-striped table-vcenter">
                                                <thead>
                                                    <tr>
                                                        <th className="checkbox">
                                                            <label class="css-control css-control-primary css-checkbox p-0">
                                                                <input name="checkall" type="checkbox" class="css-control-input checkbox" onChange={this.handleChecked} />
                                                                <span class="css-control-indicator"></span>
                                                            </label>
                                                        </th>
                                                        <th>Stt</th>
                                                        <th>Title</th>
                                                        <th>Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.DataM && this.state.DataM.data.length > 0 ? this.state.DataM.data.map((item, i) => {
                                                            return (<tr key={i + '_' + item._id}
                                                            >
                                                                <td className="checkbox">
                                                                    <label class="css-control css-control-primary css-checkbox p-0">
                                                                        <input name="check_item" type="checkbox" class="css-control-input checkbox" value={item._id}
                                                                            onChange={this.handleChecked}
                                                                        />
                                                                        <span class="css-control-indicator"></span>
                                                                    </label>
                                                                </td>
                                                                <td>
                                                                    {this.page > 1 ? ((this.page - 1) * this.limit) + i + 1 : i + 1}
                                                                </td>
                                                                <td>{item.title}</td>
                                                                <td>{moment(item.time_created).format('LT DD/MM/YYYY')}</td>
                                                            </tr>)
                                                        }) : <tr>
                                                            <td colSpan="11" className="text-center">Không có dữ liệu</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
