import { ArrowLeftOutlined, DeleteOutlined, ExclamationCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { Badge, Button, DatePicker, Form, Input, Modal, notification, Select, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { fetchData } from "../../../lib/helpers";

import { connect } from "react-redux"; 
import QueryString from "qs";
import WidgetExport from "./Widget/export";
import WidgetImport from "./Widget/import";

const DataLog = (props) => {
    const [data, setData] = useState({
        total: 0,
        data: [],
    });
    const [pagination, setpagination] = useState({
        limit: 20,
        page: 1,
    });
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [loading, setloading] = useState(false);
    const [visibleExport, setvisibleExport] = useState(false);
    const [visibleImport, setvisibleImport] = useState(false);
    const [ initialValues, setinitialValues ] = useState(QueryString.parse(props.location.search.replace(/^\?/gi, "")));

    const refFormFilter = useRef();
    const timeoutSearch = useRef();

    const { Platform } = props.Access;

    const listColumn = [...new Array(8)].map((item, index) => `column${index+1}`)

    useEffect(() => {
        if(refFormFilter.current){
            refFormFilter.current.setFieldsValue(initialValues)
        }
    }, [ initialValues, refFormFilter ])

    const getListData = async () => {
        try {
            setloading(true);
            let params = {
                ...pagination
            }
            if (refFormFilter.current) params = { ...params, ...refFormFilter.current.getFieldsValue() }
            let response = await fetchData({
                url: API_URL.userDataLog.index,
                params: params
            });
            setData(response);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const deleteMany = async (ids) => {
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids == -1 ? data.total : ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (ids.length == 0) throw ("Chọn ít nhất 1 đối tượng!");
                        let response = await fetchData({
                            url: API_URL.userDataLog.index,
                            method: "delete",
                            body: JSON.stringify({
                                ids: ids
                            })
                        });
                        getListData();

                        setselectedRowKeys([]);

                        notification.success({
                            message: "Thông báo",
                            description: "Thao tác thành công"
                        });
                    } catch (err) {
                        notification.error({
                            message: "Thông báo",
                            description: err.message || err
                        });
                    } finally {

                    }
                    resolve();
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });

    };

    useEffect(() => {
        getListData();
    }, [pagination]);

    return (
        <React.Fragment>
            <WidgetExport 
                {...props}
                visible={visibleExport}
                hide={() => setvisibleExport(false)}
                listData={data.data}
                listIdChecked={selectedRowKeys}
                listColumn={listColumn}
            />

            <WidgetImport 
                visible={visibleImport}
                hide={() => setvisibleImport(false)}
                onFinish={() => {
                    setvisibleImport(false);
                    getListData();
                }}
            />

            <div className="block">
                <Badge.Ribbon text="Dữ liệu" />
                <div className="block-content block-content-m">
                    <div className="d-flex align-items-center mb-20 ">
                        <h2 className="block-title font-weight-bold">Quản lý dữ liệu</h2>
                    </div>
                    <div className="">
                        <Form
                            ref={refFormFilter}
                            className="row gx-10"
                            onValuesChange={(e) => {
                                if (timeoutSearch.current) clearTimeout(timeoutSearch.current);
                                let timeout = 300;
                                timeoutSearch.current = setTimeout(() => {
                                    getListData();
                                }, timeout);
                            }}
                            onReset={() => {
                                getListData();
                            }}
                            initialValues={initialValues}
                        >
                            <Form.Item name="task_id" className="col-md-2">
                                <Input placeholder="Task id" />
                            </Form.Item>
                            <Form.Item name="action_id" className="col-md-2">
                                <Input placeholder="Action id" />
                            </Form.Item>
                            <Form.Item name="device_id" className="col-md-2">
                                <Input placeholder="Thiết bị" />
                            </Form.Item>
                            {
                                listColumn.map((item) => {
                                    return <Form.Item name={item} className="col-md-2">
                                        <Input placeholder={item} />
                                    </Form.Item>
                                })
                            }
                            <Form.Item name="ranger_last_use" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item className="col-md-4">
                                <DatePicker.RangePicker 
                                    showTime={true}
                                    onChange={(e) => {
                                        refFormFilter.current.setFieldsValue({
                                            ranger_last_use: e && e.length ? `${e[0].unix() * 1000},${e[1].unix() * 1000}` : ""
                                        })
                                        getListData();
                                    }}
                                />
                            </Form.Item>
                            <Form.Item className="col-auto">
                                <Button htmlType="reset">Xóa lọc</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="d-flex">
                        <Button type="default" size="small">
                            Tất cả: {data.total}
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={() => setvisibleImport(true)}
                        >
                            <i className="far fa-upload mr-2"></i> Import
                        </Button>
                        <Button
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={() => setvisibleExport(true)}
                        >
                            <i className="far fa-download mr-2"></i> Export
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={getListData}
                        >
                            <i className="si si-reload mr-2"></i> làm mới
                        </Button>
                        <Tooltip title="Xóa dữ liệu đã chọn">
                            <Button
                                type="primary"
                                className="d-flex align-items-center ml-2"
                                icon={<DeleteOutlined />}
                                onClick={() => deleteMany(selectedRowKeys)}
                                danger
                                size="small"
                            >
                                Xóa
                            </Button>
                        </Tooltip>
                        <Tooltip title="Xóa tất cả">
                            <Button
                                type="primary"
                                className="d-flex align-items-center ml-2"
                                icon={<DeleteOutlined />}
                                onClick={() => deleteMany(-1)}
                                danger
                                size="small"
                            >
                                Xóa tất cả
                            </Button>
                        </Tooltip>
                    </div>
                    <Table
                        dataSource={data.data}
                        columns={[
                            {
                                title: "Device Id",
                                dataIndex: "device_id"
                            },
                            ...listColumn.map((item, index) => {
                                return {
                                    title: `Column ${index+1}`,
                                    dataIndex: item
                                }
                            }),
                            {
                                title: "Thời gian",
                                dataIndex: "last_time_use",
                                align: "right",
                                render: (value, record) => moment(value || record.created_time).format("HH:mm DD/MM/YYYY"),
                            }
                        ]}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys,
                            onChange: (e) => setselectedRowKeys(e)
                        }}
                        rowKey="_id"
                        pagination={{
                            total: data.total,
                            pageSize: pagination.limit,
                            current: pagination.page,
                            showSizeChanger: true,
                            pageSizeOptions: [20, 50, 100, 500, 1000, 2000],
                            onChange: (page, pageSize) => {
                                setpagination({
                                    limit: pageSize,
                                    page: page,
                                });
                            },
                            position: ["topRight"],
                            size: "small"
                        }}
                        loading={loading}
                        scroll={{
                            x: true
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

export default connect(mapStateToProps)(DataLog);
