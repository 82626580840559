import { ROOT_ACTION } from "../../contants";

const INITIAL_STATE = {
    viewDetail: false
}

const TaskActionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ROOT_ACTION.taskAction.viewDetail:
            return {
                ...state,
                viewDetail: action.payload
            };
        default:
            return state;
    }
}

export default TaskActionReducer;