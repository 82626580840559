import React, { Component } from 'react';

import { Form, Input, Button, Checkbox, message, Menu, Modal, Select, Dropdown, Tooltip, Popover, Tree, Switch, Table, Spin } from 'antd';
import { fetchData, formatNumber } from '../lib/helpers';
import { Footer, Header, Sidebar } from '../component';
import moment from 'moment';
import { FolderOpenOutlined, ExclamationCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import qs from 'qs';

export default class ListEmailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            loadingForm: false,
            dataSelected: []
        };
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
    }

    getListData = async (type) => {
        try {
            if (type == 'reset') this.page = 1;

            this.setState({ loading: true });
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) {
                params = {
                    ...params,
                    ...this.formFilter.getFieldsValue()
                }
            }
            let response = await fetchData({
                url: `api/v1/email/list?${qs.stringify(params)}`,
                method: 'get'
            });

            this.setState({ loading: false, listData: response.data, total: response.total })

        } catch (err) {
            this.setState({ loading: false });
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    componentWillUnmount = () => {

    }
    deleteMany = async (ids) => {
        if (ids.length == 0) {
            this.props.notification({
                type: 'warning',
                message: 'Lỗi xóa',
                description: 'Bạn phải chọn ít nhất 1 đối tượng!'
            })
            return;
        }
        let { notification } = this.props;
        let _this = this;
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let result = await fetchData({
                            url: `api/v1/email/delete`,
                            method: 'delete',
                            body: JSON.stringify({
                                ids: ids
                            }),
                            headers: { 'Content-Type': 'application/json' }
                        });
                        notification({
                            type: 'success',
                            message: 'Xóa thành công',
                            description: `Bạn đã xóa thành công ${result.data.deletedCount} đối tượng!`
                        })
                        _this.getListData();
                        resolve()
                    } catch (err) {
                        notification({
                            type: 'error',
                            message: 'Lỗi xóa',
                            description: err.message || 'Đã cõ lỗi xảy ra'
                        })
                        reject()
                    }
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }
    save = async (values) => {
        try {
            this.setState({ loadingForm: true });
            let emails = values['emails'].split('\n').map((item) => {
                item = item.replace(/\s/g, '');
                return {
                    email: item.split('|')[0],
                    password: item.split('|')[1]
                };
            })
            console.log(emails);
            let response = await fetchData({
                url: 'api/v1/email/create',
                method: 'post',
                body: JSON.stringify(emails),
                headers: { 'Content-Type': 'application/json' }
            });

            this.getListData();

            this.setState({ loadingForm: false, visibleForm: false });

            if (this.form) this.form.resetFields();

            this.props.notification({
                type: 'success',
                message: 'Cập nhập email',
                description: 'Thao tác thành công'
            });

        } catch (err) {
            this.setState({ loadingForm: false });
            this.props.notification({
                type: 'error',
                message: 'Cập nhập email',
                description: err.message || 'Đã cõ lỗi xảy ra'
            });
        }
    }
    render() {

        return (
            <React.Fragment>
                <div className="row ">
                    <Modal
                        title="Cập nhập danh sách email"
                        visible={this.state.visibleForm}
                        onCancel={() => this.setState({ visibleForm: false }, () => this.form.resetFields())}
                        footer={null}
                    >
                        <Form
                            ref={(evt) => this.form = evt}
                            onFinish={this.save}
                            layout="vertical"
                        >
                            <Form.Item name="emails">
                                <Input.TextArea placeholder="email | password" style={{ minHeight: "200px" }} />
                            </Form.Item>
                            <div className="d-flex justify-content-end" >
                                <Button type="primary" htmlType="submit" loading={this.state.loadingForm}>Cập nhập</Button>
                            </div>
                        </Form>
                    </Modal>
                    <div className="col-md-12 ">
                        <div className="block">
                            <div className="block-content block-content-m">
                                <div className="row mb-20">
                                    <div className="col-md-9">
                                        <button className="btn btn-info btn-sm">Tất cả ({this.state.total})</button>
                                        <button className="btn btn-primary btn-sm ml-2" onClick={() => this.getListData()}>
                                            <i className="fa fa-refresh"></i>
                                        </button>
                                        <button className="btn btn-primary btn-sm ml-2" onClick={() => this.setState({ visibleForm: true })}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                        <button className="btn btn-danger btn-sm ml-2" onClick={() => this.deleteMany(this.state.dataSelected)}>
                                            <i className="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                                <Form
                                    ref={(evt) => this.formFilter = evt}
                                    initialValues={{
                                        status: "",
                                        is_add_fb: ""
                                    }}
                                    className="row"
                                >
                                    <Form.Item name="email" className="col-md-2">
                                        <Input placeholder="Nhập email.." onChange={() => {
                                            if (this.timeoutfilter) clearTimeout(this.timeoutfilter);
                                            this.timeoutfilter = setTimeout(() => {
                                                this.getListData('reset');
                                            }, 300)
                                        }} />
                                    </Form.Item>
                                    <Form.Item name="status" className="col-md-2">
                                        <Select onChange={() => this.getListData('reset')}>
                                            <Select.Option value="">Trạng thái</Select.Option>
                                            <Select.Option value={1}>Hoạt động</Select.Option>
                                            <Select.Option value={2}>Không hoạt động</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="is_add_fb" className="col-md-2">
                                        <Select onChange={() => this.getListData('reset')}>
                                            <Select.Option value="">Add nick fb</Select.Option>
                                            <Select.Option value={1}>Đã add</Select.Option>
                                            <Select.Option value={0}>Chưa add</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item className="col-md-1">
                                        <Button htmlType="reset" type="primary" onClick={(e) => {
                                            this.formFilter.resetFields();
                                            this.getListData('reset');
                                        }}>Xóa lọc</Button>
                                    </Form.Item>
                                </Form>
                                <div className="row ">
                                    <div className="col-md-12 ">
                                        <Spin spinning={this.state.loading}>
                                            <Table
                                                columns={[
                                                    {
                                                        title: "#",
                                                        key: "#",
                                                        render: (item, value, index) => {
                                                            return this.page == 1 ? index + 1 : (this.page - 1) * this.limit + index + 1;
                                                        }
                                                    },
                                                    {
                                                        title: "Email",
                                                        key: "email",
                                                        dataIndex: "email"
                                                    },
                                                    {
                                                        title: "password",
                                                        key: "password",
                                                        dataIndex: "password"
                                                    },
                                                    {
                                                        title: "status",
                                                        key: "status",
                                                        dataIndex: "status",
                                                        render: (status) => {
                                                            return <span className={`badge ${status == 1 ? "badge-success" : "badge-danger"}`}>
                                                                {status == 1 ? "Hoạt động" : "Không hoạt động"}
                                                            </span>
                                                        }
                                                    },
                                                    {
                                                        title: "Log",
                                                        key: "logs",
                                                        dataIndex: "logs",
                                                        render: (logs) => {
                                                            let text_logs = logs.sort((a, b) => b.time - a.time).map((item) => {
                                                                return new Date(item.time) + ' ---> ' + item.message
                                                            })
                                                            return <Input.TextArea value={text_logs.join('\n')} style={{ minHeight: "120px" }} />
                                                        }
                                                    },
                                                    {
                                                        title: "Updated time",
                                                        key: "updated_time",
                                                        dataIndex: "updated_time",
                                                        render: (updated_time) => updated_time ? moment(updated_time).format('HH:mm DD/MM/YYYY') : ""
                                                    },
                                                    {
                                                        title: "Action",
                                                        key: "action",
                                                        dataIndex: "_id",
                                                        className: "text-right",
                                                        render: (id) => {
                                                            return <div>
                                                                <Tooltip title="Xóa">
                                                                    <a href="#" className="btn btn-danger ml-5 p-5" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.deleteMany([id])
                                                                    }}>
                                                                        <i class="far fa-trash"></i>
                                                                    </a>
                                                                </Tooltip>
                                                            </div>
                                                        }
                                                    }
                                                ]}
                                                dataSource={this.state.listData}
                                                pagination={{
                                                    total: this.state.total,
                                                    page: this.page,
                                                    pageSize: this.limit,
                                                    onChange: (current) => {
                                                        this.page = current;
                                                        this.getListData();
                                                    },
                                                    onShowSizeChange: (current, size) => {
                                                        this.page = current;
                                                        this.limit = size;
                                                        this.getListData();
                                                    }
                                                }}
                                                rowKey={item => item._id}
                                                rowSelection={{
                                                    type: "checkbox",
                                                    selectedRowKeys: this.state.dataSelected,
                                                    onChange: (values) => {
                                                        this.setState({
                                                            dataSelected: values
                                                        })
                                                    }
                                                }}
                                            />
                                        </Spin>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
