import { all } from "redux-saga/effects";
import Access from "./containers/access/saga";

export function* rootSaga(){
    yield all([
        Access()
    ])
}

export default rootSaga;
