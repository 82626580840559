import React, { Component } from 'react';

export default class Footer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <footer id="page-footer" className="opacity-0">
                <div className="content py-20 font-size-sm clearfix">
                    <div className="float-right">
                        Crafted with <i className="fa fa-heart text-pulse"></i> by <a className="font-w600" href="https://1.envato.market/ydb" target="_blank">pixelcave</a>
                    </div>
                    <div className="float-left">
                        <a className="font-w600" href="https://1.envato.market/95j" target="_blank">Codebase</a> &copy; <span className="js-year-copy"></span>
                    </div>
                </div>
            </footer>
        )
    }
}
