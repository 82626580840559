import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Button, Form, Input, Modal, Select, TreeSelect, InputNumber, Checkbox, Row,
    Col, Upload, Tooltip, Switch, Badge, Radio, message
} from "antd";

const WidgetDetailScript = (props) => {
    const { visible, hide, item} = props;

    return <Modal
        title={"Chi tiết script"}
        visible={visible}
        onCancel={hide}
        footer={null}
        width={600}
    >
        <Input.TextArea 
            value={item ? item.script : null}
            rows={16}
        />
    </Modal>
}

export default WidgetDetailScript