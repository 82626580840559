import { Table, Tooltip, Button, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { useVT } from 'virtualizedtableforantd4';


const App = (props) => {
  const [vt, set_components] = useVT(() => ({ scroll: { y: props.scroll && props.scroll.y } }), []);

  return (
    <Table
      {...props}
      scroll={{ y: props.scroll && props.scroll.y }}
      components={vt}
    />
  )
};

export default App;