import { DeleteOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, notification, Select, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { copyText } from "../../../lib/app";
import { fetchData } from "../../../lib/helpers";
import { Config, ConfigListAction } from "../../../utils/config";
import WidgetFormData from "./Widget/formData";
import WidgetFormPlatform from "./Widget/formPlatform";

import { connect } from "react-redux";

const ActionPlatform = (props) => {
    const [data, setData] = useState([]);
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [loading, setloading] = useState(false);
    const [visibleForm, setVisibleForm] = useState({ visible: false });
    const [loadingSave, setloadingSave] = useState(false);
    const [visibleFormPlatform, setvisibleFormPlatform] = useState(false);

    const refFormFilter = useRef();
    const timeoutSearch = useRef();

    const { Platform } = props.Access;

    const getListData = async () => {
        try {
            setloading(true);
            let params = {};

            if (refFormFilter.current) params = { ...params, ...refFormFilter.current.getFieldsValue() }

            let response = await fetchData({
                url: API_URL.admin.platform.action.index,
                params: params
            });
            setData(response.data);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const deleteMany = async (ids) => {
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (ids.length == 0) throw ("Chọn ít nhất 1 đối tượng!");
                        let response = await fetchData({
                            url: API_URL.admin.platform.action.index,
                            method: "delete",
                            body: JSON.stringify({
                                ids: ids
                            })
                        });
                        getListData();

                        setselectedRowKeys([]);

                        notification.success({
                            message: "Thông báo",
                            description: "Thao tác thành công"
                        });
                    } catch (err) {
                        notification.error({
                            message: "Thông báo",
                            description: err.message || err
                        });
                    } finally {

                    }
                    resolve();
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });

    };

    const saveActionPlatformOld = async () => {
        try {
            setloadingSave(true);
            let response = await fetchData({
                url: API_URL.admin.platform.action.index,
                method: "post",
                body: JSON.stringify({
                    data: ConfigListAction
                })
            });
            getListData();
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloadingSave(false);
        }
    }

    useEffect(() => {
        getListData();
    }, []);

    return (
        <React.Fragment>
            <WidgetFormData 
                {...visibleForm}
                hide={() => setVisibleForm({ visible: false })}
                platform={Platform.data}
                onFinish={() => {
                    getListData();
                }}
            />

            <WidgetFormPlatform 
                visible={visibleFormPlatform}
                hide={() => setvisibleFormPlatform(false)}
            />

            <div className="block">
                <div className="block-content block-content-m">
                    <h3 className="block-title">Danh sách form hành động</h3>
                    <div className="mt-20 mb-20 d-flex">
                        <Button
                            type="primary"
                            className="d-flex align-items-center mr-4"
                            icon={<PlusOutlined />}
                            onClick={() => setVisibleForm({ visible: true })}
                        >
                            Thêm mới
                        </Button>
                        <Button
                            className="d-flex align-items-center mr-4"
                            onClick={() => setvisibleFormPlatform(true)}
                            type="primary"
                        >
                            Quản lý platform
                        </Button>
                        {
                            !data.length && <Button
                                className="d-flex align-items-center mr-4"
                                onClick={saveActionPlatformOld}
                                loading={loadingSave}
                            >
                                Cập nhập config default
                            </Button>
                        }
                        <Button
                            type="danger"
                            className="d-flex align-items-center"
                            icon={<DeleteOutlined />}
                            onClick={() => deleteMany(selectedRowKeys)}
                        >
                            Xóa
                        </Button>
                    </div>
                    <div>
                        <Form
                            ref={refFormFilter}
                            className="row gx-10"
                            onValuesChange={(e) => {
                                if (timeoutSearch.current) clearTimeout(timeoutSearch.current);
                                let timeout = e.key ? 300 : 0;
                                timeoutSearch.current = setTimeout(() => {
                                    getListData();
                                }, timeout);
                            }}
                            onReset={getListData}
                        >
                            <Form.Item name="key" className="col-md-2">
                                <Input placeholder="Key action.." />
                            </Form.Item>
                            <Form.Item name="platform" className="col-md-2" >
                                <Select placeholder="Platform">
                                    {
                                        Platform.data.map((item, i) => {
                                            return <Select.Option key={i} value={item}>{item}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            {/* <Form.Item name="status" className="col-md-2" >
                                <Select placeholder="Trạng thái">
                                    {
                                        Object.keys(Config.statusFormAction).map((key, i) => {
                                            return <Select.Option key={i} value={key}>{Config.statusFormAction[key].label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item> */}
                            <Form.Item className="col-auto">
                                <Button htmlType="reset">Xóa lọc</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="mb-0 d-flex">
                        <Button type="default" size="small">
                            Tất cả: {data.length}
                        </Button>
                        <Button type="default" size="small" className="ml-2">
                            Đã chọn: {selectedRowKeys.length}
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={getListData}
                        >
                            <i className="si si-reload mr-2"></i> làm mới
                        </Button>
                    </div>
                    <Table
                        dataSource={data}
                        columns={[
                            {
                                title: "Key",
                                dataIndex: "key"
                            },
                            {
                                title: "Tên",
                                dataIndex: "value",
                            },
                            {
                                title: "Platform",
                                dataIndex: "platform",
                            },
                            {
                                title: "Fields",
                                dataIndex: "fields",
                                render: (item) => item && item.length
                            },
                            {
                                title: "Updated time",
                                dataIndex: "updated_time",
                                render: (item) => item ? moment(item).format("HH:mm DD/MM/YYYY") : null
                            },
                            {
                                title: "Loại",
                                dataIndex: "type"
                            },
                            // {
                            //     title: "Trạng thái",
                            //     dataIndex: "status",
                            //     render: (item) => Config.statusFormAction[item].label
                            // },
                            {
                                title: "Thao tác",
                                align: "right",
                                render: (value, record) => {
                                    return <React.Fragment>
                                        <Tooltip title="Copy fields">
                                            <Button type="primary" size="small"
                                                onClick={() => copyText(JSON.stringify(record.fields, null, 2))}
                                                className="mb-2"
                                            >
                                                <i className="far fa-copy"></i>
                                            </Button>
                                        </Tooltip>
                                        <br></br>
                                        <Button type="primary" size="small"
                                            onClick={() => setVisibleForm({ visible: true, item: record })}
                                            className="mb-2"
                                        >
                                            <i className="fa fa-edit"></i>
                                        </Button>
                                        <br></br>
                                        <Button type="primary" size="small" danger
                                            onClick={() => deleteMany([record._id])}
                                        >
                                            <i className="far fa-trash"></i>
                                        </Button>
                                    </React.Fragment>
                                }
                            }
                        ]}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys,
                            onChange: (e) => setselectedRowKeys(e),
                        }}
                        pagination={{
                            pageSize: 20,
                            position: ["topRight"],
                            size: "small"
                        }}
                        rowKey="_id"
                        loading={loading}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

export default connect(mapStateToProps)(ActionPlatform);
