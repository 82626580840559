import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Form, Input, Button, Checkbox, message, Menu, Modal, Select, Dropdown, Tooltip, Popover, Tree, Pagination, InputNumber } from 'antd';
import { fetchData } from '../lib/helpers';
import { Footer, Header, Sidebar, ModalUploadUserFb, ModalExportUserFb, FormSaveFolder, PopupMenu, SpinLoading, FormOptJoinGroup } from '../component';
import moment from 'moment';
import { FolderOpenOutlined, ExclamationCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import qs from "qs";

const { SubMenu } = Menu;
const { Option } = Select;
const { DirectoryTree } = Tree;

export default class LogGetCookie extends Component {
    constructor(props) {
        super(props);
        this.rootSubmenuKeys = [];
        this.state = {
            openKeys: [],
            currentProfile: 'all_slt_app',
            listProfile: [],
            listData: [],
            loading: false,
            deviceGroups: [],
            listIdChecked: [],
            autoResetWifi: localStorage.getItem('auto_reset_wifi'),
            num_profile_reset: localStorage.getItem('num_profile_reset') || 3,
            time_out_profile: localStorage.getItem('time_out_profile') || 5,
            auto_reset_timeout: localStorage.getItem('auto_reset_timeout'),
            max_reset_wifi: localStorage.getItem('max_reset_wifi') || 1,
            auto_reset_proxy: localStorage.getItem('auto_reset_proxy'),
        };
        this.timeOutFilter = null;
        this.realtime_getprofile = null
        this.last_device_reset = {};
        this.num_device_reset = {};
    }
    componentWillMount = () => {
        this.getListData();
        this.realtime_getprofile = setInterval(() => {
            this.getListData();
        }, 5000);
        var _this = this;
        window.addEventListener("click", function () {
            if (_this.state.popup && _this.state.popup['visible']) {
                _this.setState({
                    popup: {}
                })
            }
        }, false);
    }

    getListData = async (type) => {
        try {
            let params = {
                time_out_profile: this.state.time_out_profile
            }

            if (this.state.currentProfile != "all_slt_app") {
                params['device_group'] = this.state.currentProfile;
            }

            if (this.formFilter) params = {
                ...params,
                ...this.formFilter.getFieldsValue()
            }

            let res = await fetchData({
                url: `api/v2/app/log_get_cookie?${qs.stringify(params)}`,
                method: 'get'
            });
            this.setState({ listData: res.data, deviceGroups: res.device_groups }, () => {
                // if(res.device_groups.profile_stop.length > 1){
                //     console.log('reset');
                // }

                // reset wifi
                if (window.socket && this.state.autoResetWifi == 1) {
                    let device_stops = res.device_groups.filter((item) => item.profile_stop.length >= this.state.num_profile_reset);
                    console.log('start reset', device_stops, this.last_device_reset);
                    device_stops.forEach((item) => {
                        if (!this.last_device_reset[item.device_group] || (Date.now() - this.last_device_reset[item.device_group]) >= 60 * 1000 * 2) {
                            this.last_device_reset[item.device_group] = Date.now();
                            let profile_id_stop = item.profile_stop.map((item) => { return item.profile_id });
                            console.log(`${item.device_group} reset wifi`);
                            window.socket.emit('reset_wifi', { device_group: item.device_group, profile_reset: item.profile_stop[0], profile_ids: profile_id_stop });
                        }
                    })
                }

                // reset proxy
                if (window.socket && this.state.auto_reset_proxy == 1) {
                    this.state.listData.filter((item) => item.is_stop_checkpoint == 1).forEach((item) => {
                        if (!this.last_device_reset[item.profile_id] || (Date.now() - this.last_device_reset[item.profile_id]) >= 60 * 1000 * 2) {
                            this.last_device_reset[item.profile_id] = Date.now();
                            console.log('reset proxy', item)
                            window.socket.emit('reset_proxy', { socket_id: item.socket_id, profile_id: item.profile_id });
                        }
                    })
                }

                if (window.socket && this.state.auto_reset_timeout == 1) {
                    let profile_ids = res.data.filter((item) => item.last_time < (Date.now() - 60 * 1000 * (parseInt(this.state.time_out_profile || 5))) && item.is_stop_checkpoint != 1).map((item) => item.profile_id);
                    if (profile_ids.length > 0) {
                        console.log(profile_ids);
                        this.resetGet(profile_ids);
                    }
                }
            });
        } catch (err) {
            console.log(err);
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    getListProfile = async () => {
        try {
            let res = await fetchData({
                url: `api/v2/app/profile/list`,
            });
            this.setState({ listProfile: res.data }, async () => {
                await this.getListData();
            });
        } catch (err) {
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    componentWillUnmount = () => {
        if (this.realtime_getprofile) clearInterval(this.realtime_getprofile);
    }
    handleChecked = (e) => {
        if (e.target.name == 'checkall') {
            if (e.target.checked) {
                let listIdChecked = [];
                document.querySelectorAll('[name="check_item"]').forEach((item) => {
                    item.checked = true;
                    listIdChecked.push(item.value);
                });
                this.setState({ listIdChecked: listIdChecked });
            } else {
                document.querySelectorAll('[name="check_item"]').forEach((item) => item.checked = false)
                this.setState({ listIdChecked: [] });
            }
        } else {
            document.querySelector('[name="checkall"]').checked = false;
            let listIdChecked = this.state.listIdChecked;
            if (e.target.checked) {
                listIdChecked.push(e.target.value);
            } else {
                listIdChecked.splice(listIdChecked.indexOf(e.target.value), 1);
            }
            this.setState({ listIdChecked });
        }
    }
    removeMany = async (values) => {
        let { notification } = this.props;
        let _this = this;
        let list_ids = this.state.listIdChecked;
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${list_ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let result = await fetchData({
                            url: 'api/v2/app/log_get_cookie',
                            method: 'delete',
                            body: JSON.stringify({ profile_ids: list_ids }),
                            headers: { 'Content-Type': 'application/json' }
                        });
                        notification({
                            type: 'success',
                            message: 'Xóa log',
                            description: `Bạn đã xóa thành công ${result.data.deletedCount} đối tượng!`
                        })
                        _this.setState({ listIdChecked: [] });
                        _this.getListData();
                        resolve()
                    } catch (err) {
                        notification({
                            type: 'error',
                            message: 'Xóa log',
                            description: err.message || 'Đã cõ lỗi xảy ra'
                        })
                        reject()
                    }
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });
    }
    resetGet = async (ids = []) => {
        try {
            if (ids.length == 0) throw ({ message: "Chọn ít nhất 1 profile" });

            window.socket.emit('reset_get_cookie', {
                profile_ids: this.state.listData.filter((item) => ids.indexOf(item.profile_id) > -1).map((item) => {
                    return item.socket_id;
                })
            });
        } catch (err) {
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }

    stopGet = async (ids = []) => {
        try {
            if (ids.length == 0) throw ({ message: "Chọn ít nhất 1 profile" });

            window.socket.emit('stop_get_cookie', {
                profile_ids: this.state.listData.filter((item) => ids.indexOf(item.profile_id) > -1).map((item) => {
                    return item.socket_id;
                })
            });
        } catch (err) {
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row ">
                    <Modal
                        title="Thiết lập get cookie"
                        visible={this.state.visibleSetting}
                        onCancel={() => {
                            this.setState({
                                visibleSetting: false
                            }, () => {
                                this.formSetting.resetFields();
                            })
                        }}
                        footer={null}
                    >
                        <Form
                            initialValues={{
                                num_profile_reset: this.state.num_profile_reset,
                                auto_reset: this.state.autoResetWifi == 1 ? true : false,
                                time_out_profile: this.state.time_out_profile,
                                auto_reset_timeout: this.state.auto_reset_timeout == 1 ? true : false,
                                auto_reset_proxy: this.state.auto_reset_proxy == 1 ? true : false
                            }}
                            ref={(evt) => this.formSetting = evt}
                            layout="vertical"
                        >
                            <Form.Item name="num_profile_reset" label="Max profile -> dừng (reset wifi)">
                                <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item name="time_out_profile" label="Time warning profile dừng">
                                <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item name="auto_reset" valuePropName="checked">
                                <Checkbox >Auto reset wifi</Checkbox>
                            </Form.Item>
                            <Form.Item name="auto_reset_timeout" valuePropName="checked">
                                <Checkbox >Auto reset</Checkbox>
                            </Form.Item>
                            <Form.Item name="auto_reset_proxy" valuePropName="checked">
                                <Checkbox >Auto reset proxy</Checkbox>
                            </Form.Item>
                            <Button type="primary" onClick={() => {
                                this.setState({
                                    autoResetWifi: this.formSetting.getFieldValue('auto_reset'),
                                    num_profile_reset: this.formSetting.getFieldValue('num_profile_reset') || 3,
                                    time_out_profile: this.formSetting.getFieldValue('time_out_profile') || 5,
                                    auto_reset_timeout: this.formSetting.getFieldValue('auto_reset_timeout'),
                                    auto_reset_proxy: this.formSetting.getFieldValue('auto_reset_proxy')
                                }, () => {
                                    localStorage.setItem('auto_reset_wifi', this.formSetting.getFieldValue('auto_reset') ? 1 : 0);
                                    localStorage.setItem('num_profile_reset', this.formSetting.getFieldValue('num_profile_reset'));
                                    localStorage.setItem('time_out_profile', this.formSetting.getFieldValue('time_out_profile'));
                                    localStorage.setItem('auto_reset_timeout', this.formSetting.getFieldValue('auto_reset_timeout') ? 1 : 0);
                                    localStorage.setItem('auto_reset_proxy', this.formSetting.getFieldValue('auto_reset_proxy') ? 1 : 0);
                                    this.props.notification({
                                        type: 'success',
                                        description: 'Thao tác thành công!',
                                    })
                                })
                            }}>Lưu thiết lập</Button>
                        </Form>
                    </Modal>

                    <div className="col-md-3 ">
                        <div className="block">
                            <div className="block-content block-content-m">
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <strong>Quản lý app</strong>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-md-12 p-0">
                                        <Menu
                                            mode="inline"
                                            onSelect={(e) => this.setState({ currentProfile: e.key, listIdChecked: [] }, () => {
                                                this.getListData();
                                                document.querySelectorAll('[name="check_item"]').forEach((item) => {
                                                    item.checked = false;
                                                });
                                            })}
                                            selectedKeys={[this.state.currentProfile]}
                                        >
                                            <Menu.Item key={"all_slt_app"}>Tất cả</Menu.Item>
                                            {
                                                this.state.deviceGroups.map((item, i) => {
                                                    return <Menu.Item key={item.device_group} style={{ height: "auto" }}>
                                                        <strong className="m-0">{item.device_group}</strong>
                                                    </Menu.Item>
                                                })
                                            }
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 ">
                        <div className="block">
                            <div className="block-content block-content-m">
                                <div className="row mb-20">
                                    <div className="col-md-9">
                                        <button className="btn btn-info btn-sm">Tất cả ({this.state.listData.length})</button>
                                        <button className="btn btn-danger btn-sm ml-2 btn-remove-nc" onClick={this.removeMany}>
                                            <i className="fa fa-trash-o"></i>
                                        </button>
                                        <Tooltip title="Thiết lập">
                                            <button className="btn btn-primary btn-sm ml-2" onClick={() => this.setState({ visibleSetting: true })}>
                                                <i className="fa fa-cog"></i>
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Reset get">
                                            <button className="btn btn-primary btn-sm ml-2" onClick={() => this.resetGet(this.state.listIdChecked)}>
                                                <i className="fa fa-sync"></i>
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Stop get">
                                            <button className="btn btn-primary btn-sm ml-2" onClick={() => this.stopGet(this.state.listIdChecked)}>
                                                <i className="fa fa-pause"></i>
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                                <Form
                                    ref={(evt) => this.formFilter = evt}
                                    initialValues={{
                                        status: ""
                                    }}
                                    className="row"
                                >
                                    <Form.Item name="status" className="col-md-2">
                                        <Select onChange={() => this.getListData()}>
                                            <Select.Option value="">Trạng thái</Select.Option>
                                            <Select.Option value={1}>Checkpoint</Select.Option>
                                            <Select.Option value={2}>Timeout</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item className="col-md-1">
                                        <Button htmlType="reset" type="primary" onClick={(e) => {
                                            this.formFilter.resetFields();
                                            this.getListData();
                                        }}>Xóa lọc</Button>
                                    </Form.Item>
                                </Form>
                                <div className="row ">
                                    <div className="col-md-12 ">
                                        <div className="table-responsive" onScroll={this.handleScroll}>
                                            <SpinLoading visible={this.state.loading} />
                                            <table className="table table-striped table-vcenter">
                                                <thead>
                                                    <tr>
                                                        <th className="checkbox">
                                                            <label class="css-control css-control-primary css-checkbox p-0">
                                                                <input name="checkall" type="checkbox" class="css-control-input checkbox" onChange={this.handleChecked}
                                                                    checked={this.state.listIdChecked.length == this.state.listData.length && this.state.listIdChecked.length > 0 ? 1 : 0}
                                                                />
                                                                <span class="css-control-indicator"></span>
                                                            </label>
                                                        </th>
                                                        <th>#</th>
                                                        <th>Group</th>
                                                        <th>Profile</th>
                                                        <th>Cần check</th>
                                                        <th>Hoạt động</th>
                                                        <th>Checkpoint</th>
                                                        <th>Tỷ lệ</th>
                                                        <th>Vô hiệu hóa</th>
                                                        <th>Lỗi đăng nhập</th>
                                                        <th>Lỗi không xác định</th>
                                                        <th>Last time</th>
                                                        <th className="text-right">Connect</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listData.length > 0 ? this.state.listData.map((item, i) => {
                                                            item.data = item.data ? item.data : {};
                                                            return (<tr key={i + '_' + item._id}
                                                                className={`${item.is_stop_checkpoint == 1 ? 'bg-danger text-white' : item.last_time < (Date.now() - 60 * 1000 * (parseInt(this.state.time_out_profile || 5))) ? 'bg-warning' : ''}`}
                                                            >
                                                                <td className="checkbox">
                                                                    <label class="css-control css-control-primary css-checkbox p-0">
                                                                        <input name="check_item" type="checkbox" class="css-control-input checkbox" value={item.profile_id}
                                                                            onChange={this.handleChecked}
                                                                        />
                                                                        <span class="css-control-indicator"></span>
                                                                    </label>
                                                                </td>
                                                                <td>
                                                                    {i + 1}
                                                                </td>
                                                                <td>{item.device_group}</td>
                                                                <td>
                                                                    <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "150px" }}>
                                                                        {item.profile_name}
                                                                    </div>
                                                                </td>
                                                                <td>{item.data.run}</td>
                                                                <td className="text-success">{item.data.success}</td>
                                                                <td>{item.data.checkpoint}</td>
                                                                <td>{item.data.checkpoint && (item.data.success / item.data.checkpoint).toFixed(1)}</td>
                                                                <td>{item.data.die}</td>
                                                                <td>{item.data.error}</td>
                                                                <td>{item.data.other}</td>
                                                                <td>{moment(item.last_time).format('LT DD/MM/YYYY')}</td>
                                                                <td className="text-right">{item.is_connnect == 1 ? 'Yes' : 'No'}</td>
                                                            </tr>)
                                                        }) : <tr>
                                                            <td colSpan="11" className="text-center">Không có dữ liệu</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
