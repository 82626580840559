import { ExclamationCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Input, Modal, notification, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PopupMenu } from "../../component";
import { API_URL } from "../../lib/api";
import { getPosContextmenu } from "../../lib/app";
import { fetchData } from "../../lib/helpers";
import { Config } from "../../utils/config";
import WidgetFormUpdate from "./Widgets/form"
import WidgetFormAdd from "./Widgets/formAdd"
import WidgetFormUpdateLicense from "./Widgets/formLicense"
import dayjs from "dayjs"

const RefUserPage = (props) => {
    const location = useLocation()

    const [data, setData] = useState({
        total: 0,
        data: [],
    });
    const [pagination, setpagination] = useState({
        limit: 20,
        page: 1,
    });
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [loading, setloading] = useState(false);
    const [visibleForm, setvisibleForm] = useState();
    const [visibleFormLicense, setvisibleFormLicense] = useState();
    const [visibleFormAdd, setvisibleFormAdd] = useState();

    const getListData = async () => {
        try {
            setloading(true);
            let params = {
                ...pagination
            }
            let response = await fetchData({
                url: API_URL.Ref.index,
                params: params
            });
            setData(response);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message || err
            });
        } finally {
            setloading(false);
        }
    };
    useEffect(() => {
        getListData();
    }, [ pagination ]);

    return (
        <React.Fragment>
            <WidgetFormAdd
                visible={visibleFormAdd ? true : false}
                item={visibleFormAdd}
                onFinish={() => {
                    setvisibleFormAdd(null);
                    getListData()
                }}
                onCancel={() => setvisibleFormAdd(null)}
            />

            <WidgetFormUpdate 
                visible={visibleForm ? true : false}
                item={visibleForm}
                onFinish={() => {
                    setvisibleForm(null);
                    getListData()
                }}
                onCancel={() => setvisibleForm(null)}
            />

            <WidgetFormUpdateLicense
                visible={visibleFormLicense ? true : false}
                item={visibleFormLicense}
                onFinish={() => {
                    setvisibleFormLicense(null);
                    getListData()
                }}
                onCancel={() => setvisibleFormLicense(null)}
            />

                
            <div className="block" style={{ minHeight: "800px", maxHeight: "100%" }}>
                <Badge.Ribbon text={"CTV"} />
                <div className="block-content block-content-m">
                    <h3 className="block-title mb-20">Người dùng giới thiệu</h3>
                    <div>
                        {/* <div className="mb-20">
                            Link giới thiệu: 
                            <Button className="ml-2" type="primary" size="small"
                                onClick={() => {
                                    props.copyText(window.location.protocol+"//"+window.location.host+"?ref="+props.userSystem._id)
                                }}
                            >
                                {window.location.protocol+"//"+window.location.host+"?ref="+props.userSystem._id}
                            </Button>
                        </div> */}
                        <div className="mb-20 d-flex">
                            <Button type="default" size="small">
                                Tất cả: {data.total}
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                className="ml-2 d-flex align-items-center"
                                onClick={() => setvisibleFormAdd(true)}
                            >
                                Thêm mới
                            </Button>
                            <Button
                                type="default"
                                size="small"
                                className="ml-2 d-flex align-items-center"
                                onClick={getListData}
                            >
                                <i className="si si-reload mr-2"></i>
                            </Button>
                        </div>
                        <Table
                            dataSource={data.data}
                            columns={[
                                {
                                    title: "#",
                                    key: "index",
                                    render: (value, item, index) => {
                                        if (pagination.page == 1) {
                                            return index + 1;
                                        } else {
                                            return (pagination.page - 1) * pagination.limit + 1
                                        }
                                    }
                                },
                                {
                                    title: "Full name",
                                    key: "full_name",
                                    dataIndex: "full_name",
                                    render: (item, record) => {
                                        return <>
                                            <p className='mb-1'>{item}</p>
                                            <p className='mb-1'>{record.user_name}</p>
                                            <p className='mn-0'><Tag>{Config.levelUser[record.level]}</Tag></p>
                                        </>
                                    }
                                },
                                {
                                    title: "Status",
                                    key: "status",
                                    dataIndex: "status",
                                    render: (item) => Config.statusUser[item] && <Tag color={Config.statusUser[item].color}>{Config.statusUser[item].label}</Tag>
                                },
                                {
                                    title: "Platform",
                                    dataIndex: "role",
                                    render: (item) => {
                                        return item && item.platforms?.map((item) => {
                                            return <>
                                                <p className='mb-0'>{typeof item == "object" ?
                                                    <>
                                                        <Tooltip
                                                            title={`License: ${moment(item.expired_time).format("hh:mm DD/MM/YYYY")}`}
                                                        >
                                                            <span className={item.expired_time - moment().unix() * 1000 > 0 ? "text-success" : "text-danger"}>{item.type}</span>
                                                        </Tooltip>
                                                    </>
                                                    : item}</p>
                                            </>
                                        })
                                    }
                                },
                                {
                                    title: "Last time",
                                    key: "last_time",
                                    dataIndex: "last_time",
                                    render: (item) => item ? moment(item).format('HH:mm DD/MM/YYYY') : ""
                                },
                                {
                                    title: "Created time",
                                    key: "created_time",
                                    align: "right",
                                    dataIndex: "created_time",
                                    render: (created_time) => created_time ? moment(created_time).format('HH:mm DD/MM/YYYY') : ""
                                },
                                {
                                    title: "Action",
                                    key: "action",
                                    className: "text-right",
                                    render: (item) => {
                                        return <div>
                                            <Tooltip title="Chỉnh sửa">
                                                <a href="#" className="btn btn-primary ml-5 p-5" onClick={(e) => {
                                                    e.preventDefault();
                                                    setvisibleForm(item);
                                                }}>
                                                    <i class="far fa-edit"></i>
                                                </a>
                                            </Tooltip>
                                            <Tooltip title="Kích hoạt license">
                                                <a href="#" className="btn btn-danger ml-5 p-5" onClick={(e) => {
                                                    e.preventDefault();
                                                    setvisibleFormLicense({
                                                        ...item,
                                                        platforms: item.role?.platforms?.filter((item) => typeof item == "object").map((item) => {
                                                            return {
                                                                ...item,
                                                                expired_date: dayjs(item.expired_time)
                                                            }
                                                        }),
                                                        max_app: item.role?.max_app
                                                    })
                                                }}>
                                                    <i class="far fa-user"></i>
                                                </a>
                                            </Tooltip>
                                        </div>
                                    }
                                }
                            ]}
                            rowSelection={{
                                selectedRowKeys: selectedRowKeys,
                                onChange: (e) => setselectedRowKeys(e),
                            }}
                            pagination={{
                                total: data.total,
                                pageSize: pagination.limit,
                                current: pagination.page,
                                showSizeChanger: true,
                                pageSizeOptions: [20, 50, 100, 500],
                                onChange: (page, pageSize) => {
                                    setpagination({
                                        limit: pageSize,
                                        page: page,
                                    });
                                },
                            }}
                            rowKey="_id"
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default RefUserPage;
