import { Modal, Form, Button, Input, Select, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { fetchData } from "../../../lib/helpers";
import { connect } from "react-redux";

const FormScript = (props) => {
    const { routerTypeAdmin } = props;
    const { Platform } = props.Access

    const [loading, setloading] = useState(false);

    const form = useRef();

    const onFinish = async (values) => {
        try {
            setloading(true);

            let index_router = routerTypeAdmin
                ? API_URL.admin.scriptContent.index
                : API_URL.scriptContent.index;

            let options = {
                url: index_router,
                method: "post",
                body: JSON.stringify({
                    ...values,
                }),
            };

            if (values._id) {
                options["method"] = "put";
                options["url"] = index_router + "/" + values._id;
            }

            await fetchData(options);

            props.onFinish();
            notification.success({
                message: "Thông báo",
                description: "Cập nhập thành công",
            });
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message || err,
            });
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        if (!props.visible && form.current) {
            form.current.resetFields();
        }
        if (props.visible && form.current && props.item) {
            form.current.setFieldsValue(props.item);
        }
    }, [props.visible]);
    return (
        <Modal
            title="Cập nhập script"
            visible={props.visible}
            onCancel={props.onCancel}
            footer={null}
        >
            <Form onFinish={onFinish} ref={form} layout="vertical">
                <Form.Item noStyle name="_id">
                    <Input type={"hidden"} />
                </Form.Item>
                <Form.Item
                    label="Tiêu đề"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống",
                        },
                    ]}
                >
                    <Input placeholder="Nhập tiêu đề.." />
                </Form.Item>
                <Form.Item label="Platform" name="platform">
                    <Select>
                        {
                            Platform.data.map((item) => {
                                return <Select.Option value={item}>{item}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Nội dung script"
                    name="content"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống",
                        },
                    ]}
                >
                    <Input.TextArea
                        style={{ minHeight: "160px" }}
                        placeholder="Nhập nội dung.."
                    />
                </Form.Item>
                <Form.Item className="text-right mb-0">
                    <Button htmlType="submit" type="primary" loading={loading}>
                        Cập nhập
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access,
});

export default connect(mapStateToProps)(FormScript);
