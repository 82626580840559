import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { fetchData } from '../lib/helpers';
import { AccountMetaData, setCookie } from '../lib/account';
import bcryptjs from "bcryptjs"

export default class LoginView extends Component {
    constructor(props) {
        super(props);
        let auth_login = localStorage.getItem('auth_login');
        try {
            auth_login = auth_login ? JSON.parse(atob(auth_login)) : null;
        } catch (err) {
            auth_login = null;
        }
        this.state = {
            loading: false,
            auth_login: auth_login,
            dataForm: {},
            is_policy: false
        }
    }
    onFinish = async (e) => {
        try {
            this.setState({ loading: true})
            e.preventDefault();
            if(!this.state.is_policy) throw({ message: "Bạn phải đồng ý với điều khoản dịch vụ của chúng tôi!"})
            let values = this.state.dataForm;
            let resutl = await fetchData({
                url: 'api/v1/login',
                method: 'post',
                body: JSON.stringify({
                    ...values,
                    password: btoa(values['password'])
                }),
                headers: { 'Content-Type': 'application/json' }
            });
            this.setState({ loading: false }, () => {
                localStorage.setItem('auth_login', btoa(JSON.stringify(values)));
                setCookie(AccountMetaData.token_name, btoa(resutl['data']['token']), 2);

                if (this.props.setInfo){
                    if(window.location.pathname.includes("login")){
                        window.location.href = "/";
                    } else{
                        this.props.setInfo(resutl['data']['user']);
                    }
                }
            });

        } catch (err) {
            this.setState({ loading: false }, () => {
                message.warning({
                    content: err.message ? err.message : 'Đã có lỗi xảy ra!',
                    key: 'error'
                })
            });
        }
    };
    handleForm = (e) => {
        let dataForm = this.state.dataForm;
        dataForm[e.target.name] = e.target.value;
        this.setState({
            dataForm
        })
    }
    render() {
        return (
            <div id="page-container" className="main-content-boxed">
                <main id="main-container">
                    <div className="bg-body-dark bg-pattern">
                        <div className="row mx-0 justify-content-center">
                            <div className="hero-static col-lg-6 col-xl-4">
                                <div className="content content-full overflow-hidden">
                                    <div className="py-30 text-center">
                                        <a className="link-effect font-w700" href="index.html">
                                            <i className="si si-fire"></i>
                                            <span className="font-size-xl text-primary-dark">code</span><span className="font-size-xl">base</span>
                                        </a>
                                    </div>
                                    <form className="js-validation-signin" onSubmit={this.onFinish}>
                                        <div className="block block-themed block-rounded block-shadow">
                                            <div className="block-header bg-gd-dusk">
                                                <h3 className="block-title">Thông tin đăng nhập</h3>
                                                <div className="block-options">
                                                    <button type="button" className="btn-block-option">
                                                        <i className="si si-wrench"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="block-content">

                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label>Tài khoản</label>
                                                        <input type="text" className="form-control" name="user_name" value={this.state.dataForm ? this.state.dataForm.user_name : ''} 
                                                            onChange={this.handleForm}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label>Mật khẩu</label>
                                                        {/* <input type="password" className="form-control" name="password" value={this.state.dataForm ? this.state.dataForm.password : ''} 
                                                            onChange={this.handleForm}
                                                        /> */}
                                                        <Input.Password 
                                                            name="password" 
                                                            value={this.state.dataForm ? this.state.dataForm.password : ''} 
                                                            onChange={this.handleForm}
                                                            style={{
                                                                borderRadius: "4px"
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row mb-0">
                                                    <div className="col-sm-8 d-sm-flex align-items-center push">
                                                        <Checkbox onChange={(e) => this.setState({ is_policy: e.target.checked })}>
                                                            <a href="/dieu-khoan-su-dung.html" target='_blank'>Tôi đồng ý với điều khoản dịch vụ</a>
                                                        </Checkbox>
                                                    </div>
                                                    <div className="col-sm-4 text-sm-right push">
                                                        <Button htmlType="submit" className="btn btn-alt-primary" loading={this.state.loading}>
                                                            <i className="si si-login mr-10"></i> Đăng nhập
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="block-content bg-body-light">
                                                <div className="form-group text-center">
                                                    <Link className="link-effect text-muted mr-10 mb-5 d-inline-block" to="register">
                                                        <i className="fa fa-plus mr-5"></i> Đăng ký
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
        )
    }
}
