import { ArrowLeftOutlined, DeleteOutlined, ExclamationCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Form, Input, Modal, notification, Select, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { API_URL } from "../../lib/api";
import { fetchData } from "../../lib/helpers";

import { connect } from "react-redux"; 
import QueryString from "qs";
import lodash from "lodash";

const DataLog = (props) => {
    const [data, setData] = useState({
        total: 0,
        data: [],
    });
    const [pagination, setpagination] = useState({
        limit: 20,
        page: 1,
    });
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [loading, setloading] = useState(false);
    const [ initialValues, setinitialValues ] = useState(QueryString.parse(props.location.search.replace(/^\?/gi, "")));

    const refFormFilter = useRef();
    const timeoutSearch = useRef();

    useEffect(() => {
        if(refFormFilter.current){
            refFormFilter.current.setFieldsValue(initialValues)
        }
    }, [ initialValues, refFormFilter ])

    const getListData = async () => {
        try {
            setloading(true);
            let params = {
                ...pagination
            }
            if (refFormFilter.current) params = { ...params, ...refFormFilter.current.getFieldsValue() }

            let response = await fetchData({
                url: API_URL.shareData.index,
                params: params
            });
            setData(response);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const checkExipred = async (id) => {
        try {
            setloading(true);

            let response = await fetchData({
                url: API_URL.shareData.chechkExpired,
                method: "post",
                body: JSON.stringify({
                    id: id
                })
            });

            getListData();
            
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const changeExpired = async (id) => {
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Thu hồi tài nguyên`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let response = await fetchData({
                            url: API_URL.shareData.changeExpired,
                            method: "post",
                            body: JSON.stringify({
                                id: id
                            })
                        });
                        getListData();

                        notification.success({
                            message: "Thông báo",
                            description: "Thao tác thành công"
                        });
                    } catch (err) {
                        notification.error({
                            message: "Thông báo",
                            description: err.message || err
                        });
                    } finally {

                    }
                    resolve();
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });

    };

    useEffect(() => {
        getListData();
    }, [pagination]);

    return (
        <React.Fragment>
            <div className="block">
                <Badge.Ribbon text="Dữ liệu" />
                <div className="block-content block-content-m">
                    <div className="d-flex align-items-center mb-20 ">
                        <h2 className="block-title font-weight-bold">Quản lý chia sẻ</h2>
                    </div>
                    <div className="">
                        <Form
                            ref={refFormFilter}
                            className="row gx-10"
                            onValuesChange={(e) => {
                                if (timeoutSearch.current) clearTimeout(timeoutSearch.current);
                                let timeout = 300;
                                timeoutSearch.current = setTimeout(() => {
                                    getListData();
                                }, timeout);
                            }}
                            onReset={() => {
                                getListData();
                            }}
                            initialValues={initialValues}
                        >
                            <Form.Item name="keyword" className="col-md-2">
                                <Input placeholder="Tìm kiếm.." />
                            </Form.Item>
                            <Form.Item className="col-auto">
                                <Button htmlType="reset">Xóa lọc</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="d-flex">
                        <Button type="default" size="small">
                            Tất cả: {data.total}
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={getListData}
                        >
                            <i className="si si-reload mr-2"></i> làm mới
                        </Button>
                        {/* <Tooltip title="Xóa dữ liệu đã chọn">
                            <Button
                                type="primary"
                                className="d-flex align-items-center ml-2"
                                icon={<DeleteOutlined />}
                                onClick={() => deleteMany(selectedRowKeys)}
                                danger
                                size="small"
                            >
                                Xóa
                            </Button>
                        </Tooltip> */}
                    </div>
                    <Table
                        dataSource={data.data}
                        columns={[
                            {
                                title: "Id",
                                render: (value, record) => {
                                    return lodash.get(record.to_user, "user_id")
                                }
                            },
                            {
                                title: "Người dùng",
                                render: (value, record) => {
                                    return lodash.get(record.to_user, "username")
                                }
                            },
                            {
                                title: "Hoạt động",
                                dataIndex: "total"
                            },
                            {
                                title: "Hết hạn",
                                dataIndex: "total_expired_time"
                            },
                            {
                                title: "Thời gian",
                                dataIndex: "created_time",
                                align: "right",
                                render: (value, record) => moment(value).format("HH:mm DD/MM/YYYY"),
                            },
                            {
                                title: "Hành động",
                                align: "right",
                                render: (value, record) => {
                                    return <>
                                        <Button type="primary" size="small" 
                                            onClick={() => {
                                                changeExpired(value._id)
                                            }}
                                            className="ml-2"
                                            danger
                                        >
                                            Thu hồi
                                        </Button>
                                        <Button type="primary" size="small" 
                                            onClick={() => {
                                                checkExipred(value._id)
                                            }}
                                            className="ml-2"
                                        >
                                            Kiểm tra
                                        </Button>
                                        
                                    </>
                                }
                            }
                        ]}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys,
                            onChange: (e) => setselectedRowKeys(e)
                        }}
                        rowKey="_id"
                        pagination={{
                            total: data.total,
                            pageSize: pagination.limit,
                            current: pagination.page,
                            showSizeChanger: true,
                            pageSizeOptions: [20, 50, 100, 500, 1000, 2000],
                            onChange: (page, pageSize) => {
                                setpagination({
                                    limit: pageSize,
                                    page: page,
                                });
                            },
                            position: ["topRight"],
                            size: "small"
                        }}
                        loading={loading}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

export default connect(mapStateToProps)(DataLog);
