import { message } from "antd";

export function versionCompare(v1, v2, options) {
    var lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend,
        v1parts = v1.split('.'),
        v2parts = v2.split('.');
 
    function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }
 
    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
    }
 
    if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
    }
 
    if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
    }
 
    for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length == i) {
            return 1;
        }
 
        if (v1parts[i] == v2parts[i]) {
            continue;
        }
        else if (v1parts[i] > v2parts[i]) {
            return 1;
        }
        else {
            return -1;
        }
    }
 
    if (v1parts.length != v2parts.length) {
        return -1;
    }
 
    return 0;
}

export function getPosContextmenu(e){
    e.preventDefault();
    let popup = {
        x: e.clientX
    }
    if (window.innerHeight - e.clientY < 400) {
        popup['bt'] = window.innerHeight - e.clientY;
    } else {
        popup['y'] = e.clientY;
    }
    
    return popup
}

export function copyText(text, mess) {
    let el = document.querySelector("#input-copy");
    el.textContent = text;
    el.select();
    document.execCommand("copy");
    message.success(mess || "Copy thành công")
};