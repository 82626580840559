import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber, Select, TreeSelect } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

export default class FormAddMail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            totalGet: 0,
            add_email: false,
            listFolder: [],
            listFolderUs: [],
            loadingData: false
        }
        this.form = null;
    }
    componentWillMount = () => {

    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible) {
            this.getData();
        }
    }
    onFinish = async (values) => {
        try {
            if (!window.extfbase || !window.extfbase.isConnected) {
                throw ({ message: 'Vui lòng kiểm tra tiện ích Fbase phiên bản mới nhất của chúng tôi!' });
            }

            this.props.runGetCookie({
                run: this.state.totalGet,
                max_tab: values['max_tab'],
                time_delay: values['time_delay'],
                max_nick_delay: values['max_nick_delay'] || 10,
                max_nick_delay_time: values['max_nick_delay_time'] || 1,
                max_nick_checkpoint: values['max_nick_checkpoint'],
                type: 'addmail',
                data: {
                    password_new: values['password_new'],
                    script: this.props.userSystem.script_fb,
                    login_cookie: values['login_cookie'],
                    folder_us_sync: values['folder_us_sync'],
                    sync_us: values['folder_us_sync'] ? true : false,
                    addmail_type: values['addmail_type']
                }
            });
        } catch (err) {
            this.props.notification({
                type: 'warning',
                message: 'Trình add mail',
                description: err.message || 'Đã có lỗi xảy ra!'
            })
        }
    };
    getData = async () => {
        try {
            this.setState({ loadingData: true });
            let result = await this.props.fetchData({
                url: `api/v1/user_fb/new?folder_id=${this.props.currentFolder ? this.props.currentFolder : ''}&count=1&type=addmail`,
                method: 'get'
            });
            let folder_us = await window.extfbase.request({
                method: 'mhcp_account', data: {
                    uss_token: this.props.userSystem.token_us,
                    action: 'get.hashgroups'
                }
            });

            this.setState({
                loadingData: false,
                totalGet: result.data,
                listFolderUs: this.parseFolderUs(folder_us.data.data)
            })
        } catch (err) {
            this.setState({ loadingData: false });
            this.props.notification({
                type: 'warning',
                message: 'Trình add mail',
                description: err.message || 'Đã có lỗi xảy ra!'
            })
        }
    }
    stop = () => {
        this.setState({
            loading: false
        })
    }
    parseFolderUs = (list) => {
        var roots = [];

        for (let i = 0; i < list.length; i++) {
            let result = {
                title: list[i]['name'],
                value: list[i]['id'],
                children: []
            }
            if (list[i]['childs']['node'].length > 0) {
                let childs = list[i]['childs']['node'];
                childs.forEach((item) => {
                    result['children'].push({
                        title: item.name,
                        value: item.id
                    })
                })
            }
            roots.push(result)
        }
        return roots;
    }
    render() {
        let { dataRunGetCookie } = this.props;
        return (
            <Modal
                title="Trình add mail"
                visible={this.props.visible}
                onCancel={() => {
                    this.form.resetFields();
                    this.props.hide();
                }}
                footer={null}
                className="modal-getcookie"
                width={900}
            >
                <Form
                    onFinish={this.onFinish}
                    ref={(evt) => this.form = evt}
                    initialValues={{
                        max_tab: 1,
                        time_delay: '1-2',
                        login_cookie: true,
                        addmail_type: 2
                    }}
                    layout="vertical"
                >
                    <h3 className="block-title mb-5">Tổng: {dataRunGetCookie ? dataRunGetCookie.run : this.state.totalGet}</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <div style={{ marginBottom: "15px" }}>
                                <div className="block-header block-header-default" style={{ padding: "8px 20px" }}>
                                    <h3 className="block-title">Thống kê</h3>
                                </div>
                                <div className="block-content mb-5 bt-1" style={{ border: "1px solid #f6f7f9", borderTop: "0px" }}>
                                    <p className="text-success mb-5">Thành công: {dataRunGetCookie ? dataRunGetCookie.success : 0}</p>
                                    <p className="text-danger mb-5">Checkpoint: {dataRunGetCookie ? dataRunGetCookie.checkpoint : 0}</p>
                                    <p className="text-danger mb-5">Die: {dataRunGetCookie ? dataRunGetCookie.die : 0}</p>
                                    <p className="text-danger mb-5">Lỗi: {dataRunGetCookie ? dataRunGetCookie.error : 0}</p>
                                    <p className="text-danger mb-5">Không xác định: {dataRunGetCookie ? dataRunGetCookie.other : 0}</p>
                                </div>
                            </div>
                            <Form.Item
                                name="password_new"
                                label="Mật khẩu thay đổi"
                                style={{ marginBottom: "10px" }}
                            >
                                <Input />
                            </Form.Item>
                            <div style={{ marginBottom: "15px" }}><i>Note: Mật khẩu để trống, hệ thống tự random.</i></div>
                            <Form.Item
                                name="folder_us_sync"
                                label="Đồng bộ dữ liệu sang Us"
                            >
                                <TreeSelect
                                    style={{ width: '100%' }}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    treeData={this.state.listFolderUs}
                                    placeholder="Tìm tên hoặc id nhóm"
                                    treeDefaultExpandAll={false}
                                    allowClear
                                    showSearch
                                    disabled={this.state.loadingData}
                                    filterOption={(input, option) => {
                                        if (option.title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.value == input) {
                                            return option
                                        }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name="addmail_type">
                                <Select>
                                    <Select.Option value={1}>Loại 1</Select.Option>
                                    <Select.Option value={2}>Loại 2</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Row>
                                <Col span={24} className="pr-3">
                                    <Form.Item
                                        name="max_tab"
                                        label="Max tab"
                                    >
                                        <InputNumber style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        name="time_delay"
                                        label="Time delay 1 tiến trình(s)"
                                    >
                                        <Input style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        name="max_nick_checkpoint"
                                        label="Gặp n (nick) checkpoint thì dừng"
                                    >
                                        <Input style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Thực hiện n (nick) -> nghỉ s (giây)"
                                        className="mb-0"
                                    >
                                        <Row>
                                            <Col span={12}>
                                                <Form.Item name="max_nick_delay">
                                                    <InputNumber style={{ width: "100%" }} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="max_nick_delay_time">
                                                    <InputNumber style={{ width: "100%" }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <Form.Item
                                        name="login_cookie"
                                        valuePropName="checked"
                                    >
                                        <Checkbox>Login cookie</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="text-right">
                        <Button type="primary" loading={this.props.run} htmlType="submit" id="btn-get-cookie" disabled={this.state.loadingData}>
                            Tiếp tục
                        </Button>
                        {this.props.run ? <Button className="ml-10" type="danger" onClick={this.props.stopGetCookie}>
                            Dừng
                        </Button> : null}
                    </div>
                </Form>
            </Modal>
        )
    }
}
