import { Button, Form, Input, message, Modal, notification, Radio, Select, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { fetchData } from "../../../../lib/helpers";
import { API_URL } from "../../../../lib/api";
import { ListKeySystemData } from "../../../../utils/config";
import { InboxOutlined } from "@ant-design/icons";

const WidgetForm = (props) => {
    const { visible, hide, Access } = props;
    const [loading, setloading] = useState(false);
    const [ type, setType ] = useState(ListKeySystemData[0]);
    const [ fileList, setfileList] = useState([])
    const formRef = useRef();

    const onFinish = async (values) => {
        try {
            setloading(true);

            let data = {
                title: values.title,
                key: values.key
            }
            let headers = {}

            if(values.key == "token_facebook"){
                data['value'] = JSON.stringify({
                    token: values.token,
                    cookie: values.cookie
                })
            } else if(values.key == "proxy_facebook"){
                data['value'] = values.proxy
            }

            if(values.files){
                data = new FormData()
                values.files.fileList.map((file) => {
                    data.append("files", file['originFileObj'])
                })
                data.append("title", values.title)
                data.append("key", values.key)
                data.append("local", values.local)
            } else {
                data = JSON.stringify(data)
            }

            let url = values._id ? `${API_URL.admin.systemData.index}/${values._id}` : API_URL.admin.systemData.index

            let response = await fetchData({
                url: `${url}?key=${values.key}`,
                method: values._id ? "put" : "post",
                body: data,
                notAddContentType: values.files ? true : false
            });
            
            if(props.onFinish) props.onFinish();
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const onFileChange = ({ fileList }) => {
        setfileList(fileList)
    }

    useEffect(() => {
        if (visible) {
            setType(ListKeySystemData[0]);
            setfileList([])
            formRef.current.resetFields();
            if(props.item){
                formRef.current.setFieldsValue({
                    ...props.item,
                    ...JSON.parse(props.item.value)
                });
            }
        }

    }, [visible])

    return (
        <Modal
            title="Cập nhập dữ liệu"
            visible={visible}
            onCancel={hide}
            footer={null}
        >
            <Form
                onFinish={onFinish}
                layout="vertical"
                ref={formRef}
                initialValues={{
                    key: ListKeySystemData[0],
                    title: "Tài Nguyên",
                    local: "/sdcard/AutoTouchPro"
                }}
            >
                <Form.Item name="_id" noStyle>
                    <Input type="hidden"/>
                </Form.Item>
                <Form.Item name="key" label="Loại"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống"
                        }
                    ]}
                >
                    <Select placeholder="Chọn loại dữ liệu"
                        onChange={(e) => setType(e)}
                    >
                        {
                            ListKeySystemData.map((item) => {
                                return <Select.Option value={item}>{item}</Select.Option>       
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="title" label="Tên"
                    rules={[
                        {
                            required: true,
                            message: "Không được để trống"
                        }
                    ]}
                >
                    <Input placeholder="Nhập tên dữ liệu"/>
                </Form.Item>
                {
                    type == "token_facebook" ?
                    <>
                        <Form.Item name="token" label="Token">
                            <Input placeholder="Nhập token"/>
                        </Form.Item>
                        <Form.Item name="cookie" label="Cookie">
                            <Input.TextArea placeholder="Nhập cookie"
                                rows={3}
                            />
                        </Form.Item>
                    </> : type == "proxy_facebook" ?
                    <>
                        <Form.Item name="proxy" label="Proxy">
                            <Input placeholder="Ip:port:user:password"/>
                        </Form.Item>
                    </>
                    : type.includes("autophone") ?
                    <>
                        <Form.Item name="local" label="Đường dẫn local">
                            <Input placeholder="/sdcard/AutoTouchPro"/>
                        </Form.Item>
                        <Form.Item name="files">
                            <Upload.Dragger beforeUpload={() => false} fileList={fileList} onChange={onFileChange} 
                                showUploadList={true}
                                multiple={true}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file txt to this area to upload</p>
                                <p className="ant-upload-hint">{fileList.length ? `${fileList.length} select` : 'Support for a single or bulk upload.'}</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </> : null
                }
                <Form.Item className="d-flex mb-0 justify-content-end">
                    <Button onClick={hide} className="mr-2">
                        Đóng
                    </Button>
                    <Button htmlType="submit" type="primary" loading={loading}>
                        Cập nhập
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

const mapDispathToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispathToProps)(WidgetForm);
