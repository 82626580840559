import React, { useState, useEffect } from "react";
import { Footer, Header, Sidebar } from '../component';

const AppTest = (props) => {
    return <div id="page-container" className="sidebar-mini sidebar-inverse enable-page-overlay side-scroll page-header-fixed page-header-inverse main-content-boxed side-trans-enabled sidebar-o page-header-scroll">
        <Header {...props} />
        <Sidebar {...props} />
        <main id="main-container">
            <div className="content">
                <div className="row ">
                    <div className="col-md-12 ">
                        Page test
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
}

export default AppTest;