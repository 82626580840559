import React, { useEffect, useMemo, useRef, useState } from "react";

import { Form, Input, TreeSelect, InputNumber, Switch, Upload, message, Radio } from "antd";

import WidgetUpload from "./UploadFile";
import { connect } from "react-redux";

const WidgetFormItem = (props) => {
    const [checked, setChecked] = useState(false);
    const refSwitch = useRef();
    const refInputImage = useRef();

    const [fileList, setfileList] = useState([]);

    const { folderContent, item, viewDetail } = props;

    const FormFolderContent = useMemo(() => {
        return <React.Fragment>
            <Form.Item
                label="Folder nội dung"
                tooltip={"Folder nội dung ứng với các folder ở màn quản lý content"}
                name="contentFolder"
                rules={[
                    {
                        required: true,
                        message: "Vui lòng chọn folder chứa content!",
                    },
                ]}
            >
                <TreeSelect
                    dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                    }}
                    treeData={folderContent}
                    placeholder="Hãy chọn một folder"
                    treeDefaultExpandAll={false}
                />
            </Form.Item>
        </React.Fragment>
    }, [item, folderContent])

    const FormPostContent = useMemo(() => {
        return <Form.Item
            label="Nội dung bài đăng"
            name="postContent"
            rules={[
                {
                    required: true,
                    message: "Vui lòng tải lên nội dung post!",
                },
            ]}
        >
            <Input.TextArea
                rows={3}
                allowClear={true}
                placeholder="..."
            />
        </Form.Item>
    }, [item])

    useEffect(() => {
        if (refSwitch.current) {
            setChecked(refSwitch.current.getAttribute("aria-checked"))
        }

    }, [refSwitch])

    useEffect(() => {
        if(refInputImage.current){
            let files = refInputImage.current.input.value.split(",").map((item, index) => ({
                uid: `${index}`,
                name: item,
                status: 'done',
                url: item
            }))
            setfileList(files)
        }
    }, [ refInputImage, viewDetail ])

    return <React.Fragment>
        {
            viewDetail ?
                <>
                    <Form.Item
                        name="switchOption"
                        valuePropName="checked"
                        noStyle

                    >
                        <Switch
                            className="d-none"
                            ref={refSwitch}
                        />
                    </Form.Item>
                    <Form.Item
                        name="imgURL"
                        noStyle
                    >
                        <Input type="hidden" ref={refInputImage} />
                    </Form.Item>
                    {
                        checked ?
                            <React.Fragment>
                                {FormPostContent}
                                <WidgetUpload label="Ảnh trong bài đăng" disabled={true} fileList={fileList} />
                            </React.Fragment>
                            : <React.Fragment>
                                {FormFolderContent}
                            </React.Fragment>
                    }
                </> :
                <>
                    <Form.Item
                        label="Tùy chọn nội dung"
                        tooltip={"Chọn từ thư viện: random 1 trong số các nội dung trong folder đã chọn"}
                        name="switchOption"
                        extra={
                            checked
                                ? "** Nhập nội dung thủ công **"
                                : "** sử dụng nội dung có sẵn từ thư viện **"
                        }
                        valuePropName="checked"

                    >
                        <Switch
                            onChange={(checked) => {
                                setChecked(checked);
                            }}
                            ref={refSwitch}
                        // checked={checked}
                        />
                    </Form.Item>

                    {checked ? (
                        <React.Fragment>
                            {/* <Form.Item
                                name="imgURL"
                                noStyle
                            >
                                <Input type="hidden" ref={refInputImage} />
                            </Form.Item> */}

                            {FormPostContent}

                            <WidgetUpload label="Ảnh trong bài đăng" fileList={fileList} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {FormFolderContent}
                        </React.Fragment>
                    )}
                </>
        }
    </React.Fragment>
}

const mapStateToProps = (state) => ({
    viewDetail: state.TaskAction.viewDetail
})

export default connect(mapStateToProps)(WidgetFormItem);