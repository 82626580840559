import { ExclamationCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, Empty, Form, Image, Input, Modal, notification, Result, Select, Spin, Table, Tag } from "antd";
import moment from "moment";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { API_URL } from "../../lib/api";
import { fetchData, getImageApi } from "../../lib/helpers";
import LazyLoad from "react-lazyload";
import locale from 'antd/es/date-picker/locale/vi_VN';

const ScreenDeviceView = (props) => {
    const [listScreen, setlistScreen] = useState([]);
    const [loading, setloading] = useState(false);
    const [previewVisible, setpreviewVisible] = useState({
        visible: false,
        current: 0
    });

    const refFormFilter = useRef();

    const device_id = useMemo(() => {
        return new URLSearchParams(props.location.search).get("device_id");
    }, [props.location])

    const checkTimeParams = () => {
        try{
            let params = new URLSearchParams(props.location.search);

            if(params.get("start_time") && params.get("end_time")){
                return [
                    moment(parseInt(params.get("start_time"))),
                    moment(parseInt(params.get("end_time")))
                ];
            } else{
                throw(true);
            }
        } catch(err){
            return [moment().startOf("day"), moment()]
        }
    }

    const getListData = async () => {
        try {
            setloading(true);

            let params = {
                device_id: device_id,
            }

            if(refFormFilter.current){
                let values = refFormFilter.current.getFieldsValue();
                console.log(values);
                params = {
                    ...params,
                    start_time: values.time[0].unix() * 1000,
                    end_time: values.time[1].unix() * 1000
                }
            }

            let response = await fetchData({
                url: API_URL.appProfile.screenAlbum,
                method: "get",
                params
            });
            setlistScreen(response.data.data);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message || err
            });
        } finally {
            setloading(false);
        }
    }

    const listData = useMemo(() => {
        let result = {};

        listScreen.forEach((item, i) => {
            if (!result[item.date_string]) {
                result[item.date_string] = [{
                    ...item,
                    i
                }];
            } else {
                result[item.date_string].push({
                    ...item,
                    i
                })
            }
        })

        return Object.keys(result).map((key) => {
            return {
                date_string: key,
                images: result[key]
            }
        })

    }, [listScreen])

    useEffect(() => {
        getListData();
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [])

    return (
        <React.Fragment>
            <div className="block" id="screen-app">
                <div className="block-content-m p-10" style={{ minHeight: "1000px" }}>
                    <div className="block-header">
                        <Form
                            onValuesChange={(values) => {
                                getListData();
                            }}
                            initialValues={{
                                time: checkTimeParams()
                            }}
                            layout="inline"
                            ref={refFormFilter}
                        >
                            <Form.Item name={"time"} className="mb-0">
                                <DatePicker.RangePicker showTime
                                    disabledDate={(current) => current && current > moment()}
                                    ranges={{
                                        'Hôm nay': [moment().startOf("day"), moment().endOf("day")],
                                        'Hôm qua': [moment().startOf("day").add(-1, "day"), moment().endOf("day").add(-1, "day")],
                                        // 'Tuần này': [moment().startOf("week"), moment().endOf("week")],
                                        // 'Tuần trước': [moment().add(-1, "week").startOf("week"), moment().add(-1, "week").endOf("week")],
                                        // 'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                        // 'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                    }}
                                    locale={locale}
                                    inputReadOnly
                                    allowClear={false}
                                    format="YYYY-MM-DD HH:mm"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" onClick={getListData}>Tải lại</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <Spin spinning={loading}>
                        <div className="block-content" style={{ minHeight: "300px" }}>
                            {
                                !listData.length ?
                                    <Empty
                                        description="Chưa có dữ liệu"
                                        className="mt-100"
                                    />
                                    : <Image.PreviewGroup>
                                        {
                                            listData.map((item, i) => {
                                                return <div key={i} className="box-list-img">
                                                    <p className="title">{item.date_string}</p>
                                                    <div className="list-img">
                                                            {
                                                                item.images.map((image, ii) => {
                                                                    return <div className="img-item" key={`img_${ii}`}>
                                                                        <LazyLoad offset={100}>
                                                                            <Image
                                                                                sizes="small"
                                                                                src={getImageApi(image.url)}
                                                                                width={"100%"}
                                                                                height={"100%"}
                                                                                previewPrefixCls={{
    
                                                                                }}
                                                                                // preview={{ visible: false }}
                                                                                // onClick={() => setpreviewVisible({...previewVisible, visible: true, current: image.i})}
                                                                            />
                                                                            <p>{moment(image.time).format("HH:mm:ss")}</p>
                                                                        </LazyLoad>
                                                                    </div>
                                                                })
                                                            }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </Image.PreviewGroup>
                            }
                        </div>
                        {/* <div className="preview-img d-none">
                            <Image.PreviewGroup
                                preview={{
                                    visible: previewVisible.visible,
                                    onVisibleChange: (vis) => setpreviewVisible({ ...previewVisible, visible: vis }),
                                }}
                            >
                                {
                                    listScreen.map((image, ii) => {
                                        return <Image
                                            src={getImageApi(image.url)}
                                        />
                                    })
                                }
                            </Image.PreviewGroup>
                        </div> */}
                    </Spin>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ScreenDeviceView;
