import React, { Component } from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';

import { Form, Input, Button, Checkbox, message, Menu, Modal, Select, Dropdown, Tooltip, Popover, Tree, Pagination } from 'antd';
import { fetchData } from '../lib/helpers';
import { Footer, Header, Sidebar, ModalUploadUserFb, ModalExportUserFb, FormSaveFolder, PopupMenu, SpinLoading, FormOptJoinGroup } from '../component';
import moment from 'moment';
import { FolderOpenOutlined, ExclamationCircleOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';

import { ConfigListAction } from "../utils/config";

const { SubMenu } = Menu;
const { Option } = Select;
const { DirectoryTree } = Tree;

export default class AppRun extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.rootSubmenuKeys = [];
        this.state = {
            openKeys: [],
            currentProfile: 'all_slt_app',
            listProfile: [],
            listData: [],
            listIdChecked: [],
            valuesFilter: {},
            loading: false,
            dataMenuRight: null,
            DataM: {},
            popup: {}
        };
        this.limit = 20;
        this.page = 1;
        this.nextpage = true;
        this.timeOutFilter = null;
        this.realtime_getprofile = null
    }
    componentWillMount = () => {
        // this.getListProfile();
        this.getListData();
        // this.realtime_getprofile = setInterval(() => {
        //     this.getListProfile();
        // }, 3000);
        var _this = this;
        window.addEventListener("click", function () {
            if (_this.state.popup && _this.state.popup['visible']) {
                _this.setState({
                    popup: {}
                })
            }
        }, false);
    }

    getListData = async (type) => {
        try {
            if (type == 'reset') {
                this.page = 1;
                this.nextpage = false;
                this.state.listIdChecked = [];
            }
            this.setState({ loading: true });
            let query = `limit=${this.limit}&page=${this.page}`;
            // for (let [key, value] of Object.entries(this.state.valuesFilter)) {
            //     query += `&${key}=${value}`;
            // }
            let valuesFilter = { ...this.state.valuesFilter };

            valuesFilter['profile_id'] = new URLSearchParams(this.props.location.search).get("device_id");

            let res = await fetchData({
                url: `api/v2/app/action/list?${query}`,
                method: 'post',
                body: JSON.stringify(valuesFilter)
            });
            if (this.limit != res.data.length) {
                this.nextpage = false;
            } else {
                this.nextpage = true;
            }
            this.setState({ listData: res.data, totalData: res.total, DataM: res, loading: false });
            if (document.querySelector('[name="checkall"]')) document.querySelector('[name="checkall"]').checked = false;
        } catch (err) {
            this.setState({ loading: false });
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    getListProfile = async () => {
        try {
            let res = await fetchData({
                url: `api/v2/app/profile/list`,
            });
            this.setState({ listProfile: res.data });
        } catch (err) {
            this.props.message({ content: err.message || 'Đã cõ lỗi xảy ra', key: 'message', type: 'error' });
        }
    }
    handleChecked = (e) => {
        if (e.target.name == 'checkall') {
            if (e.target.checked) {
                let listIdChecked = [];
                document.querySelectorAll('[name="check_item"]').forEach((item) => {
                    item.checked = true;
                    listIdChecked.push(item.value);
                });
                this.setState({ listIdChecked: listIdChecked });
            } else {
                document.querySelectorAll('[name="check_item"]').forEach((item) => item.checked = false)
                this.setState({ listIdChecked: [] });
            }
        } else {
            document.querySelector('[name="checkall"]').checked = false;
            let listIdChecked = this.state.listIdChecked;
            if (e.target.checked) {
                listIdChecked.push(e.target.value);
            } else {
                listIdChecked.splice(listIdChecked.indexOf(e.target.value), 1);
            }
            this.setState({ listIdChecked });
        }
    }
    handleFilterSearch = (e) => {
        if (this.timeOutFilter) clearTimeout(this.timeOutFilter);
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter });
        this.timeOutFilter = setTimeout(() => {
            this.getListData('reset')
        }, 300)
    }
    handleFilter = (e) => {
        let valuesFilter = this.state.valuesFilter;
        let target = e.target;
        valuesFilter[target.name] = target.value;
        this.setState({ valuesFilter }, () => { this.getListData('reset') });
    }
    setPosContextmenu = (e, item) => {
        e.preventDefault();
        let menu = <ul>
            <li className="title">{item.name}</li>
            {
                item.status == 1 ?
                    <li onClick={() => {
                        this.updateProfile(item._id, { status: 2 });
                    }}>Dừng app</li> :
                    <li onClick={() => {
                        this.updateProfile(item._id, { status: 1 });
                    }}>Chạy app</li>
            }
            <li onClick={() => {
                this.removeProfile(item._id);
            }}>Xóa app</li>
        </ul>
        this.setState({
            popup: {
                visible: true,
                x: e.clientX,
                y: e.clientY,
                menu: menu
            }
        })
    }
    updateProfile = async (id, data) => {
        try {
            let res = await fetchData({
                url: `api/v2/app/profile/${id}`,
                method: 'post',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' }
            });
            let findIndex = this.state.listProfile.findIndex((item) => item._id == id);
            if (findIndex > -1) {
                this.state.listProfile[findIndex] = res.data;
                this.setState({
                    reload: !this.state.reload
                })
            }
            this.props.notification({
                type: 'success',
                message: 'Cập nhập app',
                description: 'Thao tác thành công'
            })
        } catch (err) {
            this.props.notification({
                type: 'error',
                message: 'Cập nhập app',
                description: err.message || 'Đã có lỗi xảy ra!'
            })
        }
    }
    removeProfile = async (id) => {
        try {
            let res = await fetchData({
                url: `api/v2/app/profile/${id}`,
                method: 'delete'
            });
            let findIndex = this.state.listProfile.findIndex((item) => item._id == id);
            if (findIndex > -1) {
                this.state.listProfile.splice(findIndex, 1);
                this.setState({
                    reload: !this.state.reload
                })
            }
            this.props.notification({
                type: 'success',
                message: 'Xóa app',
                description: 'Thao tác thành công'
            })
        } catch (err) {
            this.props.notification({
                type: 'error',
                message: 'Xóa app',
                description: err.message || 'Đã có lỗi xảy ra!'
            })
        }
    }
    componentWillUnmount = () => {
        if (this.realtime_getprofile) clearInterval(this.realtime_getprofile);
    }
    render() {
        return (
            <React.Fragment>
                <PopupMenu {...this.state.popup} menu={this.state.popup ? this.state.popup.menu : null} />
                <div className="row ">
                    {/* <div className="col-md-3 ">
                        <div className="block">
                            <div className="block-content block-content-m">
                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <strong>Quản lý app</strong>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-md-12 p-0">
                                        <Menu
                                            mode="inline"
                                            onSelect={(e) => this.setState({ currentProfile: e.key }, () => this.getListData({ type: 'reset' }))}
                                            selectedKeys={[this.state.currentProfile]}
                                        >
                                            <Menu.Item key={"all_slt_app"}>Tất cả</Menu.Item>
                                            {
                                                this.state.listProfile && this.state.listProfile.map((item, i) => {
                                                    return <Menu.Item key={item.profile_id} style={{ height: "auto" }} onContextMenu={(event) => this.setPosContextmenu(event, item)}>
                                                        <strong className="m-0">{item.name}</strong>
                                                        <p className="m-0 d-flex" style={{ fontSize: "11px", alignItems: "center" }}>
                                                            {
                                                                item.status == 1 ?
                                                                    <span className="badge badge-pill badge-info p-0 d-inline-block mr-2" style={{ width: "5px", height: "5px" }}></span> :
                                                                    <span className="badge badge-pill badge-danger p-0 d-inline-block mr-2" style={{ width: "5px", height: "5px" }}></span>
                                                            }
                                                            <i>{item.type == "phone" ? <React.Fragment><i className='far fa-mobile'></i> - </React.Fragment> : null}Hoạt động - {moment(item.last_time).fromNow()}</i>
                                                        </p>
                                                    </Menu.Item>
                                                })
                                            }
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-md-12">
                        <div className="block">
                            <div className="block-content block-content-m">
                                <div className="row mb-20">
                                    <div className="col-md-9">
                                        <button className="btn btn-info btn-sm" onClick={() => this.props.history.goBack()}>
                                                <i className='fa fa-arrow-left text-white'></i>
                                            </button>

                                        <button className="btn btn-info btn-sm ml-2">Tất cả ({this.state.totalData ? this.state.totalData : 0})</button>
                                        <button className="btn btn-primary btn-sm ml-2" onClick={() => this.getListData()}>
                                            <i className="fa fa-refresh"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="row mb-20 tool-filter">
                                    <div className="col-md-2">
                                        <Select value={this.state.valuesFilter['status'] ? this.state.valuesFilter['status'] : ""}
                                            name="status" style={{ width: "100%" }}
                                            onChange={(e) => {
                                                let valuesFilter = this.state.valuesFilter;
                                                valuesFilter['status'] = e;
                                                this.setState({ valuesFilter }, () => this.getListData('reset'));
                                            }}
                                        >
                                            <Option value="">Trạng thái</Option>
                                            <Option value="1">Đang chờ</Option>
                                            <Option value="2">Thành công</Option>
                                            <Option value="3">Thất bại</Option>
                                        </Select>
                                    </div>
                                    <div className="col-md-2">
                                        <Button icon={<DeleteOutlined />} type="primary" size={"middle"} onClick={() => this.setState({ valuesFilter: {} }, () => {
                                            this.getListData('reset');
                                        })}>
                                            Xóa lọc
                                        </Button>
                                    </div>
                                    <div className="col-md-8 text-right">
                                        <Pagination
                                            total={this.state.DataM ? this.state.DataM.total : 0}
                                            showSizeChanger
                                            pageSize={this.limit}
                                            pageSizeOptions={[20, 50, 100, 200, 500, 1000]}
                                            current={this.page}
                                            onShowSizeChange={(current, size) => {
                                                this.limit = size;
                                            }}
                                            onChange={(current) => {
                                                this.page = current;
                                                this.getListData();
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-md-12 ">
                                        <div className="table-responsive" onScroll={this.handleScroll}>
                                            <SpinLoading visible={this.state.loading} />
                                            <table className="table table-striped table-vcenter">
                                                <thead>
                                                    <tr>
                                                        {/* <th className="checkbox">
                                                                    <label class="css-control css-control-primary css-checkbox p-0">
                                                                        <input name="checkall" type="checkbox" class="css-control-input checkbox" onChange={this.handleChecked}
                                                                        checked={this.state.listIdChecked.length == this.state.listData.length && this.state.listIdChecked.length > 0 ? 1 : 0}
                                                                        />
                                                                        <span class="css-control-indicator"></span>
                                                                    </label>
                                                                </th> */}
                                                        <th>Type</th>
                                                        <th>Uid / Action</th>
                                                        <th>Profile</th>
                                                        {/* <th>Device</th> */}
                                                        {/* <th>Action</th> */}
                                                        <th>Log</th>
                                                        <th>Status</th>
                                                        <th className="text-right">Last Time</th>
                                                        <th className="text-right">Time Create</th>
                                                        <th className='text-right'>Hành động</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listData.length > 0 && this.state.listProfile ? this.state.listData.map((item, i) => {
                                                            let action = ConfigListAction.find((item_) => item_.key == item.action_name);
                                                            return (<tr key={i + '_' + item._id}
                                                            >
                                                                <td>{item.action_type}</td>
                                                                <td>
                                                                    {item.uid} / {action ? action.value : item.action_name}
                                                                </td>
                                                                <td>
                                                                    {item.device_id}
                                                                </td>
                                                                {/* <td>{item.device_id}</td> */}
                                                                {/* <td>{item.action == 'join_group' ? 'Join Nhóm' : item.action}</td> */}
                                                                <td>
                                                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "260px" }}>
                                                                        {item.logs.length > 0 ? item.logs[0].log : null}
                                                                    </div>
                                                                </td>
                                                                <td>{item.status == 1 ? <span className="">Đang chờ</span> : item.status == 2 ? <span className="text-success">Thành công</span> : <span className="text-danger">Thất bại</span>}</td>
                                                                <td className="text-right">{item.last_time > 10 ? moment(item.last_time).format('LT DD/MM/YYYY') : null}</td>
                                                                <td className="text-right">{moment(item.created_time).format('LT DD/MM/YYYY')}</td>
                                                                <td className='text-right'>
                                                                    {item.task && item.created_by == this.props.userSystem._id && <Button size="small" type='primary'
                                                                        onClick={() => {
                                                                            window.open(`/task_action/${item.task._id}?device_id=${item.device_id}`)
                                                                        }}
                                                                    >Chiến dịch</Button>}
                                                                </td>
                                                            </tr>)
                                                        }) : <tr>
                                                            <td colSpan="11" className="text-center">Không có dữ liệu</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
