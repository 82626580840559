import { Modal, Form, Button, Input, Select, notification, DatePicker, InputNumber } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { fetchData } from "../../../lib/helpers";
import { Config } from "../../../utils/config";
import { connect } from "react-redux";
import dayjs from "dayjs"
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

const WidgetFormLicense = (props) => {
    const { Access } = props;

    const [loading, setloading] = useState(false);

    const form = useRef();

    const onFinish = async (values) => {
        try {
            setloading(true);

            await fetchData({
                url: API_URL.Ref.index + "/" + values._id,
                method: "put",
                body: JSON.stringify({
                    role: {
                        max_app: values.max_app,
                        platforms: values.platforms.map((item) => {
                            let date = item.expired_date;
                            delete item.expired_date;
                            return {
                                ...item,
                                expired_time: dayjs(date).unix() * 1000
                            }
                        })
                    }
                }),
            });

            props.onFinish();
            notification.success({
                message: "Thông báo",
                description: "Cập nhập thành công",
            });
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message || err,
            });
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        if (!props.visible && form.current) {
            form.current.resetFields();
        }
        if (props.visible) {
            form.current.setFieldsValue(props.item);
        }
    }, [props.visible]);

    return (
        <Modal
            title="Cập nhập"
            visible={props.visible}
            onCancel={props.onCancel}
            footer={null}
        >
            <Form layout="vertical" onFinish={onFinish} ref={form}
                defaultValue={{
                    max_app: 20
                }}
            >
                <Form.Item name="_id" noStyle>
                    <Input type="hidden" />
                </Form.Item>

                <Form.List name="platforms">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <div key={key} className='w-100 row'>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'type']}
                                        rules={[{ required: true, message: 'Không được để trống' }]}
                                        className='col-5 mb-3'
                                    >
                                        <Select placeholder="Chọn nền tảng">
                                            {
                                                Access.Platform.data.map((item) => {
                                                    return <Select.Option value={item}>{item}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'expired_date']}
                                        rules={[{ required: true, message: 'Missing last name' }]}
                                        className='col-5  mb-3'
                                    >
                                        <DatePicker
                                            presets={[
                                                { label: '7 ngày', value: dayjs().add(7, 'day') },
                                                { label: '1 tháng', value: dayjs().add(1, 'month') },
                                                { label: '2 tháng', value: dayjs().add(2, 'month') },
                                                { label: '3 tháng', value: dayjs().add(3, 'month') },
                                                { label: '6 tháng', value: dayjs().add(6, 'month') },
                                                { label: '1 năm', value: dayjs().add(1, 'year') },
                                            ]}
                                        />
                                    </Form.Item>
                                    <CloseOutlined onClick={() => remove(name)} />
                                </div>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add field
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item name="max_app" label="Số thiết bị">
                    <InputNumber min={0} className="w-100"/>
                </Form.Item>

                <Form.Item className="text-right mb-0">
                    <Button htmlType="submit" type="primary" loading={loading}>
                        Cập nhập
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

const mapDispathToProps = (dispatch) => ({

})

export default connect(mapStateToProps, mapDispathToProps)(WidgetFormLicense);
