import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Modal, Button, message, Upload, Form, Input, Checkbox, Col, Row, InputNumber, Select, Progress } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

export default class CheckPriceAds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            type_login: 2,
            dataRun: {}
        }
        this.form = null;
        this.run_now = 0;
        this.is_run = false;
    }
    componentWillMount = () => {

    }
    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.visible && nextProps.visible != this.props.visible) {

        }
    }
    onMessage = (req_id, message) => {
        try{
            if (req_id !== message.req_id) return;
            this.run_now--;
            let dataRun = this.state.dataRun;
            let total = this.props.listIdChecked.length;
            dataRun['success'] = dataRun['success'] ? dataRun['success'] : 0;
            dataRun['success'] += 1;
            dataRun['percent'] = Math.floor((dataRun['success'] / total) * 100);
            if(dataRun['percent'] > 100) dataRun['percent'] = 100;
            
            this.setState({
                dataRun: dataRun,
                loading: dataRun['percent'] == 100 ? false : this.state.loading
            })
            window.extfbase.port.onMessage.removeListener(this.onMessage);
        } catch(err){
            console.log(err);
        }
    }
    onFinish = async (values) => {
        try {
            if (!window.extfbase || !window.extfbase.isConnected) {
                throw ({ message: 'Vui lòng kiểm tra tiện ích Fbase phiên bản mới nhất của chúng tôi!' });
            }
            this.setState({ loading: true });
            let listIdChecked = JSON.parse(JSON.stringify(this.props.listIdChecked));
            let listData = JSON.parse(JSON.stringify(this.props.listData));
            let type_login = this.state.type_login;
            this.is_run = true;
            while (listIdChecked.length > 0 && this.is_run) {
                let ids_run = listIdChecked.splice(0, values['max_tab'] - this.run_now);
                if (ids_run.length > 0 && this.run_now < values['max_tab']) {
                    for (let i = 0; i < ids_run.length; i++) {
                        let user = listData.find((item) => item._id == ids_run[i]);
                        let req_id = Date.now() + i;
                        if (user) {
                            this.run_now++;
                            window.extfbase.port.postMessage({
                                url: 'https://facebook.com',
                                method: 'action_ext',
                                script_action: {
                                    type: 'check_price_ads',
                                    type_login: type_login
                                },
                                action_domain: 'facebook.com',
                                nick_action: { user_action: user },
                                req_id: req_id
                            });
                            window.extfbase.port.onMessage.addListener((message) => this.onMessage(req_id, message));
                        }
                    }
                }
                await this.props.sleep(3000);
            }
        } catch (err) {
            this.run_now = 0;
            console.log(err);
            this.setState({ loading: false }, () => {
                this.props.notification({
                    type: 'error',
                    message: 'Kiểm tra ngưỡng ads',
                    description: err.message
                })
            });
        }
    };
    render() {
        let { dataRun } = this.state;
        return (
            <Modal
                title="Kiểm tra ngưỡng ads"
                visible={this.props.visible}
                onCancel={() => {
                    this.setState({
                        loading: false,
                        dataRun: {}
                    }, () => {
                        this.is_run = false;
                        this.props.hide();
                    })
                }}
                footer={null}
            >
                <Form
                    onFinish={this.onFinish}
                    ref={(evt) => this.form = evt}
                    initialValues={{
                        max_tab: 10,
                        type_login: 1
                    }}
                    layout="vertical"
                >
                    <div className="block-header block-header-default">
                        <h3 className="block-title">Thống kê</h3>
                    </div>
                    <div className="block-content mb-5 bt-1" style={{ border: "1px solid #f6f7f9", borderTop: "0px" }}>
                        <p className="mb-5">Tổng: {this.props.listIdChecked.length}</p>
                        <p className="text-success mb-5">Đã chạy: {dataRun['success'] || 0}</p>
                        <Progress percent={dataRun['percent'] || 0} steps={10} style={{ width: "100%" }} />
                    </div>
                    <Row>
                        <Col span={24} className="pr-3">
                            <Form.Item
                                name="max_tab"
                                label="Số tab thực hiện"
                            >
                                <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item
                                name="type_login"
                            >
                                <Checkbox checked={this.state.type_login == 2} value={2} onChange={(e) => {
                                    this.setState({ type_login: e.target.checked ? 2 : 1 })
                                }}>Login cookie</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="text-center">
                        <Button type="primary" loading={this.state.loading} htmlType="submit" >
                            Tiếp tục
                        </Button>
                        { this.state.loading ? <Button className="ml-10" type="danger" onClick={() => {
                            this.is_run = false;
                            this.setState({
                                loading: false
                            })
                        }}>
                            Dừng
                        </Button> : null}
                    </div>
                </Form>
            </Modal>
        )
    }
}
